import { cn } from '@my/magic-ui'
import { motion, AnimatePresence } from 'framer-motion'
import { Plus, Minus } from 'lucide-react'
import React from 'react'

interface AccordionItemProps {
  title: string
  content: string | React.ReactNode
  isOpen: boolean
  onToggle: () => void
  classNames?: {
    container?: string // 每个手风琴项的容器
    button?: string // 点击按钮
    title?: string // 标题文本
    icon?: string // 加号/减号图标
    content?: string // 内容区域
  }
}

export const AccordionItem = ({
  title,
  content,
  isOpen,
  onToggle,
  classNames = {},
}: AccordionItemProps) => {
  return (
    <div className={cn('border-coal-100 border-b px-5 py-4', classNames.container)}>
      <button
        onClick={onToggle}
        className={cn(
          'flex w-full items-center justify-between py-4 text-left font-medium transition-colors',
          isOpen ? 'text-info' : 'hover:text-primary-400 text-white',
          classNames.button
        )}
      >
        <span className={cn('text-base font-medium md:text-2xl', classNames.title)}>{title}</span>
        <motion.div
          initial={false}
          animate={{ rotate: isOpen ? 180 : 0 }}
          transition={{ duration: 0.2 }}
          className={classNames.icon}
        >
          {isOpen ? (
            <Minus className="h-5 w-5 text-white" />
          ) : (
            <Plus className="h-5 w-5 text-white" />
          )}
        </motion.div>
      </button>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.3, ease: 'easeInOut' }}
          >
            <div className={cn('pb-4 text-base text-gray-900', classNames.content)}>{content}</div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

interface AccordionProps {
  items: {
    title: string
    content: string | React.ReactNode
  }[]
  classNames?: {
    container?: string // 整个手风琴的容器
    item?: {
      // 每个项的样式
      container?: string
      button?: string
      title?: string
      icon?: string
      content?: string
    }
  }
}

export const Accordion = ({ items, classNames = {} }: AccordionProps) => {
  const [openIndex, setOpenIndex] = React.useState<number | null>(null)

  return (
    <div className={cn('rounded-lg bg-gray-900/60 backdrop-blur-sm', classNames.container)}>
      {items.map((item, index) => (
        <AccordionItem
          key={index}
          title={item.title}
          content={item.content}
          isOpen={openIndex === index}
          onToggle={() => setOpenIndex(openIndex === index ? null : index)}
          classNames={{
            container: cn(
              index === items.length - 1 ? 'border-b-0' : '',
              classNames.item?.container
            ),
            button: classNames.item?.button,
            title: classNames.item?.title,
            icon: classNames.item?.icon,
            content: classNames.item?.content,
          }}
        />
      ))}
    </div>
  )
}
