/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never
}
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  /** A high precision floating point value represented as a string */
  BigFloat: { input: string; output: string }
  /** An arbitrary size integer represented as a string */
  BigInt: { input: any; output: any }
  /** An opaque string using for tracking a position in results during pagination */
  Cursor: { input: any; output: any }
  /** A date wihout time information */
  Date: { input: any; output: any }
  /** A date and time */
  Datetime: { input: any; output: any }
  /** A Javascript Object Notation value serialized as a string */
  JSON: { input: any; output: any }
  /** Any type not handled by the type system */
  Opaque: { input: any; output: any }
  /** A time without date information */
  Time: { input: any; output: any }
  /** A universally unique identifier */
  UUID: { input: string; output: string }
}

/** Boolean expression comparing fields on type "BigFloat" */
export type BigFloatFilter = {
  eq?: InputMaybe<Scalars['BigFloat']['input']>
  gt?: InputMaybe<Scalars['BigFloat']['input']>
  gte?: InputMaybe<Scalars['BigFloat']['input']>
  in?: InputMaybe<Array<Scalars['BigFloat']['input']>>
  is?: InputMaybe<FilterIs>
  lt?: InputMaybe<Scalars['BigFloat']['input']>
  lte?: InputMaybe<Scalars['BigFloat']['input']>
  neq?: InputMaybe<Scalars['BigFloat']['input']>
}

/** Boolean expression comparing fields on type "BigInt" */
export type BigIntFilter = {
  eq?: InputMaybe<Scalars['BigInt']['input']>
  gt?: InputMaybe<Scalars['BigInt']['input']>
  gte?: InputMaybe<Scalars['BigInt']['input']>
  in?: InputMaybe<Array<Scalars['BigInt']['input']>>
  is?: InputMaybe<FilterIs>
  lt?: InputMaybe<Scalars['BigInt']['input']>
  lte?: InputMaybe<Scalars['BigInt']['input']>
  neq?: InputMaybe<Scalars['BigInt']['input']>
}

/** Boolean expression comparing fields on type "Boolean" */
export type BooleanFilter = {
  eq?: InputMaybe<Scalars['Boolean']['input']>
  is?: InputMaybe<FilterIs>
}

/** Boolean expression comparing fields on type "Date" */
export type DateFilter = {
  eq?: InputMaybe<Scalars['Date']['input']>
  gt?: InputMaybe<Scalars['Date']['input']>
  gte?: InputMaybe<Scalars['Date']['input']>
  in?: InputMaybe<Array<Scalars['Date']['input']>>
  is?: InputMaybe<FilterIs>
  lt?: InputMaybe<Scalars['Date']['input']>
  lte?: InputMaybe<Scalars['Date']['input']>
  neq?: InputMaybe<Scalars['Date']['input']>
}

/** Boolean expression comparing fields on type "Datetime" */
export type DatetimeFilter = {
  eq?: InputMaybe<Scalars['Datetime']['input']>
  gt?: InputMaybe<Scalars['Datetime']['input']>
  gte?: InputMaybe<Scalars['Datetime']['input']>
  in?: InputMaybe<Array<Scalars['Datetime']['input']>>
  is?: InputMaybe<FilterIs>
  lt?: InputMaybe<Scalars['Datetime']['input']>
  lte?: InputMaybe<Scalars['Datetime']['input']>
  neq?: InputMaybe<Scalars['Datetime']['input']>
}

export enum FilterIs {
  NotNull = 'NOT_NULL',
  Null = 'NULL',
}

/** Boolean expression comparing fields on type "Float" */
export type FloatFilter = {
  eq?: InputMaybe<Scalars['Float']['input']>
  gt?: InputMaybe<Scalars['Float']['input']>
  gte?: InputMaybe<Scalars['Float']['input']>
  in?: InputMaybe<Array<Scalars['Float']['input']>>
  is?: InputMaybe<FilterIs>
  lt?: InputMaybe<Scalars['Float']['input']>
  lte?: InputMaybe<Scalars['Float']['input']>
  neq?: InputMaybe<Scalars['Float']['input']>
}

/** Boolean expression comparing fields on type "ID" */
export type IdFilter = {
  eq?: InputMaybe<Scalars['ID']['input']>
}

/** Boolean expression comparing fields on type "Int" */
export type IntFilter = {
  eq?: InputMaybe<Scalars['Int']['input']>
  gt?: InputMaybe<Scalars['Int']['input']>
  gte?: InputMaybe<Scalars['Int']['input']>
  in?: InputMaybe<Array<Scalars['Int']['input']>>
  is?: InputMaybe<FilterIs>
  lt?: InputMaybe<Scalars['Int']['input']>
  lte?: InputMaybe<Scalars['Int']['input']>
  neq?: InputMaybe<Scalars['Int']['input']>
}

/** The root type for creating and mutating data */
export type Mutation = {
  __typename?: 'Mutation'
  /** Deletes zero or more records from the `checkouts` collection */
  deleteFromcheckoutsCollection: CheckoutsDeleteResponse
  /** Deletes zero or more records from the `comment_likes` collection */
  deleteFromcomment_likesCollection: Comment_LikesDeleteResponse
  /** Deletes zero or more records from the `comments` collection */
  deleteFromcommentsCollection: CommentsDeleteResponse
  /** Deletes zero or more records from the `credits` collection */
  deleteFromcreditsCollection: CreditsDeleteResponse
  /** Deletes zero or more records from the `credits_history` collection */
  deleteFromcredits_historyCollection: Credits_HistoryDeleteResponse
  /** Deletes zero or more records from the `extract_tag_detail` collection */
  deleteFromextract_tag_detailCollection: Extract_Tag_DetailDeleteResponse
  /** Deletes zero or more records from the `generate_tasks` collection */
  deleteFromgenerate_tasksCollection: Generate_TasksDeleteResponse
  /** Deletes zero or more records from the `invoices` collection */
  deleteFrominvoicesCollection: InvoicesDeleteResponse
  /** Deletes zero or more records from the `make_models_history` collection */
  deleteFrommake_models_historyCollection: Make_Models_HistoryDeleteResponse
  /** Deletes zero or more records from the `membership` collection */
  deleteFrommembershipCollection: MembershipDeleteResponse
  /** Deletes zero or more records from the `membership_history` collection */
  deleteFrommembership_historyCollection: Membership_HistoryDeleteResponse
  /** Deletes zero or more records from the `model_subscribers` collection */
  deleteFrommodel_subscribersCollection: Model_SubscribersDeleteResponse
  /** Deletes zero or more records from the `models` collection */
  deleteFrommodelsCollection: ModelsDeleteResponse
  /** Deletes zero or more records from the `models_deleted` collection */
  deleteFrommodels_deletedCollection: Models_DeletedDeleteResponse
  /** Deletes zero or more records from the `payment` collection */
  deleteFrompaymentCollection: PaymentDeleteResponse
  /** Deletes zero or more records from the `post_likes` collection */
  deleteFrompost_likesCollection: Post_LikesDeleteResponse
  /** Deletes zero or more records from the `posts` collection */
  deleteFrompostsCollection: PostsDeleteResponse
  /** Deletes zero or more records from the `posts_deleted` collection */
  deleteFromposts_deletedCollection: Posts_DeletedDeleteResponse
  /** Deletes zero or more records from the `posts_pending` collection */
  deleteFromposts_pendingCollection: Posts_PendingDeleteResponse
  /** Deletes zero or more records from the `prices` collection */
  deleteFrompricesCollection: PricesDeleteResponse
  /** Deletes zero or more records from the `products` collection */
  deleteFromproductsCollection: ProductsDeleteResponse
  /** Deletes zero or more records from the `profiles` collection */
  deleteFromprofilesCollection: ProfilesDeleteResponse
  /** Deletes zero or more records from the `session_message_interactions` collection */
  deleteFromsession_message_interactionsCollection: Session_Message_InteractionsDeleteResponse
  /** Deletes zero or more records from the `session_messages` collection */
  deleteFromsession_messagesCollection: Session_MessagesDeleteResponse
  /** Deletes zero or more records from the `session_scenario_likes` collection */
  deleteFromsession_scenario_likesCollection: Session_Scenario_LikesDeleteResponse
  /** Deletes zero or more records from the `session_scenarios` collection */
  deleteFromsession_scenariosCollection: Session_ScenariosDeleteResponse
  /** Deletes zero or more records from the `session_topic_interactions` collection */
  deleteFromsession_topic_interactionsCollection: Session_Topic_InteractionsDeleteResponse
  /** Deletes zero or more records from the `session_topics` collection */
  deleteFromsession_topicsCollection: Session_TopicsDeleteResponse
  /** Deletes zero or more records from the `sessions` collection */
  deleteFromsessionsCollection: SessionsDeleteResponse
  /** Deletes zero or more records from the `subscriptions` collection */
  deleteFromsubscriptionsCollection: SubscriptionsDeleteResponse
  /** Deletes zero or more records from the `user_extract_tag` collection */
  deleteFromuser_extract_tagCollection: User_Extract_TagDeleteResponse
  /** Deletes zero or more records from the `user_referrers` collection */
  deleteFromuser_referrersCollection: User_ReferrersDeleteResponse
  /** Deletes zero or more records from the `user_shares` collection */
  deleteFromuser_sharesCollection: User_SharesDeleteResponse
  /** Deletes zero or more records from the `user_subscribers` collection */
  deleteFromuser_subscribersCollection: User_SubscribersDeleteResponse
  /** Deletes zero or more records from the `users` collection */
  deleteFromusersCollection: UsersDeleteResponse
  /** Deletes zero or more records from the `users_deleted` collection */
  deleteFromusers_deletedCollection: Users_DeletedDeleteResponse
  /** Adds one or more `checkouts` records to the collection */
  insertIntocheckoutsCollection?: Maybe<CheckoutsInsertResponse>
  /** Adds one or more `comment_likes` records to the collection */
  insertIntocomment_likesCollection?: Maybe<Comment_LikesInsertResponse>
  /** Adds one or more `comments` records to the collection */
  insertIntocommentsCollection?: Maybe<CommentsInsertResponse>
  /** Adds one or more `credits` records to the collection */
  insertIntocreditsCollection?: Maybe<CreditsInsertResponse>
  /** Adds one or more `credits_history` records to the collection */
  insertIntocredits_historyCollection?: Maybe<Credits_HistoryInsertResponse>
  /** Adds one or more `extract_tag_detail` records to the collection */
  insertIntoextract_tag_detailCollection?: Maybe<Extract_Tag_DetailInsertResponse>
  /** Adds one or more `generate_tasks` records to the collection */
  insertIntogenerate_tasksCollection?: Maybe<Generate_TasksInsertResponse>
  /** Adds one or more `invoices` records to the collection */
  insertIntoinvoicesCollection?: Maybe<InvoicesInsertResponse>
  /** Adds one or more `make_models_history` records to the collection */
  insertIntomake_models_historyCollection?: Maybe<Make_Models_HistoryInsertResponse>
  /** Adds one or more `membership` records to the collection */
  insertIntomembershipCollection?: Maybe<MembershipInsertResponse>
  /** Adds one or more `membership_history` records to the collection */
  insertIntomembership_historyCollection?: Maybe<Membership_HistoryInsertResponse>
  /** Adds one or more `model_subscribers` records to the collection */
  insertIntomodel_subscribersCollection?: Maybe<Model_SubscribersInsertResponse>
  /** Adds one or more `models` records to the collection */
  insertIntomodelsCollection?: Maybe<ModelsInsertResponse>
  /** Adds one or more `models_deleted` records to the collection */
  insertIntomodels_deletedCollection?: Maybe<Models_DeletedInsertResponse>
  /** Adds one or more `payment` records to the collection */
  insertIntopaymentCollection?: Maybe<PaymentInsertResponse>
  /** Adds one or more `post_likes` records to the collection */
  insertIntopost_likesCollection?: Maybe<Post_LikesInsertResponse>
  /** Adds one or more `posts` records to the collection */
  insertIntopostsCollection?: Maybe<PostsInsertResponse>
  /** Adds one or more `posts_deleted` records to the collection */
  insertIntoposts_deletedCollection?: Maybe<Posts_DeletedInsertResponse>
  /** Adds one or more `posts_pending` records to the collection */
  insertIntoposts_pendingCollection?: Maybe<Posts_PendingInsertResponse>
  /** Adds one or more `prices` records to the collection */
  insertIntopricesCollection?: Maybe<PricesInsertResponse>
  /** Adds one or more `products` records to the collection */
  insertIntoproductsCollection?: Maybe<ProductsInsertResponse>
  /** Adds one or more `profiles` records to the collection */
  insertIntoprofilesCollection?: Maybe<ProfilesInsertResponse>
  /** Adds one or more `session_message_interactions` records to the collection */
  insertIntosession_message_interactionsCollection?: Maybe<Session_Message_InteractionsInsertResponse>
  /** Adds one or more `session_messages` records to the collection */
  insertIntosession_messagesCollection?: Maybe<Session_MessagesInsertResponse>
  /** Adds one or more `session_scenario_likes` records to the collection */
  insertIntosession_scenario_likesCollection?: Maybe<Session_Scenario_LikesInsertResponse>
  /** Adds one or more `session_scenarios` records to the collection */
  insertIntosession_scenariosCollection?: Maybe<Session_ScenariosInsertResponse>
  /** Adds one or more `session_topic_interactions` records to the collection */
  insertIntosession_topic_interactionsCollection?: Maybe<Session_Topic_InteractionsInsertResponse>
  /** Adds one or more `session_topics` records to the collection */
  insertIntosession_topicsCollection?: Maybe<Session_TopicsInsertResponse>
  /** Adds one or more `sessions` records to the collection */
  insertIntosessionsCollection?: Maybe<SessionsInsertResponse>
  /** Adds one or more `subscriptions` records to the collection */
  insertIntosubscriptionsCollection?: Maybe<SubscriptionsInsertResponse>
  /** Adds one or more `user_extract_tag` records to the collection */
  insertIntouser_extract_tagCollection?: Maybe<User_Extract_TagInsertResponse>
  /** Adds one or more `user_referrers` records to the collection */
  insertIntouser_referrersCollection?: Maybe<User_ReferrersInsertResponse>
  /** Adds one or more `user_shares` records to the collection */
  insertIntouser_sharesCollection?: Maybe<User_SharesInsertResponse>
  /** Adds one or more `user_subscribers` records to the collection */
  insertIntouser_subscribersCollection?: Maybe<User_SubscribersInsertResponse>
  /** Adds one or more `users` records to the collection */
  insertIntousersCollection?: Maybe<UsersInsertResponse>
  /** Adds one or more `users_deleted` records to the collection */
  insertIntousers_deletedCollection?: Maybe<Users_DeletedInsertResponse>
  /** Updates zero or more records in the `checkouts` collection */
  updatecheckoutsCollection: CheckoutsUpdateResponse
  /** Updates zero or more records in the `comment_likes` collection */
  updatecomment_likesCollection: Comment_LikesUpdateResponse
  /** Updates zero or more records in the `comments` collection */
  updatecommentsCollection: CommentsUpdateResponse
  /** Updates zero or more records in the `credits` collection */
  updatecreditsCollection: CreditsUpdateResponse
  /** Updates zero or more records in the `credits_history` collection */
  updatecredits_historyCollection: Credits_HistoryUpdateResponse
  /** Updates zero or more records in the `extract_tag_detail` collection */
  updateextract_tag_detailCollection: Extract_Tag_DetailUpdateResponse
  /** Updates zero or more records in the `generate_tasks` collection */
  updategenerate_tasksCollection: Generate_TasksUpdateResponse
  /** Updates zero or more records in the `invoices` collection */
  updateinvoicesCollection: InvoicesUpdateResponse
  /** Updates zero or more records in the `make_models_history` collection */
  updatemake_models_historyCollection: Make_Models_HistoryUpdateResponse
  /** Updates zero or more records in the `membership` collection */
  updatemembershipCollection: MembershipUpdateResponse
  /** Updates zero or more records in the `membership_history` collection */
  updatemembership_historyCollection: Membership_HistoryUpdateResponse
  /** Updates zero or more records in the `model_subscribers` collection */
  updatemodel_subscribersCollection: Model_SubscribersUpdateResponse
  /** Updates zero or more records in the `models` collection */
  updatemodelsCollection: ModelsUpdateResponse
  /** Updates zero or more records in the `models_deleted` collection */
  updatemodels_deletedCollection: Models_DeletedUpdateResponse
  /** Updates zero or more records in the `payment` collection */
  updatepaymentCollection: PaymentUpdateResponse
  /** Updates zero or more records in the `post_likes` collection */
  updatepost_likesCollection: Post_LikesUpdateResponse
  /** Updates zero or more records in the `posts` collection */
  updatepostsCollection: PostsUpdateResponse
  /** Updates zero or more records in the `posts_deleted` collection */
  updateposts_deletedCollection: Posts_DeletedUpdateResponse
  /** Updates zero or more records in the `posts_pending` collection */
  updateposts_pendingCollection: Posts_PendingUpdateResponse
  /** Updates zero or more records in the `prices` collection */
  updatepricesCollection: PricesUpdateResponse
  /** Updates zero or more records in the `products` collection */
  updateproductsCollection: ProductsUpdateResponse
  /** Updates zero or more records in the `profiles` collection */
  updateprofilesCollection: ProfilesUpdateResponse
  /** Updates zero or more records in the `session_message_interactions` collection */
  updatesession_message_interactionsCollection: Session_Message_InteractionsUpdateResponse
  /** Updates zero or more records in the `session_messages` collection */
  updatesession_messagesCollection: Session_MessagesUpdateResponse
  /** Updates zero or more records in the `session_scenario_likes` collection */
  updatesession_scenario_likesCollection: Session_Scenario_LikesUpdateResponse
  /** Updates zero or more records in the `session_scenarios` collection */
  updatesession_scenariosCollection: Session_ScenariosUpdateResponse
  /** Updates zero or more records in the `session_topic_interactions` collection */
  updatesession_topic_interactionsCollection: Session_Topic_InteractionsUpdateResponse
  /** Updates zero or more records in the `session_topics` collection */
  updatesession_topicsCollection: Session_TopicsUpdateResponse
  /** Updates zero or more records in the `sessions` collection */
  updatesessionsCollection: SessionsUpdateResponse
  /** Updates zero or more records in the `subscriptions` collection */
  updatesubscriptionsCollection: SubscriptionsUpdateResponse
  /** Updates zero or more records in the `user_extract_tag` collection */
  updateuser_extract_tagCollection: User_Extract_TagUpdateResponse
  /** Updates zero or more records in the `user_referrers` collection */
  updateuser_referrersCollection: User_ReferrersUpdateResponse
  /** Updates zero or more records in the `user_shares` collection */
  updateuser_sharesCollection: User_SharesUpdateResponse
  /** Updates zero or more records in the `user_subscribers` collection */
  updateuser_subscribersCollection: User_SubscribersUpdateResponse
  /** Updates zero or more records in the `users` collection */
  updateusersCollection: UsersUpdateResponse
  /** Updates zero or more records in the `users_deleted` collection */
  updateusers_deletedCollection: Users_DeletedUpdateResponse
}

/** The root type for creating and mutating data */
export type MutationDeleteFromcheckoutsCollectionArgs = {
  atMost?: Scalars['Int']['input']
  filter?: InputMaybe<CheckoutsFilter>
}

/** The root type for creating and mutating data */
export type MutationDeleteFromcomment_LikesCollectionArgs = {
  atMost?: Scalars['Int']['input']
  filter?: InputMaybe<Comment_LikesFilter>
}

/** The root type for creating and mutating data */
export type MutationDeleteFromcommentsCollectionArgs = {
  atMost?: Scalars['Int']['input']
  filter?: InputMaybe<CommentsFilter>
}

/** The root type for creating and mutating data */
export type MutationDeleteFromcreditsCollectionArgs = {
  atMost?: Scalars['Int']['input']
  filter?: InputMaybe<CreditsFilter>
}

/** The root type for creating and mutating data */
export type MutationDeleteFromcredits_HistoryCollectionArgs = {
  atMost?: Scalars['Int']['input']
  filter?: InputMaybe<Credits_HistoryFilter>
}

/** The root type for creating and mutating data */
export type MutationDeleteFromextract_Tag_DetailCollectionArgs = {
  atMost?: Scalars['Int']['input']
  filter?: InputMaybe<Extract_Tag_DetailFilter>
}

/** The root type for creating and mutating data */
export type MutationDeleteFromgenerate_TasksCollectionArgs = {
  atMost?: Scalars['Int']['input']
  filter?: InputMaybe<Generate_TasksFilter>
}

/** The root type for creating and mutating data */
export type MutationDeleteFrominvoicesCollectionArgs = {
  atMost?: Scalars['Int']['input']
  filter?: InputMaybe<InvoicesFilter>
}

/** The root type for creating and mutating data */
export type MutationDeleteFrommake_Models_HistoryCollectionArgs = {
  atMost?: Scalars['Int']['input']
  filter?: InputMaybe<Make_Models_HistoryFilter>
}

/** The root type for creating and mutating data */
export type MutationDeleteFrommembershipCollectionArgs = {
  atMost?: Scalars['Int']['input']
  filter?: InputMaybe<MembershipFilter>
}

/** The root type for creating and mutating data */
export type MutationDeleteFrommembership_HistoryCollectionArgs = {
  atMost?: Scalars['Int']['input']
  filter?: InputMaybe<Membership_HistoryFilter>
}

/** The root type for creating and mutating data */
export type MutationDeleteFrommodel_SubscribersCollectionArgs = {
  atMost?: Scalars['Int']['input']
  filter?: InputMaybe<Model_SubscribersFilter>
}

/** The root type for creating and mutating data */
export type MutationDeleteFrommodelsCollectionArgs = {
  atMost?: Scalars['Int']['input']
  filter?: InputMaybe<ModelsFilter>
}

/** The root type for creating and mutating data */
export type MutationDeleteFrommodels_DeletedCollectionArgs = {
  atMost?: Scalars['Int']['input']
  filter?: InputMaybe<Models_DeletedFilter>
}

/** The root type for creating and mutating data */
export type MutationDeleteFrompaymentCollectionArgs = {
  atMost?: Scalars['Int']['input']
  filter?: InputMaybe<PaymentFilter>
}

/** The root type for creating and mutating data */
export type MutationDeleteFrompost_LikesCollectionArgs = {
  atMost?: Scalars['Int']['input']
  filter?: InputMaybe<Post_LikesFilter>
}

/** The root type for creating and mutating data */
export type MutationDeleteFrompostsCollectionArgs = {
  atMost?: Scalars['Int']['input']
  filter?: InputMaybe<PostsFilter>
}

/** The root type for creating and mutating data */
export type MutationDeleteFromposts_DeletedCollectionArgs = {
  atMost?: Scalars['Int']['input']
  filter?: InputMaybe<Posts_DeletedFilter>
}

/** The root type for creating and mutating data */
export type MutationDeleteFromposts_PendingCollectionArgs = {
  atMost?: Scalars['Int']['input']
  filter?: InputMaybe<Posts_PendingFilter>
}

/** The root type for creating and mutating data */
export type MutationDeleteFrompricesCollectionArgs = {
  atMost?: Scalars['Int']['input']
  filter?: InputMaybe<PricesFilter>
}

/** The root type for creating and mutating data */
export type MutationDeleteFromproductsCollectionArgs = {
  atMost?: Scalars['Int']['input']
  filter?: InputMaybe<ProductsFilter>
}

/** The root type for creating and mutating data */
export type MutationDeleteFromprofilesCollectionArgs = {
  atMost?: Scalars['Int']['input']
  filter?: InputMaybe<ProfilesFilter>
}

/** The root type for creating and mutating data */
export type MutationDeleteFromsession_Message_InteractionsCollectionArgs = {
  atMost?: Scalars['Int']['input']
  filter?: InputMaybe<Session_Message_InteractionsFilter>
}

/** The root type for creating and mutating data */
export type MutationDeleteFromsession_MessagesCollectionArgs = {
  atMost?: Scalars['Int']['input']
  filter?: InputMaybe<Session_MessagesFilter>
}

/** The root type for creating and mutating data */
export type MutationDeleteFromsession_Scenario_LikesCollectionArgs = {
  atMost?: Scalars['Int']['input']
  filter?: InputMaybe<Session_Scenario_LikesFilter>
}

/** The root type for creating and mutating data */
export type MutationDeleteFromsession_ScenariosCollectionArgs = {
  atMost?: Scalars['Int']['input']
  filter?: InputMaybe<Session_ScenariosFilter>
}

/** The root type for creating and mutating data */
export type MutationDeleteFromsession_Topic_InteractionsCollectionArgs = {
  atMost?: Scalars['Int']['input']
  filter?: InputMaybe<Session_Topic_InteractionsFilter>
}

/** The root type for creating and mutating data */
export type MutationDeleteFromsession_TopicsCollectionArgs = {
  atMost?: Scalars['Int']['input']
  filter?: InputMaybe<Session_TopicsFilter>
}

/** The root type for creating and mutating data */
export type MutationDeleteFromsessionsCollectionArgs = {
  atMost?: Scalars['Int']['input']
  filter?: InputMaybe<SessionsFilter>
}

/** The root type for creating and mutating data */
export type MutationDeleteFromsubscriptionsCollectionArgs = {
  atMost?: Scalars['Int']['input']
  filter?: InputMaybe<SubscriptionsFilter>
}

/** The root type for creating and mutating data */
export type MutationDeleteFromuser_Extract_TagCollectionArgs = {
  atMost?: Scalars['Int']['input']
  filter?: InputMaybe<User_Extract_TagFilter>
}

/** The root type for creating and mutating data */
export type MutationDeleteFromuser_ReferrersCollectionArgs = {
  atMost?: Scalars['Int']['input']
  filter?: InputMaybe<User_ReferrersFilter>
}

/** The root type for creating and mutating data */
export type MutationDeleteFromuser_SharesCollectionArgs = {
  atMost?: Scalars['Int']['input']
  filter?: InputMaybe<User_SharesFilter>
}

/** The root type for creating and mutating data */
export type MutationDeleteFromuser_SubscribersCollectionArgs = {
  atMost?: Scalars['Int']['input']
  filter?: InputMaybe<User_SubscribersFilter>
}

/** The root type for creating and mutating data */
export type MutationDeleteFromusersCollectionArgs = {
  atMost?: Scalars['Int']['input']
  filter?: InputMaybe<UsersFilter>
}

/** The root type for creating and mutating data */
export type MutationDeleteFromusers_DeletedCollectionArgs = {
  atMost?: Scalars['Int']['input']
  filter?: InputMaybe<Users_DeletedFilter>
}

/** The root type for creating and mutating data */
export type MutationInsertIntocheckoutsCollectionArgs = {
  objects: Array<CheckoutsInsertInput>
}

/** The root type for creating and mutating data */
export type MutationInsertIntocomment_LikesCollectionArgs = {
  objects: Array<Comment_LikesInsertInput>
}

/** The root type for creating and mutating data */
export type MutationInsertIntocommentsCollectionArgs = {
  objects: Array<CommentsInsertInput>
}

/** The root type for creating and mutating data */
export type MutationInsertIntocreditsCollectionArgs = {
  objects: Array<CreditsInsertInput>
}

/** The root type for creating and mutating data */
export type MutationInsertIntocredits_HistoryCollectionArgs = {
  objects: Array<Credits_HistoryInsertInput>
}

/** The root type for creating and mutating data */
export type MutationInsertIntoextract_Tag_DetailCollectionArgs = {
  objects: Array<Extract_Tag_DetailInsertInput>
}

/** The root type for creating and mutating data */
export type MutationInsertIntogenerate_TasksCollectionArgs = {
  objects: Array<Generate_TasksInsertInput>
}

/** The root type for creating and mutating data */
export type MutationInsertIntoinvoicesCollectionArgs = {
  objects: Array<InvoicesInsertInput>
}

/** The root type for creating and mutating data */
export type MutationInsertIntomake_Models_HistoryCollectionArgs = {
  objects: Array<Make_Models_HistoryInsertInput>
}

/** The root type for creating and mutating data */
export type MutationInsertIntomembershipCollectionArgs = {
  objects: Array<MembershipInsertInput>
}

/** The root type for creating and mutating data */
export type MutationInsertIntomembership_HistoryCollectionArgs = {
  objects: Array<Membership_HistoryInsertInput>
}

/** The root type for creating and mutating data */
export type MutationInsertIntomodel_SubscribersCollectionArgs = {
  objects: Array<Model_SubscribersInsertInput>
}

/** The root type for creating and mutating data */
export type MutationInsertIntomodelsCollectionArgs = {
  objects: Array<ModelsInsertInput>
}

/** The root type for creating and mutating data */
export type MutationInsertIntomodels_DeletedCollectionArgs = {
  objects: Array<Models_DeletedInsertInput>
}

/** The root type for creating and mutating data */
export type MutationInsertIntopaymentCollectionArgs = {
  objects: Array<PaymentInsertInput>
}

/** The root type for creating and mutating data */
export type MutationInsertIntopost_LikesCollectionArgs = {
  objects: Array<Post_LikesInsertInput>
}

/** The root type for creating and mutating data */
export type MutationInsertIntopostsCollectionArgs = {
  objects: Array<PostsInsertInput>
}

/** The root type for creating and mutating data */
export type MutationInsertIntoposts_DeletedCollectionArgs = {
  objects: Array<Posts_DeletedInsertInput>
}

/** The root type for creating and mutating data */
export type MutationInsertIntoposts_PendingCollectionArgs = {
  objects: Array<Posts_PendingInsertInput>
}

/** The root type for creating and mutating data */
export type MutationInsertIntopricesCollectionArgs = {
  objects: Array<PricesInsertInput>
}

/** The root type for creating and mutating data */
export type MutationInsertIntoproductsCollectionArgs = {
  objects: Array<ProductsInsertInput>
}

/** The root type for creating and mutating data */
export type MutationInsertIntoprofilesCollectionArgs = {
  objects: Array<ProfilesInsertInput>
}

/** The root type for creating and mutating data */
export type MutationInsertIntosession_Message_InteractionsCollectionArgs = {
  objects: Array<Session_Message_InteractionsInsertInput>
}

/** The root type for creating and mutating data */
export type MutationInsertIntosession_MessagesCollectionArgs = {
  objects: Array<Session_MessagesInsertInput>
}

/** The root type for creating and mutating data */
export type MutationInsertIntosession_Scenario_LikesCollectionArgs = {
  objects: Array<Session_Scenario_LikesInsertInput>
}

/** The root type for creating and mutating data */
export type MutationInsertIntosession_ScenariosCollectionArgs = {
  objects: Array<Session_ScenariosInsertInput>
}

/** The root type for creating and mutating data */
export type MutationInsertIntosession_Topic_InteractionsCollectionArgs = {
  objects: Array<Session_Topic_InteractionsInsertInput>
}

/** The root type for creating and mutating data */
export type MutationInsertIntosession_TopicsCollectionArgs = {
  objects: Array<Session_TopicsInsertInput>
}

/** The root type for creating and mutating data */
export type MutationInsertIntosessionsCollectionArgs = {
  objects: Array<SessionsInsertInput>
}

/** The root type for creating and mutating data */
export type MutationInsertIntosubscriptionsCollectionArgs = {
  objects: Array<SubscriptionsInsertInput>
}

/** The root type for creating and mutating data */
export type MutationInsertIntouser_Extract_TagCollectionArgs = {
  objects: Array<User_Extract_TagInsertInput>
}

/** The root type for creating and mutating data */
export type MutationInsertIntouser_ReferrersCollectionArgs = {
  objects: Array<User_ReferrersInsertInput>
}

/** The root type for creating and mutating data */
export type MutationInsertIntouser_SharesCollectionArgs = {
  objects: Array<User_SharesInsertInput>
}

/** The root type for creating and mutating data */
export type MutationInsertIntouser_SubscribersCollectionArgs = {
  objects: Array<User_SubscribersInsertInput>
}

/** The root type for creating and mutating data */
export type MutationInsertIntousersCollectionArgs = {
  objects: Array<UsersInsertInput>
}

/** The root type for creating and mutating data */
export type MutationInsertIntousers_DeletedCollectionArgs = {
  objects: Array<Users_DeletedInsertInput>
}

/** The root type for creating and mutating data */
export type MutationUpdatecheckoutsCollectionArgs = {
  atMost?: Scalars['Int']['input']
  filter?: InputMaybe<CheckoutsFilter>
  set: CheckoutsUpdateInput
}

/** The root type for creating and mutating data */
export type MutationUpdatecomment_LikesCollectionArgs = {
  atMost?: Scalars['Int']['input']
  filter?: InputMaybe<Comment_LikesFilter>
  set: Comment_LikesUpdateInput
}

/** The root type for creating and mutating data */
export type MutationUpdatecommentsCollectionArgs = {
  atMost?: Scalars['Int']['input']
  filter?: InputMaybe<CommentsFilter>
  set: CommentsUpdateInput
}

/** The root type for creating and mutating data */
export type MutationUpdatecreditsCollectionArgs = {
  atMost?: Scalars['Int']['input']
  filter?: InputMaybe<CreditsFilter>
  set: CreditsUpdateInput
}

/** The root type for creating and mutating data */
export type MutationUpdatecredits_HistoryCollectionArgs = {
  atMost?: Scalars['Int']['input']
  filter?: InputMaybe<Credits_HistoryFilter>
  set: Credits_HistoryUpdateInput
}

/** The root type for creating and mutating data */
export type MutationUpdateextract_Tag_DetailCollectionArgs = {
  atMost?: Scalars['Int']['input']
  filter?: InputMaybe<Extract_Tag_DetailFilter>
  set: Extract_Tag_DetailUpdateInput
}

/** The root type for creating and mutating data */
export type MutationUpdategenerate_TasksCollectionArgs = {
  atMost?: Scalars['Int']['input']
  filter?: InputMaybe<Generate_TasksFilter>
  set: Generate_TasksUpdateInput
}

/** The root type for creating and mutating data */
export type MutationUpdateinvoicesCollectionArgs = {
  atMost?: Scalars['Int']['input']
  filter?: InputMaybe<InvoicesFilter>
  set: InvoicesUpdateInput
}

/** The root type for creating and mutating data */
export type MutationUpdatemake_Models_HistoryCollectionArgs = {
  atMost?: Scalars['Int']['input']
  filter?: InputMaybe<Make_Models_HistoryFilter>
  set: Make_Models_HistoryUpdateInput
}

/** The root type for creating and mutating data */
export type MutationUpdatemembershipCollectionArgs = {
  atMost?: Scalars['Int']['input']
  filter?: InputMaybe<MembershipFilter>
  set: MembershipUpdateInput
}

/** The root type for creating and mutating data */
export type MutationUpdatemembership_HistoryCollectionArgs = {
  atMost?: Scalars['Int']['input']
  filter?: InputMaybe<Membership_HistoryFilter>
  set: Membership_HistoryUpdateInput
}

/** The root type for creating and mutating data */
export type MutationUpdatemodel_SubscribersCollectionArgs = {
  atMost?: Scalars['Int']['input']
  filter?: InputMaybe<Model_SubscribersFilter>
  set: Model_SubscribersUpdateInput
}

/** The root type for creating and mutating data */
export type MutationUpdatemodelsCollectionArgs = {
  atMost?: Scalars['Int']['input']
  filter?: InputMaybe<ModelsFilter>
  set: ModelsUpdateInput
}

/** The root type for creating and mutating data */
export type MutationUpdatemodels_DeletedCollectionArgs = {
  atMost?: Scalars['Int']['input']
  filter?: InputMaybe<Models_DeletedFilter>
  set: Models_DeletedUpdateInput
}

/** The root type for creating and mutating data */
export type MutationUpdatepaymentCollectionArgs = {
  atMost?: Scalars['Int']['input']
  filter?: InputMaybe<PaymentFilter>
  set: PaymentUpdateInput
}

/** The root type for creating and mutating data */
export type MutationUpdatepost_LikesCollectionArgs = {
  atMost?: Scalars['Int']['input']
  filter?: InputMaybe<Post_LikesFilter>
  set: Post_LikesUpdateInput
}

/** The root type for creating and mutating data */
export type MutationUpdatepostsCollectionArgs = {
  atMost?: Scalars['Int']['input']
  filter?: InputMaybe<PostsFilter>
  set: PostsUpdateInput
}

/** The root type for creating and mutating data */
export type MutationUpdateposts_DeletedCollectionArgs = {
  atMost?: Scalars['Int']['input']
  filter?: InputMaybe<Posts_DeletedFilter>
  set: Posts_DeletedUpdateInput
}

/** The root type for creating and mutating data */
export type MutationUpdateposts_PendingCollectionArgs = {
  atMost?: Scalars['Int']['input']
  filter?: InputMaybe<Posts_PendingFilter>
  set: Posts_PendingUpdateInput
}

/** The root type for creating and mutating data */
export type MutationUpdatepricesCollectionArgs = {
  atMost?: Scalars['Int']['input']
  filter?: InputMaybe<PricesFilter>
  set: PricesUpdateInput
}

/** The root type for creating and mutating data */
export type MutationUpdateproductsCollectionArgs = {
  atMost?: Scalars['Int']['input']
  filter?: InputMaybe<ProductsFilter>
  set: ProductsUpdateInput
}

/** The root type for creating and mutating data */
export type MutationUpdateprofilesCollectionArgs = {
  atMost?: Scalars['Int']['input']
  filter?: InputMaybe<ProfilesFilter>
  set: ProfilesUpdateInput
}

/** The root type for creating and mutating data */
export type MutationUpdatesession_Message_InteractionsCollectionArgs = {
  atMost?: Scalars['Int']['input']
  filter?: InputMaybe<Session_Message_InteractionsFilter>
  set: Session_Message_InteractionsUpdateInput
}

/** The root type for creating and mutating data */
export type MutationUpdatesession_MessagesCollectionArgs = {
  atMost?: Scalars['Int']['input']
  filter?: InputMaybe<Session_MessagesFilter>
  set: Session_MessagesUpdateInput
}

/** The root type for creating and mutating data */
export type MutationUpdatesession_Scenario_LikesCollectionArgs = {
  atMost?: Scalars['Int']['input']
  filter?: InputMaybe<Session_Scenario_LikesFilter>
  set: Session_Scenario_LikesUpdateInput
}

/** The root type for creating and mutating data */
export type MutationUpdatesession_ScenariosCollectionArgs = {
  atMost?: Scalars['Int']['input']
  filter?: InputMaybe<Session_ScenariosFilter>
  set: Session_ScenariosUpdateInput
}

/** The root type for creating and mutating data */
export type MutationUpdatesession_Topic_InteractionsCollectionArgs = {
  atMost?: Scalars['Int']['input']
  filter?: InputMaybe<Session_Topic_InteractionsFilter>
  set: Session_Topic_InteractionsUpdateInput
}

/** The root type for creating and mutating data */
export type MutationUpdatesession_TopicsCollectionArgs = {
  atMost?: Scalars['Int']['input']
  filter?: InputMaybe<Session_TopicsFilter>
  set: Session_TopicsUpdateInput
}

/** The root type for creating and mutating data */
export type MutationUpdatesessionsCollectionArgs = {
  atMost?: Scalars['Int']['input']
  filter?: InputMaybe<SessionsFilter>
  set: SessionsUpdateInput
}

/** The root type for creating and mutating data */
export type MutationUpdatesubscriptionsCollectionArgs = {
  atMost?: Scalars['Int']['input']
  filter?: InputMaybe<SubscriptionsFilter>
  set: SubscriptionsUpdateInput
}

/** The root type for creating and mutating data */
export type MutationUpdateuser_Extract_TagCollectionArgs = {
  atMost?: Scalars['Int']['input']
  filter?: InputMaybe<User_Extract_TagFilter>
  set: User_Extract_TagUpdateInput
}

/** The root type for creating and mutating data */
export type MutationUpdateuser_ReferrersCollectionArgs = {
  atMost?: Scalars['Int']['input']
  filter?: InputMaybe<User_ReferrersFilter>
  set: User_ReferrersUpdateInput
}

/** The root type for creating and mutating data */
export type MutationUpdateuser_SharesCollectionArgs = {
  atMost?: Scalars['Int']['input']
  filter?: InputMaybe<User_SharesFilter>
  set: User_SharesUpdateInput
}

/** The root type for creating and mutating data */
export type MutationUpdateuser_SubscribersCollectionArgs = {
  atMost?: Scalars['Int']['input']
  filter?: InputMaybe<User_SubscribersFilter>
  set: User_SubscribersUpdateInput
}

/** The root type for creating and mutating data */
export type MutationUpdateusersCollectionArgs = {
  atMost?: Scalars['Int']['input']
  filter?: InputMaybe<UsersFilter>
  set: UsersUpdateInput
}

/** The root type for creating and mutating data */
export type MutationUpdateusers_DeletedCollectionArgs = {
  atMost?: Scalars['Int']['input']
  filter?: InputMaybe<Users_DeletedFilter>
  set: Users_DeletedUpdateInput
}

export type Node = {
  /** Retrieves a record by `ID` */
  nodeId: Scalars['ID']['output']
}

/** Boolean expression comparing fields on type "Opaque" */
export type OpaqueFilter = {
  eq?: InputMaybe<Scalars['Opaque']['input']>
  is?: InputMaybe<FilterIs>
}

/** Defines a per-field sorting order */
export enum OrderByDirection {
  /** Ascending order, nulls first */
  AscNullsFirst = 'AscNullsFirst',
  /** Ascending order, nulls last */
  AscNullsLast = 'AscNullsLast',
  /** Descending order, nulls first */
  DescNullsFirst = 'DescNullsFirst',
  /** Descending order, nulls last */
  DescNullsLast = 'DescNullsLast',
}

export type PageInfo = {
  __typename?: 'PageInfo'
  endCursor?: Maybe<Scalars['String']['output']>
  hasNextPage: Scalars['Boolean']['output']
  hasPreviousPage: Scalars['Boolean']['output']
  startCursor?: Maybe<Scalars['String']['output']>
}

/** The root type for querying data */
export type Query = {
  __typename?: 'Query'
  /** A pagable collection of type `checkouts` */
  checkoutsCollection?: Maybe<CheckoutsConnection>
  /** A pagable collection of type `comment_likes` */
  comment_likesCollection?: Maybe<Comment_LikesConnection>
  /** A pagable collection of type `comments` */
  commentsCollection?: Maybe<CommentsConnection>
  /** A pagable collection of type `credits` */
  creditsCollection?: Maybe<CreditsConnection>
  /** A pagable collection of type `credits_history` */
  credits_historyCollection?: Maybe<Credits_HistoryConnection>
  /** A pagable collection of type `extract_tag_detail` */
  extract_tag_detailCollection?: Maybe<Extract_Tag_DetailConnection>
  /** A pagable collection of type `generate_tasks` */
  generate_tasksCollection?: Maybe<Generate_TasksConnection>
  /** A pagable collection of type `invoices` */
  invoicesCollection?: Maybe<InvoicesConnection>
  /** A pagable collection of type `make_models_history` */
  make_models_historyCollection?: Maybe<Make_Models_HistoryConnection>
  /** A pagable collection of type `membership` */
  membershipCollection?: Maybe<MembershipConnection>
  /** A pagable collection of type `membership_history` */
  membership_historyCollection?: Maybe<Membership_HistoryConnection>
  /** A pagable collection of type `model_subscribers` */
  model_subscribersCollection?: Maybe<Model_SubscribersConnection>
  /** A pagable collection of type `models` */
  modelsCollection?: Maybe<ModelsConnection>
  /** A pagable collection of type `models_deleted` */
  models_deletedCollection?: Maybe<Models_DeletedConnection>
  /** Retrieve a record by its `ID` */
  node?: Maybe<Node>
  /** A pagable collection of type `payment` */
  paymentCollection?: Maybe<PaymentConnection>
  /** A pagable collection of type `post_likes` */
  post_likesCollection?: Maybe<Post_LikesConnection>
  /** A pagable collection of type `posts` */
  postsCollection?: Maybe<PostsConnection>
  /** A pagable collection of type `posts_deleted` */
  posts_deletedCollection?: Maybe<Posts_DeletedConnection>
  /** A pagable collection of type `posts_pending` */
  posts_pendingCollection?: Maybe<Posts_PendingConnection>
  /** A pagable collection of type `prices` */
  pricesCollection?: Maybe<PricesConnection>
  /** A pagable collection of type `products` */
  productsCollection?: Maybe<ProductsConnection>
  /** A pagable collection of type `profiles` */
  profilesCollection?: Maybe<ProfilesConnection>
  /** A pagable collection of type `session_message_interactions` */
  session_message_interactionsCollection?: Maybe<Session_Message_InteractionsConnection>
  /** A pagable collection of type `session_messages` */
  session_messagesCollection?: Maybe<Session_MessagesConnection>
  /** A pagable collection of type `session_scenario_likes` */
  session_scenario_likesCollection?: Maybe<Session_Scenario_LikesConnection>
  /** A pagable collection of type `session_scenarios` */
  session_scenariosCollection?: Maybe<Session_ScenariosConnection>
  /** A pagable collection of type `session_topic_interactions` */
  session_topic_interactionsCollection?: Maybe<Session_Topic_InteractionsConnection>
  /** A pagable collection of type `session_topics` */
  session_topicsCollection?: Maybe<Session_TopicsConnection>
  /** A pagable collection of type `sessions` */
  sessionsCollection?: Maybe<SessionsConnection>
  /** A pagable collection of type `subscriptions` */
  subscriptionsCollection?: Maybe<SubscriptionsConnection>
  /** A pagable collection of type `user_extract_tag` */
  user_extract_tagCollection?: Maybe<User_Extract_TagConnection>
  /** A pagable collection of type `user_referrers` */
  user_referrersCollection?: Maybe<User_ReferrersConnection>
  /** A pagable collection of type `user_shares` */
  user_sharesCollection?: Maybe<User_SharesConnection>
  /** A pagable collection of type `user_subscribers` */
  user_subscribersCollection?: Maybe<User_SubscribersConnection>
  /** A pagable collection of type `users` */
  usersCollection?: Maybe<UsersConnection>
  /** A pagable collection of type `users_deleted` */
  users_deletedCollection?: Maybe<Users_DeletedConnection>
}

/** The root type for querying data */
export type QueryCheckoutsCollectionArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  before?: InputMaybe<Scalars['Cursor']['input']>
  filter?: InputMaybe<CheckoutsFilter>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Array<CheckoutsOrderBy>>
}

/** The root type for querying data */
export type QueryComment_LikesCollectionArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  before?: InputMaybe<Scalars['Cursor']['input']>
  filter?: InputMaybe<Comment_LikesFilter>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Array<Comment_LikesOrderBy>>
}

/** The root type for querying data */
export type QueryCommentsCollectionArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  before?: InputMaybe<Scalars['Cursor']['input']>
  filter?: InputMaybe<CommentsFilter>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Array<CommentsOrderBy>>
}

/** The root type for querying data */
export type QueryCreditsCollectionArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  before?: InputMaybe<Scalars['Cursor']['input']>
  filter?: InputMaybe<CreditsFilter>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Array<CreditsOrderBy>>
}

/** The root type for querying data */
export type QueryCredits_HistoryCollectionArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  before?: InputMaybe<Scalars['Cursor']['input']>
  filter?: InputMaybe<Credits_HistoryFilter>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Array<Credits_HistoryOrderBy>>
}

/** The root type for querying data */
export type QueryExtract_Tag_DetailCollectionArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  before?: InputMaybe<Scalars['Cursor']['input']>
  filter?: InputMaybe<Extract_Tag_DetailFilter>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Array<Extract_Tag_DetailOrderBy>>
}

/** The root type for querying data */
export type QueryGenerate_TasksCollectionArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  before?: InputMaybe<Scalars['Cursor']['input']>
  filter?: InputMaybe<Generate_TasksFilter>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Array<Generate_TasksOrderBy>>
}

/** The root type for querying data */
export type QueryInvoicesCollectionArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  before?: InputMaybe<Scalars['Cursor']['input']>
  filter?: InputMaybe<InvoicesFilter>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Array<InvoicesOrderBy>>
}

/** The root type for querying data */
export type QueryMake_Models_HistoryCollectionArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  before?: InputMaybe<Scalars['Cursor']['input']>
  filter?: InputMaybe<Make_Models_HistoryFilter>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Array<Make_Models_HistoryOrderBy>>
}

/** The root type for querying data */
export type QueryMembershipCollectionArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  before?: InputMaybe<Scalars['Cursor']['input']>
  filter?: InputMaybe<MembershipFilter>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Array<MembershipOrderBy>>
}

/** The root type for querying data */
export type QueryMembership_HistoryCollectionArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  before?: InputMaybe<Scalars['Cursor']['input']>
  filter?: InputMaybe<Membership_HistoryFilter>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Array<Membership_HistoryOrderBy>>
}

/** The root type for querying data */
export type QueryModel_SubscribersCollectionArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  before?: InputMaybe<Scalars['Cursor']['input']>
  filter?: InputMaybe<Model_SubscribersFilter>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Array<Model_SubscribersOrderBy>>
}

/** The root type for querying data */
export type QueryModelsCollectionArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  before?: InputMaybe<Scalars['Cursor']['input']>
  filter?: InputMaybe<ModelsFilter>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Array<ModelsOrderBy>>
}

/** The root type for querying data */
export type QueryModels_DeletedCollectionArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  before?: InputMaybe<Scalars['Cursor']['input']>
  filter?: InputMaybe<Models_DeletedFilter>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Array<Models_DeletedOrderBy>>
}

/** The root type for querying data */
export type QueryNodeArgs = {
  nodeId: Scalars['ID']['input']
}

/** The root type for querying data */
export type QueryPaymentCollectionArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  before?: InputMaybe<Scalars['Cursor']['input']>
  filter?: InputMaybe<PaymentFilter>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Array<PaymentOrderBy>>
}

/** The root type for querying data */
export type QueryPost_LikesCollectionArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  before?: InputMaybe<Scalars['Cursor']['input']>
  filter?: InputMaybe<Post_LikesFilter>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Array<Post_LikesOrderBy>>
}

/** The root type for querying data */
export type QueryPostsCollectionArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  before?: InputMaybe<Scalars['Cursor']['input']>
  filter?: InputMaybe<PostsFilter>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Array<PostsOrderBy>>
}

/** The root type for querying data */
export type QueryPosts_DeletedCollectionArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  before?: InputMaybe<Scalars['Cursor']['input']>
  filter?: InputMaybe<Posts_DeletedFilter>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Array<Posts_DeletedOrderBy>>
}

/** The root type for querying data */
export type QueryPosts_PendingCollectionArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  before?: InputMaybe<Scalars['Cursor']['input']>
  filter?: InputMaybe<Posts_PendingFilter>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Array<Posts_PendingOrderBy>>
}

/** The root type for querying data */
export type QueryPricesCollectionArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  before?: InputMaybe<Scalars['Cursor']['input']>
  filter?: InputMaybe<PricesFilter>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Array<PricesOrderBy>>
}

/** The root type for querying data */
export type QueryProductsCollectionArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  before?: InputMaybe<Scalars['Cursor']['input']>
  filter?: InputMaybe<ProductsFilter>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Array<ProductsOrderBy>>
}

/** The root type for querying data */
export type QueryProfilesCollectionArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  before?: InputMaybe<Scalars['Cursor']['input']>
  filter?: InputMaybe<ProfilesFilter>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Array<ProfilesOrderBy>>
}

/** The root type for querying data */
export type QuerySession_Message_InteractionsCollectionArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  before?: InputMaybe<Scalars['Cursor']['input']>
  filter?: InputMaybe<Session_Message_InteractionsFilter>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Array<Session_Message_InteractionsOrderBy>>
}

/** The root type for querying data */
export type QuerySession_MessagesCollectionArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  before?: InputMaybe<Scalars['Cursor']['input']>
  filter?: InputMaybe<Session_MessagesFilter>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Array<Session_MessagesOrderBy>>
}

/** The root type for querying data */
export type QuerySession_Scenario_LikesCollectionArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  before?: InputMaybe<Scalars['Cursor']['input']>
  filter?: InputMaybe<Session_Scenario_LikesFilter>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Array<Session_Scenario_LikesOrderBy>>
}

/** The root type for querying data */
export type QuerySession_ScenariosCollectionArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  before?: InputMaybe<Scalars['Cursor']['input']>
  filter?: InputMaybe<Session_ScenariosFilter>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Array<Session_ScenariosOrderBy>>
}

/** The root type for querying data */
export type QuerySession_Topic_InteractionsCollectionArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  before?: InputMaybe<Scalars['Cursor']['input']>
  filter?: InputMaybe<Session_Topic_InteractionsFilter>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Array<Session_Topic_InteractionsOrderBy>>
}

/** The root type for querying data */
export type QuerySession_TopicsCollectionArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  before?: InputMaybe<Scalars['Cursor']['input']>
  filter?: InputMaybe<Session_TopicsFilter>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Array<Session_TopicsOrderBy>>
}

/** The root type for querying data */
export type QuerySessionsCollectionArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  before?: InputMaybe<Scalars['Cursor']['input']>
  filter?: InputMaybe<SessionsFilter>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Array<SessionsOrderBy>>
}

/** The root type for querying data */
export type QuerySubscriptionsCollectionArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  before?: InputMaybe<Scalars['Cursor']['input']>
  filter?: InputMaybe<SubscriptionsFilter>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Array<SubscriptionsOrderBy>>
}

/** The root type for querying data */
export type QueryUser_Extract_TagCollectionArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  before?: InputMaybe<Scalars['Cursor']['input']>
  filter?: InputMaybe<User_Extract_TagFilter>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Array<User_Extract_TagOrderBy>>
}

/** The root type for querying data */
export type QueryUser_ReferrersCollectionArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  before?: InputMaybe<Scalars['Cursor']['input']>
  filter?: InputMaybe<User_ReferrersFilter>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Array<User_ReferrersOrderBy>>
}

/** The root type for querying data */
export type QueryUser_SharesCollectionArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  before?: InputMaybe<Scalars['Cursor']['input']>
  filter?: InputMaybe<User_SharesFilter>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Array<User_SharesOrderBy>>
}

/** The root type for querying data */
export type QueryUser_SubscribersCollectionArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  before?: InputMaybe<Scalars['Cursor']['input']>
  filter?: InputMaybe<User_SubscribersFilter>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Array<User_SubscribersOrderBy>>
}

/** The root type for querying data */
export type QueryUsersCollectionArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  before?: InputMaybe<Scalars['Cursor']['input']>
  filter?: InputMaybe<UsersFilter>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Array<UsersOrderBy>>
}

/** The root type for querying data */
export type QueryUsers_DeletedCollectionArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  before?: InputMaybe<Scalars['Cursor']['input']>
  filter?: InputMaybe<Users_DeletedFilter>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Array<Users_DeletedOrderBy>>
}

/** Boolean expression comparing fields on type "String" */
export type StringFilter = {
  eq?: InputMaybe<Scalars['String']['input']>
  gt?: InputMaybe<Scalars['String']['input']>
  gte?: InputMaybe<Scalars['String']['input']>
  ilike?: InputMaybe<Scalars['String']['input']>
  in?: InputMaybe<Array<Scalars['String']['input']>>
  iregex?: InputMaybe<Scalars['String']['input']>
  is?: InputMaybe<FilterIs>
  like?: InputMaybe<Scalars['String']['input']>
  lt?: InputMaybe<Scalars['String']['input']>
  lte?: InputMaybe<Scalars['String']['input']>
  neq?: InputMaybe<Scalars['String']['input']>
  regex?: InputMaybe<Scalars['String']['input']>
  startsWith?: InputMaybe<Scalars['String']['input']>
}

/** Boolean expression comparing fields on type "Time" */
export type TimeFilter = {
  eq?: InputMaybe<Scalars['Time']['input']>
  gt?: InputMaybe<Scalars['Time']['input']>
  gte?: InputMaybe<Scalars['Time']['input']>
  in?: InputMaybe<Array<Scalars['Time']['input']>>
  is?: InputMaybe<FilterIs>
  lt?: InputMaybe<Scalars['Time']['input']>
  lte?: InputMaybe<Scalars['Time']['input']>
  neq?: InputMaybe<Scalars['Time']['input']>
}

/** Boolean expression comparing fields on type "UUID" */
export type UuidFilter = {
  eq?: InputMaybe<Scalars['UUID']['input']>
  in?: InputMaybe<Array<Scalars['UUID']['input']>>
  is?: InputMaybe<FilterIs>
  neq?: InputMaybe<Scalars['UUID']['input']>
}

export enum Checkout_Type {
  Payment = 'payment',
  Setup = 'setup',
  Subscription = 'subscription',
}

/** Boolean expression comparing fields on type "checkout_type" */
export type Checkout_TypeFilter = {
  eq?: InputMaybe<Checkout_Type>
  in?: InputMaybe<Array<Checkout_Type>>
  is?: InputMaybe<FilterIs>
  neq?: InputMaybe<Checkout_Type>
}

export type Checkouts = Node & {
  __typename?: 'checkouts'
  created_at: Scalars['Datetime']['output']
  customer_id: Scalars['String']['output']
  id: Scalars['String']['output']
  mode: Checkout_Type
  /** Globally Unique Record Identifier */
  nodeId: Scalars['ID']['output']
  price_id: Scalars['String']['output']
  product_id: Scalars['String']['output']
  subscription_id?: Maybe<Scalars['String']['output']>
}

export type CheckoutsConnection = {
  __typename?: 'checkoutsConnection'
  edges: Array<CheckoutsEdge>
  pageInfo: PageInfo
}

export type CheckoutsDeleteResponse = {
  __typename?: 'checkoutsDeleteResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Checkouts>
}

export type CheckoutsEdge = {
  __typename?: 'checkoutsEdge'
  cursor: Scalars['String']['output']
  node: Checkouts
}

export type CheckoutsFilter = {
  /** Returns true only if all its inner filters are true, otherwise returns false */
  and?: InputMaybe<Array<CheckoutsFilter>>
  created_at?: InputMaybe<DatetimeFilter>
  customer_id?: InputMaybe<StringFilter>
  id?: InputMaybe<StringFilter>
  mode?: InputMaybe<Checkout_TypeFilter>
  nodeId?: InputMaybe<IdFilter>
  /** Negates a filter */
  not?: InputMaybe<CheckoutsFilter>
  /** Returns true if at least one of its inner filters is true, otherwise returns false */
  or?: InputMaybe<Array<CheckoutsFilter>>
  price_id?: InputMaybe<StringFilter>
  product_id?: InputMaybe<StringFilter>
  subscription_id?: InputMaybe<StringFilter>
}

export type CheckoutsInsertInput = {
  created_at?: InputMaybe<Scalars['Datetime']['input']>
  customer_id?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['String']['input']>
  mode?: InputMaybe<Checkout_Type>
  price_id?: InputMaybe<Scalars['String']['input']>
  product_id?: InputMaybe<Scalars['String']['input']>
  subscription_id?: InputMaybe<Scalars['String']['input']>
}

export type CheckoutsInsertResponse = {
  __typename?: 'checkoutsInsertResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Checkouts>
}

export type CheckoutsOrderBy = {
  created_at?: InputMaybe<OrderByDirection>
  customer_id?: InputMaybe<OrderByDirection>
  id?: InputMaybe<OrderByDirection>
  mode?: InputMaybe<OrderByDirection>
  price_id?: InputMaybe<OrderByDirection>
  product_id?: InputMaybe<OrderByDirection>
  subscription_id?: InputMaybe<OrderByDirection>
}

export type CheckoutsUpdateInput = {
  created_at?: InputMaybe<Scalars['Datetime']['input']>
  customer_id?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['String']['input']>
  mode?: InputMaybe<Checkout_Type>
  price_id?: InputMaybe<Scalars['String']['input']>
  product_id?: InputMaybe<Scalars['String']['input']>
  subscription_id?: InputMaybe<Scalars['String']['input']>
}

export type CheckoutsUpdateResponse = {
  __typename?: 'checkoutsUpdateResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Checkouts>
}

export type Comment_Likes = Node & {
  __typename?: 'comment_likes'
  comment: Comments
  comment_id: Scalars['UUID']['output']
  created_at?: Maybe<Scalars['Datetime']['output']>
  id: Scalars['BigInt']['output']
  /** Globally Unique Record Identifier */
  nodeId: Scalars['ID']['output']
  user_id: Scalars['UUID']['output']
}

export type Comment_LikesConnection = {
  __typename?: 'comment_likesConnection'
  edges: Array<Comment_LikesEdge>
  pageInfo: PageInfo
}

export type Comment_LikesDeleteResponse = {
  __typename?: 'comment_likesDeleteResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Comment_Likes>
}

export type Comment_LikesEdge = {
  __typename?: 'comment_likesEdge'
  cursor: Scalars['String']['output']
  node: Comment_Likes
}

export type Comment_LikesFilter = {
  /** Returns true only if all its inner filters are true, otherwise returns false */
  and?: InputMaybe<Array<Comment_LikesFilter>>
  comment_id?: InputMaybe<UuidFilter>
  created_at?: InputMaybe<DatetimeFilter>
  id?: InputMaybe<BigIntFilter>
  nodeId?: InputMaybe<IdFilter>
  /** Negates a filter */
  not?: InputMaybe<Comment_LikesFilter>
  /** Returns true if at least one of its inner filters is true, otherwise returns false */
  or?: InputMaybe<Array<Comment_LikesFilter>>
  user_id?: InputMaybe<UuidFilter>
}

export type Comment_LikesInsertInput = {
  comment_id?: InputMaybe<Scalars['UUID']['input']>
  created_at?: InputMaybe<Scalars['Datetime']['input']>
  user_id?: InputMaybe<Scalars['UUID']['input']>
}

export type Comment_LikesInsertResponse = {
  __typename?: 'comment_likesInsertResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Comment_Likes>
}

export type Comment_LikesOrderBy = {
  comment_id?: InputMaybe<OrderByDirection>
  created_at?: InputMaybe<OrderByDirection>
  id?: InputMaybe<OrderByDirection>
  user_id?: InputMaybe<OrderByDirection>
}

export type Comment_LikesUpdateInput = {
  comment_id?: InputMaybe<Scalars['UUID']['input']>
  created_at?: InputMaybe<Scalars['Datetime']['input']>
  user_id?: InputMaybe<Scalars['UUID']['input']>
}

export type Comment_LikesUpdateResponse = {
  __typename?: 'comment_likesUpdateResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Comment_Likes>
}

export type Comments = Node & {
  __typename?: 'comments'
  comment?: Maybe<Scalars['String']['output']>
  comment_likesCollection?: Maybe<Comment_LikesConnection>
  created_at?: Maybe<Scalars['Datetime']['output']>
  id: Scalars['UUID']['output']
  likes?: Maybe<Scalars['Int']['output']>
  /** Globally Unique Record Identifier */
  nodeId: Scalars['ID']['output']
  parent_id?: Maybe<Scalars['UUID']['output']>
  replies?: Maybe<Scalars['Int']['output']>
  topic: Scalars['String']['output']
  user: Profiles
  user_id: Scalars['UUID']['output']
}

export type CommentsComment_LikesCollectionArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  before?: InputMaybe<Scalars['Cursor']['input']>
  filter?: InputMaybe<Comment_LikesFilter>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Array<Comment_LikesOrderBy>>
}

export type CommentsConnection = {
  __typename?: 'commentsConnection'
  edges: Array<CommentsEdge>
  pageInfo: PageInfo
}

export type CommentsDeleteResponse = {
  __typename?: 'commentsDeleteResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Comments>
}

export type CommentsEdge = {
  __typename?: 'commentsEdge'
  cursor: Scalars['String']['output']
  node: Comments
}

export type CommentsFilter = {
  /** Returns true only if all its inner filters are true, otherwise returns false */
  and?: InputMaybe<Array<CommentsFilter>>
  comment?: InputMaybe<StringFilter>
  created_at?: InputMaybe<DatetimeFilter>
  id?: InputMaybe<UuidFilter>
  likes?: InputMaybe<IntFilter>
  nodeId?: InputMaybe<IdFilter>
  /** Negates a filter */
  not?: InputMaybe<CommentsFilter>
  /** Returns true if at least one of its inner filters is true, otherwise returns false */
  or?: InputMaybe<Array<CommentsFilter>>
  parent_id?: InputMaybe<UuidFilter>
  replies?: InputMaybe<IntFilter>
  topic?: InputMaybe<StringFilter>
  user_id?: InputMaybe<UuidFilter>
}

export type CommentsInsertInput = {
  comment?: InputMaybe<Scalars['String']['input']>
  created_at?: InputMaybe<Scalars['Datetime']['input']>
  id?: InputMaybe<Scalars['UUID']['input']>
  likes?: InputMaybe<Scalars['Int']['input']>
  parent_id?: InputMaybe<Scalars['UUID']['input']>
  replies?: InputMaybe<Scalars['Int']['input']>
  topic?: InputMaybe<Scalars['String']['input']>
  user_id?: InputMaybe<Scalars['UUID']['input']>
}

export type CommentsInsertResponse = {
  __typename?: 'commentsInsertResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Comments>
}

export type CommentsOrderBy = {
  comment?: InputMaybe<OrderByDirection>
  created_at?: InputMaybe<OrderByDirection>
  id?: InputMaybe<OrderByDirection>
  likes?: InputMaybe<OrderByDirection>
  parent_id?: InputMaybe<OrderByDirection>
  replies?: InputMaybe<OrderByDirection>
  topic?: InputMaybe<OrderByDirection>
  user_id?: InputMaybe<OrderByDirection>
}

export type CommentsUpdateInput = {
  comment?: InputMaybe<Scalars['String']['input']>
  created_at?: InputMaybe<Scalars['Datetime']['input']>
  id?: InputMaybe<Scalars['UUID']['input']>
  likes?: InputMaybe<Scalars['Int']['input']>
  parent_id?: InputMaybe<Scalars['UUID']['input']>
  replies?: InputMaybe<Scalars['Int']['input']>
  topic?: InputMaybe<Scalars['String']['input']>
  user_id?: InputMaybe<Scalars['UUID']['input']>
}

export type CommentsUpdateResponse = {
  __typename?: 'commentsUpdateResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Comments>
}

export enum Credit_History_Source {
  Addon = 'addon',
  ChatImage = 'chat_image',
  ChatImageFailed = 'chat_image_failed',
  ChatText = 'chat_text',
  ChatTextFailed = 'chat_text_failed',
  ExtractTag = 'extract_tag',
  ExtractTagFailed = 'extract_tag_failed',
  MakeModel = 'make_model',
  MakeModelFailed = 'make_model_failed',
  NewModel = 'new_model',
  NewModelFailed = 'new_model_failed',
  PostShare = 'post_share',
  PrivateModel = 'private_model',
  Refer = 'refer',
  Subscription = 'subscription',
  SubscriptionPeriodRetract = 'subscription_period_retract',
  System = 'system',
  Tag2Img = 'tag2Img',
  Tag2ImgFailed = 'tag2Img_failed',
}

/** Boolean expression comparing fields on type "credit_history_source" */
export type Credit_History_SourceFilter = {
  eq?: InputMaybe<Credit_History_Source>
  in?: InputMaybe<Array<Credit_History_Source>>
  is?: InputMaybe<FilterIs>
  neq?: InputMaybe<Credit_History_Source>
}

export type Credits = Node & {
  __typename?: 'credits'
  addon_credits: Scalars['BigFloat']['output']
  addon_credits_used: Scalars['BigFloat']['output']
  id: Scalars['BigInt']['output']
  last_updated: Scalars['Datetime']['output']
  /** Globally Unique Record Identifier */
  nodeId: Scalars['ID']['output']
  subscription_credits: Scalars['BigFloat']['output']
  subscription_credits_used: Scalars['BigFloat']['output']
  user: Users
  user_id: Scalars['UUID']['output']
}

export type CreditsConnection = {
  __typename?: 'creditsConnection'
  edges: Array<CreditsEdge>
  pageInfo: PageInfo
}

export type CreditsDeleteResponse = {
  __typename?: 'creditsDeleteResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Credits>
}

export type CreditsEdge = {
  __typename?: 'creditsEdge'
  cursor: Scalars['String']['output']
  node: Credits
}

export type CreditsFilter = {
  addon_credits?: InputMaybe<BigFloatFilter>
  addon_credits_used?: InputMaybe<BigFloatFilter>
  /** Returns true only if all its inner filters are true, otherwise returns false */
  and?: InputMaybe<Array<CreditsFilter>>
  id?: InputMaybe<BigIntFilter>
  last_updated?: InputMaybe<DatetimeFilter>
  nodeId?: InputMaybe<IdFilter>
  /** Negates a filter */
  not?: InputMaybe<CreditsFilter>
  /** Returns true if at least one of its inner filters is true, otherwise returns false */
  or?: InputMaybe<Array<CreditsFilter>>
  subscription_credits?: InputMaybe<BigFloatFilter>
  subscription_credits_used?: InputMaybe<BigFloatFilter>
  user_id?: InputMaybe<UuidFilter>
}

export type CreditsInsertInput = {
  addon_credits?: InputMaybe<Scalars['BigFloat']['input']>
  addon_credits_used?: InputMaybe<Scalars['BigFloat']['input']>
  last_updated?: InputMaybe<Scalars['Datetime']['input']>
  subscription_credits?: InputMaybe<Scalars['BigFloat']['input']>
  subscription_credits_used?: InputMaybe<Scalars['BigFloat']['input']>
  user_id?: InputMaybe<Scalars['UUID']['input']>
}

export type CreditsInsertResponse = {
  __typename?: 'creditsInsertResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Credits>
}

export type CreditsOrderBy = {
  addon_credits?: InputMaybe<OrderByDirection>
  addon_credits_used?: InputMaybe<OrderByDirection>
  id?: InputMaybe<OrderByDirection>
  last_updated?: InputMaybe<OrderByDirection>
  subscription_credits?: InputMaybe<OrderByDirection>
  subscription_credits_used?: InputMaybe<OrderByDirection>
  user_id?: InputMaybe<OrderByDirection>
}

export type CreditsUpdateInput = {
  addon_credits?: InputMaybe<Scalars['BigFloat']['input']>
  addon_credits_used?: InputMaybe<Scalars['BigFloat']['input']>
  last_updated?: InputMaybe<Scalars['Datetime']['input']>
  subscription_credits?: InputMaybe<Scalars['BigFloat']['input']>
  subscription_credits_used?: InputMaybe<Scalars['BigFloat']['input']>
  user_id?: InputMaybe<Scalars['UUID']['input']>
}

export type CreditsUpdateResponse = {
  __typename?: 'creditsUpdateResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Credits>
}

export type Credits_History = Node & {
  __typename?: 'credits_history'
  addon_credits_added?: Maybe<Scalars['BigFloat']['output']>
  addon_credits_spent?: Maybe<Scalars['BigFloat']['output']>
  created_at?: Maybe<Scalars['Datetime']['output']>
  id: Scalars['BigInt']['output']
  /** Globally Unique Record Identifier */
  nodeId: Scalars['ID']['output']
  source: Credit_History_Source
  subscription_credits_added?: Maybe<Scalars['BigFloat']['output']>
  subscription_credits_spent?: Maybe<Scalars['BigFloat']['output']>
  user: Users
  user_id: Scalars['UUID']['output']
}

export type Credits_HistoryConnection = {
  __typename?: 'credits_historyConnection'
  edges: Array<Credits_HistoryEdge>
  pageInfo: PageInfo
  /** The total number of records matching the `filter` criteria */
  totalCount: Scalars['Int']['output']
}

export type Credits_HistoryDeleteResponse = {
  __typename?: 'credits_historyDeleteResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Credits_History>
}

export type Credits_HistoryEdge = {
  __typename?: 'credits_historyEdge'
  cursor: Scalars['String']['output']
  node: Credits_History
}

export type Credits_HistoryFilter = {
  addon_credits_added?: InputMaybe<BigFloatFilter>
  addon_credits_spent?: InputMaybe<BigFloatFilter>
  /** Returns true only if all its inner filters are true, otherwise returns false */
  and?: InputMaybe<Array<Credits_HistoryFilter>>
  created_at?: InputMaybe<DatetimeFilter>
  id?: InputMaybe<BigIntFilter>
  nodeId?: InputMaybe<IdFilter>
  /** Negates a filter */
  not?: InputMaybe<Credits_HistoryFilter>
  /** Returns true if at least one of its inner filters is true, otherwise returns false */
  or?: InputMaybe<Array<Credits_HistoryFilter>>
  source?: InputMaybe<Credit_History_SourceFilter>
  subscription_credits_added?: InputMaybe<BigFloatFilter>
  subscription_credits_spent?: InputMaybe<BigFloatFilter>
  user_id?: InputMaybe<UuidFilter>
}

export type Credits_HistoryInsertInput = {
  addon_credits_added?: InputMaybe<Scalars['BigFloat']['input']>
  addon_credits_spent?: InputMaybe<Scalars['BigFloat']['input']>
  created_at?: InputMaybe<Scalars['Datetime']['input']>
  source?: InputMaybe<Credit_History_Source>
  subscription_credits_added?: InputMaybe<Scalars['BigFloat']['input']>
  subscription_credits_spent?: InputMaybe<Scalars['BigFloat']['input']>
  user_id?: InputMaybe<Scalars['UUID']['input']>
}

export type Credits_HistoryInsertResponse = {
  __typename?: 'credits_historyInsertResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Credits_History>
}

export type Credits_HistoryOrderBy = {
  addon_credits_added?: InputMaybe<OrderByDirection>
  addon_credits_spent?: InputMaybe<OrderByDirection>
  created_at?: InputMaybe<OrderByDirection>
  id?: InputMaybe<OrderByDirection>
  source?: InputMaybe<OrderByDirection>
  subscription_credits_added?: InputMaybe<OrderByDirection>
  subscription_credits_spent?: InputMaybe<OrderByDirection>
  user_id?: InputMaybe<OrderByDirection>
}

export type Credits_HistoryUpdateInput = {
  addon_credits_added?: InputMaybe<Scalars['BigFloat']['input']>
  addon_credits_spent?: InputMaybe<Scalars['BigFloat']['input']>
  created_at?: InputMaybe<Scalars['Datetime']['input']>
  source?: InputMaybe<Credit_History_Source>
  subscription_credits_added?: InputMaybe<Scalars['BigFloat']['input']>
  subscription_credits_spent?: InputMaybe<Scalars['BigFloat']['input']>
  user_id?: InputMaybe<Scalars['UUID']['input']>
}

export type Credits_HistoryUpdateResponse = {
  __typename?: 'credits_historyUpdateResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Credits_History>
}

export type Extract_Tag_Detail = Node & {
  __typename?: 'extract_tag_detail'
  alg_payload?: Maybe<Scalars['JSON']['output']>
  cover_img?: Maybe<Scalars['String']['output']>
  created_at?: Maybe<Scalars['Datetime']['output']>
  id: Scalars['BigInt']['output']
  label?: Maybe<Scalars['String']['output']>
  /** Globally Unique Record Identifier */
  nodeId: Scalars['ID']['output']
  updated_at?: Maybe<Scalars['Datetime']['output']>
}

export type Extract_Tag_DetailConnection = {
  __typename?: 'extract_tag_detailConnection'
  edges: Array<Extract_Tag_DetailEdge>
  pageInfo: PageInfo
}

export type Extract_Tag_DetailDeleteResponse = {
  __typename?: 'extract_tag_detailDeleteResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Extract_Tag_Detail>
}

export type Extract_Tag_DetailEdge = {
  __typename?: 'extract_tag_detailEdge'
  cursor: Scalars['String']['output']
  node: Extract_Tag_Detail
}

export type Extract_Tag_DetailFilter = {
  /** Returns true only if all its inner filters are true, otherwise returns false */
  and?: InputMaybe<Array<Extract_Tag_DetailFilter>>
  cover_img?: InputMaybe<StringFilter>
  created_at?: InputMaybe<DatetimeFilter>
  id?: InputMaybe<BigIntFilter>
  label?: InputMaybe<StringFilter>
  nodeId?: InputMaybe<IdFilter>
  /** Negates a filter */
  not?: InputMaybe<Extract_Tag_DetailFilter>
  /** Returns true if at least one of its inner filters is true, otherwise returns false */
  or?: InputMaybe<Array<Extract_Tag_DetailFilter>>
  updated_at?: InputMaybe<DatetimeFilter>
}

export type Extract_Tag_DetailInsertInput = {
  alg_payload?: InputMaybe<Scalars['JSON']['input']>
  cover_img?: InputMaybe<Scalars['String']['input']>
  created_at?: InputMaybe<Scalars['Datetime']['input']>
  label?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['Datetime']['input']>
}

export type Extract_Tag_DetailInsertResponse = {
  __typename?: 'extract_tag_detailInsertResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Extract_Tag_Detail>
}

export type Extract_Tag_DetailOrderBy = {
  cover_img?: InputMaybe<OrderByDirection>
  created_at?: InputMaybe<OrderByDirection>
  id?: InputMaybe<OrderByDirection>
  label?: InputMaybe<OrderByDirection>
  updated_at?: InputMaybe<OrderByDirection>
}

export type Extract_Tag_DetailUpdateInput = {
  alg_payload?: InputMaybe<Scalars['JSON']['input']>
  cover_img?: InputMaybe<Scalars['String']['input']>
  created_at?: InputMaybe<Scalars['Datetime']['input']>
  label?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['Datetime']['input']>
}

export type Extract_Tag_DetailUpdateResponse = {
  __typename?: 'extract_tag_detailUpdateResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Extract_Tag_Detail>
}

export type Generate_Tasks = Node & {
  __typename?: 'generate_tasks'
  created_at: Scalars['Datetime']['output']
  credits: Scalars['BigFloat']['output']
  id: Scalars['BigInt']['output']
  /** Globally Unique Record Identifier */
  nodeId: Scalars['ID']['output']
  status: Task_Status
  tid: Scalars['String']['output']
  type: Task_Type
  updated_at: Scalars['Datetime']['output']
  user_id: Scalars['UUID']['output']
}

export type Generate_TasksConnection = {
  __typename?: 'generate_tasksConnection'
  edges: Array<Generate_TasksEdge>
  pageInfo: PageInfo
}

export type Generate_TasksDeleteResponse = {
  __typename?: 'generate_tasksDeleteResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Generate_Tasks>
}

export type Generate_TasksEdge = {
  __typename?: 'generate_tasksEdge'
  cursor: Scalars['String']['output']
  node: Generate_Tasks
}

export type Generate_TasksFilter = {
  /** Returns true only if all its inner filters are true, otherwise returns false */
  and?: InputMaybe<Array<Generate_TasksFilter>>
  created_at?: InputMaybe<DatetimeFilter>
  credits?: InputMaybe<BigFloatFilter>
  id?: InputMaybe<BigIntFilter>
  nodeId?: InputMaybe<IdFilter>
  /** Negates a filter */
  not?: InputMaybe<Generate_TasksFilter>
  /** Returns true if at least one of its inner filters is true, otherwise returns false */
  or?: InputMaybe<Array<Generate_TasksFilter>>
  status?: InputMaybe<Task_StatusFilter>
  tid?: InputMaybe<StringFilter>
  type?: InputMaybe<Task_TypeFilter>
  updated_at?: InputMaybe<DatetimeFilter>
  user_id?: InputMaybe<UuidFilter>
}

export type Generate_TasksInsertInput = {
  created_at?: InputMaybe<Scalars['Datetime']['input']>
  credits?: InputMaybe<Scalars['BigFloat']['input']>
  status?: InputMaybe<Task_Status>
  tid?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<Task_Type>
  updated_at?: InputMaybe<Scalars['Datetime']['input']>
  user_id?: InputMaybe<Scalars['UUID']['input']>
}

export type Generate_TasksInsertResponse = {
  __typename?: 'generate_tasksInsertResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Generate_Tasks>
}

export type Generate_TasksOrderBy = {
  created_at?: InputMaybe<OrderByDirection>
  credits?: InputMaybe<OrderByDirection>
  id?: InputMaybe<OrderByDirection>
  status?: InputMaybe<OrderByDirection>
  tid?: InputMaybe<OrderByDirection>
  type?: InputMaybe<OrderByDirection>
  updated_at?: InputMaybe<OrderByDirection>
  user_id?: InputMaybe<OrderByDirection>
}

export type Generate_TasksUpdateInput = {
  created_at?: InputMaybe<Scalars['Datetime']['input']>
  credits?: InputMaybe<Scalars['BigFloat']['input']>
  status?: InputMaybe<Task_Status>
  tid?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<Task_Type>
  updated_at?: InputMaybe<Scalars['Datetime']['input']>
  user_id?: InputMaybe<Scalars['UUID']['input']>
}

export type Generate_TasksUpdateResponse = {
  __typename?: 'generate_tasksUpdateResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Generate_Tasks>
}

export type Invoices = Node & {
  __typename?: 'invoices'
  created_at: Scalars['Datetime']['output']
  customer_id: Scalars['String']['output']
  id: Scalars['String']['output']
  /** Globally Unique Record Identifier */
  nodeId: Scalars['ID']['output']
  price: Prices
  price_id: Scalars['String']['output']
  product: Products
  product_id: Scalars['String']['output']
  release_count?: Maybe<Scalars['Int']['output']>
  retract_count?: Maybe<Scalars['Int']['output']>
  subscription_id?: Maybe<Scalars['String']['output']>
  type: Pricing_Type
  user: Users
}

export type InvoicesConnection = {
  __typename?: 'invoicesConnection'
  edges: Array<InvoicesEdge>
  pageInfo: PageInfo
}

export type InvoicesDeleteResponse = {
  __typename?: 'invoicesDeleteResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Invoices>
}

export type InvoicesEdge = {
  __typename?: 'invoicesEdge'
  cursor: Scalars['String']['output']
  node: Invoices
}

export type InvoicesFilter = {
  /** Returns true only if all its inner filters are true, otherwise returns false */
  and?: InputMaybe<Array<InvoicesFilter>>
  created_at?: InputMaybe<DatetimeFilter>
  customer_id?: InputMaybe<StringFilter>
  id?: InputMaybe<StringFilter>
  nodeId?: InputMaybe<IdFilter>
  /** Negates a filter */
  not?: InputMaybe<InvoicesFilter>
  /** Returns true if at least one of its inner filters is true, otherwise returns false */
  or?: InputMaybe<Array<InvoicesFilter>>
  price_id?: InputMaybe<StringFilter>
  product_id?: InputMaybe<StringFilter>
  release_count?: InputMaybe<IntFilter>
  retract_count?: InputMaybe<IntFilter>
  subscription_id?: InputMaybe<StringFilter>
  type?: InputMaybe<Pricing_TypeFilter>
}

export type InvoicesInsertInput = {
  created_at?: InputMaybe<Scalars['Datetime']['input']>
  customer_id?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['String']['input']>
  price_id?: InputMaybe<Scalars['String']['input']>
  product_id?: InputMaybe<Scalars['String']['input']>
  release_count?: InputMaybe<Scalars['Int']['input']>
  retract_count?: InputMaybe<Scalars['Int']['input']>
  subscription_id?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<Pricing_Type>
}

export type InvoicesInsertResponse = {
  __typename?: 'invoicesInsertResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Invoices>
}

export type InvoicesOrderBy = {
  created_at?: InputMaybe<OrderByDirection>
  customer_id?: InputMaybe<OrderByDirection>
  id?: InputMaybe<OrderByDirection>
  price_id?: InputMaybe<OrderByDirection>
  product_id?: InputMaybe<OrderByDirection>
  release_count?: InputMaybe<OrderByDirection>
  retract_count?: InputMaybe<OrderByDirection>
  subscription_id?: InputMaybe<OrderByDirection>
  type?: InputMaybe<OrderByDirection>
}

export type InvoicesUpdateInput = {
  created_at?: InputMaybe<Scalars['Datetime']['input']>
  customer_id?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['String']['input']>
  price_id?: InputMaybe<Scalars['String']['input']>
  product_id?: InputMaybe<Scalars['String']['input']>
  release_count?: InputMaybe<Scalars['Int']['input']>
  retract_count?: InputMaybe<Scalars['Int']['input']>
  subscription_id?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<Pricing_Type>
}

export type InvoicesUpdateResponse = {
  __typename?: 'invoicesUpdateResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Invoices>
}

export type Make_Models_History = Node & {
  __typename?: 'make_models_history'
  created_at: Scalars['Datetime']['output']
  creator: Scalars['UUID']['output']
  error_code?: Maybe<Scalars['Int']['output']>
  gid?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  img_paths: Array<Maybe<Scalars['String']['output']>>
  imgs_info?: Maybe<Scalars['JSON']['output']>
  model_id?: Maybe<Scalars['UUID']['output']>
  /** Globally Unique Record Identifier */
  nodeId: Scalars['ID']['output']
  tid: Scalars['String']['output']
  updated_at: Scalars['Datetime']['output']
}

export type Make_Models_HistoryConnection = {
  __typename?: 'make_models_historyConnection'
  edges: Array<Make_Models_HistoryEdge>
  pageInfo: PageInfo
}

export type Make_Models_HistoryDeleteResponse = {
  __typename?: 'make_models_historyDeleteResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Make_Models_History>
}

export type Make_Models_HistoryEdge = {
  __typename?: 'make_models_historyEdge'
  cursor: Scalars['String']['output']
  node: Make_Models_History
}

export type Make_Models_HistoryFilter = {
  /** Returns true only if all its inner filters are true, otherwise returns false */
  and?: InputMaybe<Array<Make_Models_HistoryFilter>>
  created_at?: InputMaybe<DatetimeFilter>
  creator?: InputMaybe<UuidFilter>
  error_code?: InputMaybe<IntFilter>
  gid?: InputMaybe<StringFilter>
  id?: InputMaybe<UuidFilter>
  model_id?: InputMaybe<UuidFilter>
  nodeId?: InputMaybe<IdFilter>
  /** Negates a filter */
  not?: InputMaybe<Make_Models_HistoryFilter>
  /** Returns true if at least one of its inner filters is true, otherwise returns false */
  or?: InputMaybe<Array<Make_Models_HistoryFilter>>
  tid?: InputMaybe<StringFilter>
  updated_at?: InputMaybe<DatetimeFilter>
}

export type Make_Models_HistoryInsertInput = {
  created_at?: InputMaybe<Scalars['Datetime']['input']>
  creator?: InputMaybe<Scalars['UUID']['input']>
  error_code?: InputMaybe<Scalars['Int']['input']>
  gid?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['UUID']['input']>
  img_paths?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  imgs_info?: InputMaybe<Scalars['JSON']['input']>
  model_id?: InputMaybe<Scalars['UUID']['input']>
  tid?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['Datetime']['input']>
}

export type Make_Models_HistoryInsertResponse = {
  __typename?: 'make_models_historyInsertResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Make_Models_History>
}

export type Make_Models_HistoryOrderBy = {
  created_at?: InputMaybe<OrderByDirection>
  creator?: InputMaybe<OrderByDirection>
  error_code?: InputMaybe<OrderByDirection>
  gid?: InputMaybe<OrderByDirection>
  id?: InputMaybe<OrderByDirection>
  model_id?: InputMaybe<OrderByDirection>
  tid?: InputMaybe<OrderByDirection>
  updated_at?: InputMaybe<OrderByDirection>
}

export type Make_Models_HistoryUpdateInput = {
  created_at?: InputMaybe<Scalars['Datetime']['input']>
  creator?: InputMaybe<Scalars['UUID']['input']>
  error_code?: InputMaybe<Scalars['Int']['input']>
  gid?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['UUID']['input']>
  img_paths?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  imgs_info?: InputMaybe<Scalars['JSON']['input']>
  model_id?: InputMaybe<Scalars['UUID']['input']>
  tid?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['Datetime']['input']>
}

export type Make_Models_HistoryUpdateResponse = {
  __typename?: 'make_models_historyUpdateResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Make_Models_History>
}

export type Membership = Node & {
  __typename?: 'membership'
  created_at: Scalars['Datetime']['output']
  current_period_end?: Maybe<Scalars['Datetime']['output']>
  current_period_start?: Maybe<Scalars['Datetime']['output']>
  end_at?: Maybe<Scalars['Datetime']['output']>
  id: Scalars['BigInt']['output']
  /** Globally Unique Record Identifier */
  nodeId: Scalars['ID']['output']
  source: Payment_Source
  tier: Subscription_Tier
  updated_at: Scalars['Datetime']['output']
  user_id: Scalars['UUID']['output']
}

export type MembershipConnection = {
  __typename?: 'membershipConnection'
  edges: Array<MembershipEdge>
  pageInfo: PageInfo
}

export type MembershipDeleteResponse = {
  __typename?: 'membershipDeleteResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Membership>
}

export type MembershipEdge = {
  __typename?: 'membershipEdge'
  cursor: Scalars['String']['output']
  node: Membership
}

export type MembershipFilter = {
  /** Returns true only if all its inner filters are true, otherwise returns false */
  and?: InputMaybe<Array<MembershipFilter>>
  created_at?: InputMaybe<DatetimeFilter>
  current_period_end?: InputMaybe<DatetimeFilter>
  current_period_start?: InputMaybe<DatetimeFilter>
  end_at?: InputMaybe<DatetimeFilter>
  id?: InputMaybe<BigIntFilter>
  nodeId?: InputMaybe<IdFilter>
  /** Negates a filter */
  not?: InputMaybe<MembershipFilter>
  /** Returns true if at least one of its inner filters is true, otherwise returns false */
  or?: InputMaybe<Array<MembershipFilter>>
  source?: InputMaybe<Payment_SourceFilter>
  tier?: InputMaybe<Subscription_TierFilter>
  updated_at?: InputMaybe<DatetimeFilter>
  user_id?: InputMaybe<UuidFilter>
}

export type MembershipInsertInput = {
  created_at?: InputMaybe<Scalars['Datetime']['input']>
  current_period_end?: InputMaybe<Scalars['Datetime']['input']>
  current_period_start?: InputMaybe<Scalars['Datetime']['input']>
  end_at?: InputMaybe<Scalars['Datetime']['input']>
  source?: InputMaybe<Payment_Source>
  tier?: InputMaybe<Subscription_Tier>
  updated_at?: InputMaybe<Scalars['Datetime']['input']>
  user_id?: InputMaybe<Scalars['UUID']['input']>
}

export type MembershipInsertResponse = {
  __typename?: 'membershipInsertResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Membership>
}

export type MembershipOrderBy = {
  created_at?: InputMaybe<OrderByDirection>
  current_period_end?: InputMaybe<OrderByDirection>
  current_period_start?: InputMaybe<OrderByDirection>
  end_at?: InputMaybe<OrderByDirection>
  id?: InputMaybe<OrderByDirection>
  source?: InputMaybe<OrderByDirection>
  tier?: InputMaybe<OrderByDirection>
  updated_at?: InputMaybe<OrderByDirection>
  user_id?: InputMaybe<OrderByDirection>
}

export type MembershipUpdateInput = {
  created_at?: InputMaybe<Scalars['Datetime']['input']>
  current_period_end?: InputMaybe<Scalars['Datetime']['input']>
  current_period_start?: InputMaybe<Scalars['Datetime']['input']>
  end_at?: InputMaybe<Scalars['Datetime']['input']>
  source?: InputMaybe<Payment_Source>
  tier?: InputMaybe<Subscription_Tier>
  updated_at?: InputMaybe<Scalars['Datetime']['input']>
  user_id?: InputMaybe<Scalars['UUID']['input']>
}

export type MembershipUpdateResponse = {
  __typename?: 'membershipUpdateResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Membership>
}

export type Membership_History = Node & {
  __typename?: 'membership_history'
  created_at: Scalars['Datetime']['output']
  current_period_end?: Maybe<Scalars['Datetime']['output']>
  current_period_start?: Maybe<Scalars['Datetime']['output']>
  end_at?: Maybe<Scalars['Datetime']['output']>
  history_id: Scalars['BigInt']['output']
  id: Scalars['BigInt']['output']
  /** Globally Unique Record Identifier */
  nodeId: Scalars['ID']['output']
  source: Payment_Source
  tier: Subscription_Tier
  updated_at: Scalars['Datetime']['output']
  user_id: Scalars['UUID']['output']
}

export type Membership_HistoryConnection = {
  __typename?: 'membership_historyConnection'
  edges: Array<Membership_HistoryEdge>
  pageInfo: PageInfo
}

export type Membership_HistoryDeleteResponse = {
  __typename?: 'membership_historyDeleteResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Membership_History>
}

export type Membership_HistoryEdge = {
  __typename?: 'membership_historyEdge'
  cursor: Scalars['String']['output']
  node: Membership_History
}

export type Membership_HistoryFilter = {
  /** Returns true only if all its inner filters are true, otherwise returns false */
  and?: InputMaybe<Array<Membership_HistoryFilter>>
  created_at?: InputMaybe<DatetimeFilter>
  current_period_end?: InputMaybe<DatetimeFilter>
  current_period_start?: InputMaybe<DatetimeFilter>
  end_at?: InputMaybe<DatetimeFilter>
  history_id?: InputMaybe<BigIntFilter>
  id?: InputMaybe<BigIntFilter>
  nodeId?: InputMaybe<IdFilter>
  /** Negates a filter */
  not?: InputMaybe<Membership_HistoryFilter>
  /** Returns true if at least one of its inner filters is true, otherwise returns false */
  or?: InputMaybe<Array<Membership_HistoryFilter>>
  source?: InputMaybe<Payment_SourceFilter>
  tier?: InputMaybe<Subscription_TierFilter>
  updated_at?: InputMaybe<DatetimeFilter>
  user_id?: InputMaybe<UuidFilter>
}

export type Membership_HistoryInsertInput = {
  created_at?: InputMaybe<Scalars['Datetime']['input']>
  current_period_end?: InputMaybe<Scalars['Datetime']['input']>
  current_period_start?: InputMaybe<Scalars['Datetime']['input']>
  end_at?: InputMaybe<Scalars['Datetime']['input']>
  id?: InputMaybe<Scalars['BigInt']['input']>
  source?: InputMaybe<Payment_Source>
  tier?: InputMaybe<Subscription_Tier>
  updated_at?: InputMaybe<Scalars['Datetime']['input']>
  user_id?: InputMaybe<Scalars['UUID']['input']>
}

export type Membership_HistoryInsertResponse = {
  __typename?: 'membership_historyInsertResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Membership_History>
}

export type Membership_HistoryOrderBy = {
  created_at?: InputMaybe<OrderByDirection>
  current_period_end?: InputMaybe<OrderByDirection>
  current_period_start?: InputMaybe<OrderByDirection>
  end_at?: InputMaybe<OrderByDirection>
  history_id?: InputMaybe<OrderByDirection>
  id?: InputMaybe<OrderByDirection>
  source?: InputMaybe<OrderByDirection>
  tier?: InputMaybe<OrderByDirection>
  updated_at?: InputMaybe<OrderByDirection>
  user_id?: InputMaybe<OrderByDirection>
}

export type Membership_HistoryUpdateInput = {
  created_at?: InputMaybe<Scalars['Datetime']['input']>
  current_period_end?: InputMaybe<Scalars['Datetime']['input']>
  current_period_start?: InputMaybe<Scalars['Datetime']['input']>
  end_at?: InputMaybe<Scalars['Datetime']['input']>
  id?: InputMaybe<Scalars['BigInt']['input']>
  source?: InputMaybe<Payment_Source>
  tier?: InputMaybe<Subscription_Tier>
  updated_at?: InputMaybe<Scalars['Datetime']['input']>
  user_id?: InputMaybe<Scalars['UUID']['input']>
}

export type Membership_HistoryUpdateResponse = {
  __typename?: 'membership_historyUpdateResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Membership_History>
}

export enum Message_Error {
  Failed = 'failed',
  Timeout = 'timeout',
}

/** Boolean expression comparing fields on type "message_error" */
export type Message_ErrorFilter = {
  eq?: InputMaybe<Message_Error>
  in?: InputMaybe<Array<Message_Error>>
  is?: InputMaybe<FilterIs>
  neq?: InputMaybe<Message_Error>
}

export enum Message_Interaction_Type {
  Dislike = 'dislike',
  Like = 'like',
}

/** Boolean expression comparing fields on type "message_interaction_type" */
export type Message_Interaction_TypeFilter = {
  eq?: InputMaybe<Message_Interaction_Type>
  in?: InputMaybe<Array<Message_Interaction_Type>>
  is?: InputMaybe<FilterIs>
  neq?: InputMaybe<Message_Interaction_Type>
}

export enum Message_Role {
  Assistant = 'assistant',
  System = 'system',
  User = 'user',
}

/** Boolean expression comparing fields on type "message_role" */
export type Message_RoleFilter = {
  eq?: InputMaybe<Message_Role>
  in?: InputMaybe<Array<Message_Role>>
  is?: InputMaybe<FilterIs>
  neq?: InputMaybe<Message_Role>
}

export enum Message_Type {
  Image = 'image',
  Text = 'text',
}

/** Boolean expression comparing fields on type "message_type" */
export type Message_TypeFilter = {
  eq?: InputMaybe<Message_Type>
  in?: InputMaybe<Array<Message_Type>>
  is?: InputMaybe<FilterIs>
  neq?: InputMaybe<Message_Type>
}

export type Model_Subscribers = Node & {
  __typename?: 'model_subscribers'
  created_at?: Maybe<Scalars['Datetime']['output']>
  id: Scalars['BigInt']['output']
  model: Models
  model_id: Scalars['UUID']['output']
  /** Globally Unique Record Identifier */
  nodeId: Scalars['ID']['output']
  subscriber_id: Scalars['UUID']['output']
  user: Users
}

export type Model_SubscribersConnection = {
  __typename?: 'model_subscribersConnection'
  edges: Array<Model_SubscribersEdge>
  pageInfo: PageInfo
  /** The total number of records matching the `filter` criteria */
  totalCount: Scalars['Int']['output']
}

export type Model_SubscribersDeleteResponse = {
  __typename?: 'model_subscribersDeleteResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Model_Subscribers>
}

export type Model_SubscribersEdge = {
  __typename?: 'model_subscribersEdge'
  cursor: Scalars['String']['output']
  node: Model_Subscribers
}

export type Model_SubscribersFilter = {
  /** Returns true only if all its inner filters are true, otherwise returns false */
  and?: InputMaybe<Array<Model_SubscribersFilter>>
  created_at?: InputMaybe<DatetimeFilter>
  id?: InputMaybe<BigIntFilter>
  model_id?: InputMaybe<UuidFilter>
  nodeId?: InputMaybe<IdFilter>
  /** Negates a filter */
  not?: InputMaybe<Model_SubscribersFilter>
  /** Returns true if at least one of its inner filters is true, otherwise returns false */
  or?: InputMaybe<Array<Model_SubscribersFilter>>
  subscriber_id?: InputMaybe<UuidFilter>
}

export type Model_SubscribersInsertInput = {
  created_at?: InputMaybe<Scalars['Datetime']['input']>
  model_id?: InputMaybe<Scalars['UUID']['input']>
  subscriber_id?: InputMaybe<Scalars['UUID']['input']>
}

export type Model_SubscribersInsertResponse = {
  __typename?: 'model_subscribersInsertResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Model_Subscribers>
}

export type Model_SubscribersOrderBy = {
  created_at?: InputMaybe<OrderByDirection>
  id?: InputMaybe<OrderByDirection>
  model_id?: InputMaybe<OrderByDirection>
  subscriber_id?: InputMaybe<OrderByDirection>
}

export type Model_SubscribersUpdateInput = {
  created_at?: InputMaybe<Scalars['Datetime']['input']>
  model_id?: InputMaybe<Scalars['UUID']['input']>
  subscriber_id?: InputMaybe<Scalars['UUID']['input']>
}

export type Model_SubscribersUpdateResponse = {
  __typename?: 'model_subscribersUpdateResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Model_Subscribers>
}

export enum Model_Version {
  V0 = 'v0',
  V1 = 'v1',
  V2 = 'v2',
}

/** Boolean expression comparing fields on type "model_version" */
export type Model_VersionFilter = {
  eq?: InputMaybe<Model_Version>
  in?: InputMaybe<Array<Model_Version>>
  is?: InputMaybe<FilterIs>
  neq?: InputMaybe<Model_Version>
}

export type Models = Node & {
  __typename?: 'models'
  about?: Maybe<Scalars['String']['output']>
  age?: Maybe<Scalars['Int']['output']>
  ass?: Maybe<Scalars['String']['output']>
  avatar?: Maybe<Scalars['String']['output']>
  created_at: Scalars['Datetime']['output']
  creator?: Maybe<Scalars['UUID']['output']>
  fullbody?: Maybe<Scalars['String']['output']>
  has_penis_feature: Scalars['Boolean']['output']
  hobbies?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  id: Scalars['UUID']['output']
  labels?: Maybe<Array<Maybe<Scalars['Int']['output']>>>
  model_subscribersCollection?: Maybe<Model_SubscribersConnection>
  nickname?: Maybe<Scalars['String']['output']>
  /** Globally Unique Record Identifier */
  nodeId: Scalars['ID']['output']
  occupation?: Maybe<Scalars['String']['output']>
  penis?: Maybe<Scalars['String']['output']>
  personalities?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  postsCollection?: Maybe<PostsConnection>
  posts_pendingCollection?: Maybe<Posts_PendingConnection>
  private?: Maybe<Scalars['Boolean']['output']>
  sessionsCollection?: Maybe<SessionsConnection>
  subscribers?: Maybe<Scalars['BigInt']['output']>
  templated?: Maybe<Scalars['BigInt']['output']>
  updated_at: Scalars['Datetime']['output']
  user?: Maybe<Users>
  version: Model_Version
}

export type ModelsModel_SubscribersCollectionArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  before?: InputMaybe<Scalars['Cursor']['input']>
  filter?: InputMaybe<Model_SubscribersFilter>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Array<Model_SubscribersOrderBy>>
}

export type ModelsPostsCollectionArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  before?: InputMaybe<Scalars['Cursor']['input']>
  filter?: InputMaybe<PostsFilter>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Array<PostsOrderBy>>
}

export type ModelsPosts_PendingCollectionArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  before?: InputMaybe<Scalars['Cursor']['input']>
  filter?: InputMaybe<Posts_PendingFilter>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Array<Posts_PendingOrderBy>>
}

export type ModelsSessionsCollectionArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  before?: InputMaybe<Scalars['Cursor']['input']>
  filter?: InputMaybe<SessionsFilter>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Array<SessionsOrderBy>>
}

export type ModelsConnection = {
  __typename?: 'modelsConnection'
  edges: Array<ModelsEdge>
  pageInfo: PageInfo
  /** The total number of records matching the `filter` criteria */
  totalCount: Scalars['Int']['output']
}

export type ModelsDeleteResponse = {
  __typename?: 'modelsDeleteResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Models>
}

export type ModelsEdge = {
  __typename?: 'modelsEdge'
  cursor: Scalars['String']['output']
  node: Models
}

export type ModelsFilter = {
  about?: InputMaybe<StringFilter>
  age?: InputMaybe<IntFilter>
  /** Returns true only if all its inner filters are true, otherwise returns false */
  and?: InputMaybe<Array<ModelsFilter>>
  ass?: InputMaybe<StringFilter>
  avatar?: InputMaybe<StringFilter>
  created_at?: InputMaybe<DatetimeFilter>
  creator?: InputMaybe<UuidFilter>
  fullbody?: InputMaybe<StringFilter>
  has_penis_feature?: InputMaybe<BooleanFilter>
  id?: InputMaybe<UuidFilter>
  nickname?: InputMaybe<StringFilter>
  nodeId?: InputMaybe<IdFilter>
  /** Negates a filter */
  not?: InputMaybe<ModelsFilter>
  occupation?: InputMaybe<StringFilter>
  /** Returns true if at least one of its inner filters is true, otherwise returns false */
  or?: InputMaybe<Array<ModelsFilter>>
  penis?: InputMaybe<StringFilter>
  private?: InputMaybe<BooleanFilter>
  subscribers?: InputMaybe<BigIntFilter>
  templated?: InputMaybe<BigIntFilter>
  updated_at?: InputMaybe<DatetimeFilter>
  version?: InputMaybe<Model_VersionFilter>
}

export type ModelsInsertInput = {
  about?: InputMaybe<Scalars['String']['input']>
  age?: InputMaybe<Scalars['Int']['input']>
  ass?: InputMaybe<Scalars['String']['input']>
  avatar?: InputMaybe<Scalars['String']['input']>
  created_at?: InputMaybe<Scalars['Datetime']['input']>
  creator?: InputMaybe<Scalars['UUID']['input']>
  fullbody?: InputMaybe<Scalars['String']['input']>
  has_penis_feature?: InputMaybe<Scalars['Boolean']['input']>
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  id?: InputMaybe<Scalars['UUID']['input']>
  labels?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  nickname?: InputMaybe<Scalars['String']['input']>
  occupation?: InputMaybe<Scalars['String']['input']>
  penis?: InputMaybe<Scalars['String']['input']>
  personalities?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  private?: InputMaybe<Scalars['Boolean']['input']>
  subscribers?: InputMaybe<Scalars['BigInt']['input']>
  templated?: InputMaybe<Scalars['BigInt']['input']>
  updated_at?: InputMaybe<Scalars['Datetime']['input']>
  version?: InputMaybe<Model_Version>
}

export type ModelsInsertResponse = {
  __typename?: 'modelsInsertResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Models>
}

export type ModelsOrderBy = {
  about?: InputMaybe<OrderByDirection>
  age?: InputMaybe<OrderByDirection>
  ass?: InputMaybe<OrderByDirection>
  avatar?: InputMaybe<OrderByDirection>
  created_at?: InputMaybe<OrderByDirection>
  creator?: InputMaybe<OrderByDirection>
  fullbody?: InputMaybe<OrderByDirection>
  has_penis_feature?: InputMaybe<OrderByDirection>
  id?: InputMaybe<OrderByDirection>
  nickname?: InputMaybe<OrderByDirection>
  occupation?: InputMaybe<OrderByDirection>
  penis?: InputMaybe<OrderByDirection>
  private?: InputMaybe<OrderByDirection>
  subscribers?: InputMaybe<OrderByDirection>
  templated?: InputMaybe<OrderByDirection>
  updated_at?: InputMaybe<OrderByDirection>
  version?: InputMaybe<OrderByDirection>
}

export type ModelsUpdateInput = {
  about?: InputMaybe<Scalars['String']['input']>
  age?: InputMaybe<Scalars['Int']['input']>
  ass?: InputMaybe<Scalars['String']['input']>
  avatar?: InputMaybe<Scalars['String']['input']>
  created_at?: InputMaybe<Scalars['Datetime']['input']>
  creator?: InputMaybe<Scalars['UUID']['input']>
  fullbody?: InputMaybe<Scalars['String']['input']>
  has_penis_feature?: InputMaybe<Scalars['Boolean']['input']>
  hobbies?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  id?: InputMaybe<Scalars['UUID']['input']>
  labels?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  nickname?: InputMaybe<Scalars['String']['input']>
  occupation?: InputMaybe<Scalars['String']['input']>
  penis?: InputMaybe<Scalars['String']['input']>
  personalities?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  private?: InputMaybe<Scalars['Boolean']['input']>
  subscribers?: InputMaybe<Scalars['BigInt']['input']>
  templated?: InputMaybe<Scalars['BigInt']['input']>
  updated_at?: InputMaybe<Scalars['Datetime']['input']>
  version?: InputMaybe<Model_Version>
}

export type ModelsUpdateResponse = {
  __typename?: 'modelsUpdateResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Models>
}

export type Models_Deleted = Node & {
  __typename?: 'models_deleted'
  about?: Maybe<Scalars['String']['output']>
  avatar?: Maybe<Scalars['String']['output']>
  created_at?: Maybe<Scalars['Datetime']['output']>
  creator?: Maybe<Scalars['UUID']['output']>
  deleted_at?: Maybe<Scalars['Datetime']['output']>
  fullbody?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  labels?: Maybe<Array<Maybe<Scalars['Int']['output']>>>
  nickname?: Maybe<Scalars['String']['output']>
  /** Globally Unique Record Identifier */
  nodeId: Scalars['ID']['output']
  private?: Maybe<Scalars['Boolean']['output']>
  subscribers?: Maybe<Scalars['BigInt']['output']>
  templated?: Maybe<Scalars['BigInt']['output']>
  updated_at?: Maybe<Scalars['Datetime']['output']>
}

export type Models_DeletedConnection = {
  __typename?: 'models_deletedConnection'
  edges: Array<Models_DeletedEdge>
  pageInfo: PageInfo
}

export type Models_DeletedDeleteResponse = {
  __typename?: 'models_deletedDeleteResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Models_Deleted>
}

export type Models_DeletedEdge = {
  __typename?: 'models_deletedEdge'
  cursor: Scalars['String']['output']
  node: Models_Deleted
}

export type Models_DeletedFilter = {
  about?: InputMaybe<StringFilter>
  /** Returns true only if all its inner filters are true, otherwise returns false */
  and?: InputMaybe<Array<Models_DeletedFilter>>
  avatar?: InputMaybe<StringFilter>
  created_at?: InputMaybe<DatetimeFilter>
  creator?: InputMaybe<UuidFilter>
  deleted_at?: InputMaybe<DatetimeFilter>
  fullbody?: InputMaybe<StringFilter>
  id?: InputMaybe<UuidFilter>
  nickname?: InputMaybe<StringFilter>
  nodeId?: InputMaybe<IdFilter>
  /** Negates a filter */
  not?: InputMaybe<Models_DeletedFilter>
  /** Returns true if at least one of its inner filters is true, otherwise returns false */
  or?: InputMaybe<Array<Models_DeletedFilter>>
  private?: InputMaybe<BooleanFilter>
  subscribers?: InputMaybe<BigIntFilter>
  templated?: InputMaybe<BigIntFilter>
  updated_at?: InputMaybe<DatetimeFilter>
}

export type Models_DeletedInsertInput = {
  about?: InputMaybe<Scalars['String']['input']>
  avatar?: InputMaybe<Scalars['String']['input']>
  created_at?: InputMaybe<Scalars['Datetime']['input']>
  creator?: InputMaybe<Scalars['UUID']['input']>
  deleted_at?: InputMaybe<Scalars['Datetime']['input']>
  fullbody?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['UUID']['input']>
  labels?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  nickname?: InputMaybe<Scalars['String']['input']>
  private?: InputMaybe<Scalars['Boolean']['input']>
  subscribers?: InputMaybe<Scalars['BigInt']['input']>
  templated?: InputMaybe<Scalars['BigInt']['input']>
  updated_at?: InputMaybe<Scalars['Datetime']['input']>
}

export type Models_DeletedInsertResponse = {
  __typename?: 'models_deletedInsertResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Models_Deleted>
}

export type Models_DeletedOrderBy = {
  about?: InputMaybe<OrderByDirection>
  avatar?: InputMaybe<OrderByDirection>
  created_at?: InputMaybe<OrderByDirection>
  creator?: InputMaybe<OrderByDirection>
  deleted_at?: InputMaybe<OrderByDirection>
  fullbody?: InputMaybe<OrderByDirection>
  id?: InputMaybe<OrderByDirection>
  nickname?: InputMaybe<OrderByDirection>
  private?: InputMaybe<OrderByDirection>
  subscribers?: InputMaybe<OrderByDirection>
  templated?: InputMaybe<OrderByDirection>
  updated_at?: InputMaybe<OrderByDirection>
}

export type Models_DeletedUpdateInput = {
  about?: InputMaybe<Scalars['String']['input']>
  avatar?: InputMaybe<Scalars['String']['input']>
  created_at?: InputMaybe<Scalars['Datetime']['input']>
  creator?: InputMaybe<Scalars['UUID']['input']>
  deleted_at?: InputMaybe<Scalars['Datetime']['input']>
  fullbody?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['UUID']['input']>
  labels?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  nickname?: InputMaybe<Scalars['String']['input']>
  private?: InputMaybe<Scalars['Boolean']['input']>
  subscribers?: InputMaybe<Scalars['BigInt']['input']>
  templated?: InputMaybe<Scalars['BigInt']['input']>
  updated_at?: InputMaybe<Scalars['Datetime']['input']>
}

export type Models_DeletedUpdateResponse = {
  __typename?: 'models_deletedUpdateResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Models_Deleted>
}

export type Payment = Node & {
  __typename?: 'payment'
  amount: Scalars['BigFloat']['output']
  created_at: Scalars['Datetime']['output']
  id: Scalars['BigInt']['output']
  /** Globally Unique Record Identifier */
  nodeId: Scalars['ID']['output']
  source: Payment_Source
  type: Payment_Type
  user_id: Scalars['UUID']['output']
}

export type PaymentConnection = {
  __typename?: 'paymentConnection'
  edges: Array<PaymentEdge>
  pageInfo: PageInfo
}

export type PaymentDeleteResponse = {
  __typename?: 'paymentDeleteResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Payment>
}

export type PaymentEdge = {
  __typename?: 'paymentEdge'
  cursor: Scalars['String']['output']
  node: Payment
}

export type PaymentFilter = {
  amount?: InputMaybe<BigFloatFilter>
  /** Returns true only if all its inner filters are true, otherwise returns false */
  and?: InputMaybe<Array<PaymentFilter>>
  created_at?: InputMaybe<DatetimeFilter>
  id?: InputMaybe<BigIntFilter>
  nodeId?: InputMaybe<IdFilter>
  /** Negates a filter */
  not?: InputMaybe<PaymentFilter>
  /** Returns true if at least one of its inner filters is true, otherwise returns false */
  or?: InputMaybe<Array<PaymentFilter>>
  source?: InputMaybe<Payment_SourceFilter>
  type?: InputMaybe<Payment_TypeFilter>
  user_id?: InputMaybe<UuidFilter>
}

export type PaymentInsertInput = {
  amount?: InputMaybe<Scalars['BigFloat']['input']>
  created_at?: InputMaybe<Scalars['Datetime']['input']>
  source?: InputMaybe<Payment_Source>
  type?: InputMaybe<Payment_Type>
  user_id?: InputMaybe<Scalars['UUID']['input']>
}

export type PaymentInsertResponse = {
  __typename?: 'paymentInsertResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Payment>
}

export type PaymentOrderBy = {
  amount?: InputMaybe<OrderByDirection>
  created_at?: InputMaybe<OrderByDirection>
  id?: InputMaybe<OrderByDirection>
  source?: InputMaybe<OrderByDirection>
  type?: InputMaybe<OrderByDirection>
  user_id?: InputMaybe<OrderByDirection>
}

export type PaymentUpdateInput = {
  amount?: InputMaybe<Scalars['BigFloat']['input']>
  created_at?: InputMaybe<Scalars['Datetime']['input']>
  source?: InputMaybe<Payment_Source>
  type?: InputMaybe<Payment_Type>
  user_id?: InputMaybe<Scalars['UUID']['input']>
}

export type PaymentUpdateResponse = {
  __typename?: 'paymentUpdateResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Payment>
}

export enum Payment_Source {
  Gumroad = 'gumroad',
  Nowpayments = 'nowpayments',
  Sumup = 'sumup',
}

/** Boolean expression comparing fields on type "payment_source" */
export type Payment_SourceFilter = {
  eq?: InputMaybe<Payment_Source>
  in?: InputMaybe<Array<Payment_Source>>
  is?: InputMaybe<FilterIs>
  neq?: InputMaybe<Payment_Source>
}

export enum Payment_Type {
  OnetimeMembership = 'onetime_membership',
  OnetimePackage = 'onetime_package',
  RecurringMembership = 'recurring_membership',
}

/** Boolean expression comparing fields on type "payment_type" */
export type Payment_TypeFilter = {
  eq?: InputMaybe<Payment_Type>
  in?: InputMaybe<Array<Payment_Type>>
  is?: InputMaybe<FilterIs>
  neq?: InputMaybe<Payment_Type>
}

export type Post_Likes = Node & {
  __typename?: 'post_likes'
  created_at?: Maybe<Scalars['Datetime']['output']>
  id: Scalars['BigInt']['output']
  /** Globally Unique Record Identifier */
  nodeId: Scalars['ID']['output']
  post: Posts
  post_id: Scalars['BigInt']['output']
  user_id: Scalars['UUID']['output']
}

export type Post_LikesConnection = {
  __typename?: 'post_likesConnection'
  edges: Array<Post_LikesEdge>
  pageInfo: PageInfo
}

export type Post_LikesDeleteResponse = {
  __typename?: 'post_likesDeleteResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Post_Likes>
}

export type Post_LikesEdge = {
  __typename?: 'post_likesEdge'
  cursor: Scalars['String']['output']
  node: Post_Likes
}

export type Post_LikesFilter = {
  /** Returns true only if all its inner filters are true, otherwise returns false */
  and?: InputMaybe<Array<Post_LikesFilter>>
  created_at?: InputMaybe<DatetimeFilter>
  id?: InputMaybe<BigIntFilter>
  nodeId?: InputMaybe<IdFilter>
  /** Negates a filter */
  not?: InputMaybe<Post_LikesFilter>
  /** Returns true if at least one of its inner filters is true, otherwise returns false */
  or?: InputMaybe<Array<Post_LikesFilter>>
  post_id?: InputMaybe<BigIntFilter>
  user_id?: InputMaybe<UuidFilter>
}

export type Post_LikesInsertInput = {
  created_at?: InputMaybe<Scalars['Datetime']['input']>
  post_id?: InputMaybe<Scalars['BigInt']['input']>
  user_id?: InputMaybe<Scalars['UUID']['input']>
}

export type Post_LikesInsertResponse = {
  __typename?: 'post_likesInsertResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Post_Likes>
}

export type Post_LikesOrderBy = {
  created_at?: InputMaybe<OrderByDirection>
  id?: InputMaybe<OrderByDirection>
  post_id?: InputMaybe<OrderByDirection>
  user_id?: InputMaybe<OrderByDirection>
}

export type Post_LikesUpdateInput = {
  created_at?: InputMaybe<Scalars['Datetime']['input']>
  post_id?: InputMaybe<Scalars['BigInt']['input']>
  user_id?: InputMaybe<Scalars['UUID']['input']>
}

export type Post_LikesUpdateResponse = {
  __typename?: 'post_likesUpdateResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Post_Likes>
}

export type Posts = Node & {
  __typename?: 'posts'
  comments?: Maybe<Scalars['Int']['output']>
  content?: Maybe<Scalars['String']['output']>
  created_at?: Maybe<Scalars['Datetime']['output']>
  creator?: Maybe<Scalars['UUID']['output']>
  gid?: Maybe<Scalars['String']['output']>
  id: Scalars['BigInt']['output']
  images?: Maybe<Scalars['JSON']['output']>
  likes?: Maybe<Scalars['Int']['output']>
  model: Models
  model_id: Scalars['UUID']['output']
  /** Globally Unique Record Identifier */
  nodeId: Scalars['ID']['output']
  post_likesCollection?: Maybe<Post_LikesConnection>
  private?: Maybe<Scalars['Boolean']['output']>
  tag_combos?: Maybe<Scalars['JSON']['output']>
  tag_reused?: Maybe<Scalars['Int']['output']>
  user?: Maybe<Users>
  user_tags?: Maybe<Array<Maybe<Scalars['BigInt']['output']>>>
  version?: Maybe<Model_Version>
}

export type PostsPost_LikesCollectionArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  before?: InputMaybe<Scalars['Cursor']['input']>
  filter?: InputMaybe<Post_LikesFilter>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Array<Post_LikesOrderBy>>
}

export type PostsConnection = {
  __typename?: 'postsConnection'
  edges: Array<PostsEdge>
  pageInfo: PageInfo
  /** The total number of records matching the `filter` criteria */
  totalCount: Scalars['Int']['output']
}

export type PostsDeleteResponse = {
  __typename?: 'postsDeleteResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Posts>
}

export type PostsEdge = {
  __typename?: 'postsEdge'
  cursor: Scalars['String']['output']
  node: Posts
}

export type PostsFilter = {
  /** Returns true only if all its inner filters are true, otherwise returns false */
  and?: InputMaybe<Array<PostsFilter>>
  comments?: InputMaybe<IntFilter>
  content?: InputMaybe<StringFilter>
  created_at?: InputMaybe<DatetimeFilter>
  creator?: InputMaybe<UuidFilter>
  gid?: InputMaybe<StringFilter>
  id?: InputMaybe<BigIntFilter>
  likes?: InputMaybe<IntFilter>
  model_id?: InputMaybe<UuidFilter>
  nodeId?: InputMaybe<IdFilter>
  /** Negates a filter */
  not?: InputMaybe<PostsFilter>
  /** Returns true if at least one of its inner filters is true, otherwise returns false */
  or?: InputMaybe<Array<PostsFilter>>
  private?: InputMaybe<BooleanFilter>
  tag_reused?: InputMaybe<IntFilter>
  version?: InputMaybe<Model_VersionFilter>
}

export type PostsInsertInput = {
  comments?: InputMaybe<Scalars['Int']['input']>
  content?: InputMaybe<Scalars['String']['input']>
  created_at?: InputMaybe<Scalars['Datetime']['input']>
  creator?: InputMaybe<Scalars['UUID']['input']>
  gid?: InputMaybe<Scalars['String']['input']>
  images?: InputMaybe<Scalars['JSON']['input']>
  likes?: InputMaybe<Scalars['Int']['input']>
  model_id?: InputMaybe<Scalars['UUID']['input']>
  private?: InputMaybe<Scalars['Boolean']['input']>
  tag_combos?: InputMaybe<Scalars['JSON']['input']>
  tag_reused?: InputMaybe<Scalars['Int']['input']>
  user_tags?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>
  version?: InputMaybe<Model_Version>
}

export type PostsInsertResponse = {
  __typename?: 'postsInsertResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Posts>
}

export type PostsOrderBy = {
  comments?: InputMaybe<OrderByDirection>
  content?: InputMaybe<OrderByDirection>
  created_at?: InputMaybe<OrderByDirection>
  creator?: InputMaybe<OrderByDirection>
  gid?: InputMaybe<OrderByDirection>
  id?: InputMaybe<OrderByDirection>
  likes?: InputMaybe<OrderByDirection>
  model_id?: InputMaybe<OrderByDirection>
  private?: InputMaybe<OrderByDirection>
  tag_reused?: InputMaybe<OrderByDirection>
  version?: InputMaybe<OrderByDirection>
}

export type PostsUpdateInput = {
  comments?: InputMaybe<Scalars['Int']['input']>
  content?: InputMaybe<Scalars['String']['input']>
  created_at?: InputMaybe<Scalars['Datetime']['input']>
  creator?: InputMaybe<Scalars['UUID']['input']>
  gid?: InputMaybe<Scalars['String']['input']>
  images?: InputMaybe<Scalars['JSON']['input']>
  likes?: InputMaybe<Scalars['Int']['input']>
  model_id?: InputMaybe<Scalars['UUID']['input']>
  private?: InputMaybe<Scalars['Boolean']['input']>
  tag_combos?: InputMaybe<Scalars['JSON']['input']>
  tag_reused?: InputMaybe<Scalars['Int']['input']>
  user_tags?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>
  version?: InputMaybe<Model_Version>
}

export type PostsUpdateResponse = {
  __typename?: 'postsUpdateResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Posts>
}

export type Posts_Deleted = Node & {
  __typename?: 'posts_deleted'
  comments?: Maybe<Scalars['Int']['output']>
  content?: Maybe<Scalars['String']['output']>
  created_at?: Maybe<Scalars['Datetime']['output']>
  creator?: Maybe<Scalars['UUID']['output']>
  deleted_at?: Maybe<Scalars['Datetime']['output']>
  id: Scalars['BigInt']['output']
  images?: Maybe<Scalars['JSON']['output']>
  likes?: Maybe<Scalars['Int']['output']>
  model_id: Scalars['UUID']['output']
  /** Globally Unique Record Identifier */
  nodeId: Scalars['ID']['output']
  private?: Maybe<Scalars['Boolean']['output']>
  tag_combos?: Maybe<Scalars['JSON']['output']>
  tag_reused?: Maybe<Scalars['Int']['output']>
  user_tags?: Maybe<Array<Maybe<Scalars['BigInt']['output']>>>
}

export type Posts_DeletedConnection = {
  __typename?: 'posts_deletedConnection'
  edges: Array<Posts_DeletedEdge>
  pageInfo: PageInfo
}

export type Posts_DeletedDeleteResponse = {
  __typename?: 'posts_deletedDeleteResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Posts_Deleted>
}

export type Posts_DeletedEdge = {
  __typename?: 'posts_deletedEdge'
  cursor: Scalars['String']['output']
  node: Posts_Deleted
}

export type Posts_DeletedFilter = {
  /** Returns true only if all its inner filters are true, otherwise returns false */
  and?: InputMaybe<Array<Posts_DeletedFilter>>
  comments?: InputMaybe<IntFilter>
  content?: InputMaybe<StringFilter>
  created_at?: InputMaybe<DatetimeFilter>
  creator?: InputMaybe<UuidFilter>
  deleted_at?: InputMaybe<DatetimeFilter>
  id?: InputMaybe<BigIntFilter>
  likes?: InputMaybe<IntFilter>
  model_id?: InputMaybe<UuidFilter>
  nodeId?: InputMaybe<IdFilter>
  /** Negates a filter */
  not?: InputMaybe<Posts_DeletedFilter>
  /** Returns true if at least one of its inner filters is true, otherwise returns false */
  or?: InputMaybe<Array<Posts_DeletedFilter>>
  private?: InputMaybe<BooleanFilter>
  tag_reused?: InputMaybe<IntFilter>
}

export type Posts_DeletedInsertInput = {
  comments?: InputMaybe<Scalars['Int']['input']>
  content?: InputMaybe<Scalars['String']['input']>
  created_at?: InputMaybe<Scalars['Datetime']['input']>
  creator?: InputMaybe<Scalars['UUID']['input']>
  deleted_at?: InputMaybe<Scalars['Datetime']['input']>
  images?: InputMaybe<Scalars['JSON']['input']>
  likes?: InputMaybe<Scalars['Int']['input']>
  model_id?: InputMaybe<Scalars['UUID']['input']>
  private?: InputMaybe<Scalars['Boolean']['input']>
  tag_combos?: InputMaybe<Scalars['JSON']['input']>
  tag_reused?: InputMaybe<Scalars['Int']['input']>
  user_tags?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>
}

export type Posts_DeletedInsertResponse = {
  __typename?: 'posts_deletedInsertResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Posts_Deleted>
}

export type Posts_DeletedOrderBy = {
  comments?: InputMaybe<OrderByDirection>
  content?: InputMaybe<OrderByDirection>
  created_at?: InputMaybe<OrderByDirection>
  creator?: InputMaybe<OrderByDirection>
  deleted_at?: InputMaybe<OrderByDirection>
  id?: InputMaybe<OrderByDirection>
  likes?: InputMaybe<OrderByDirection>
  model_id?: InputMaybe<OrderByDirection>
  private?: InputMaybe<OrderByDirection>
  tag_reused?: InputMaybe<OrderByDirection>
}

export type Posts_DeletedUpdateInput = {
  comments?: InputMaybe<Scalars['Int']['input']>
  content?: InputMaybe<Scalars['String']['input']>
  created_at?: InputMaybe<Scalars['Datetime']['input']>
  creator?: InputMaybe<Scalars['UUID']['input']>
  deleted_at?: InputMaybe<Scalars['Datetime']['input']>
  images?: InputMaybe<Scalars['JSON']['input']>
  likes?: InputMaybe<Scalars['Int']['input']>
  model_id?: InputMaybe<Scalars['UUID']['input']>
  private?: InputMaybe<Scalars['Boolean']['input']>
  tag_combos?: InputMaybe<Scalars['JSON']['input']>
  tag_reused?: InputMaybe<Scalars['Int']['input']>
  user_tags?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>
}

export type Posts_DeletedUpdateResponse = {
  __typename?: 'posts_deletedUpdateResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Posts_Deleted>
}

export type Posts_Pending = Node & {
  __typename?: 'posts_pending'
  comments?: Maybe<Scalars['Int']['output']>
  content?: Maybe<Scalars['String']['output']>
  created_at?: Maybe<Scalars['Datetime']['output']>
  creator?: Maybe<Scalars['UUID']['output']>
  gid?: Maybe<Scalars['String']['output']>
  id: Scalars['BigInt']['output']
  images?: Maybe<Scalars['JSON']['output']>
  likes?: Maybe<Scalars['Int']['output']>
  model: Models
  model_id: Scalars['UUID']['output']
  /** Globally Unique Record Identifier */
  nodeId: Scalars['ID']['output']
  private?: Maybe<Scalars['Boolean']['output']>
  tag_combos?: Maybe<Scalars['JSON']['output']>
  tag_reused?: Maybe<Scalars['Int']['output']>
  user?: Maybe<Users>
  user_tags?: Maybe<Array<Maybe<Scalars['BigInt']['output']>>>
  version?: Maybe<Model_Version>
}

export type Posts_PendingConnection = {
  __typename?: 'posts_pendingConnection'
  edges: Array<Posts_PendingEdge>
  pageInfo: PageInfo
  /** The total number of records matching the `filter` criteria */
  totalCount: Scalars['Int']['output']
}

export type Posts_PendingDeleteResponse = {
  __typename?: 'posts_pendingDeleteResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Posts_Pending>
}

export type Posts_PendingEdge = {
  __typename?: 'posts_pendingEdge'
  cursor: Scalars['String']['output']
  node: Posts_Pending
}

export type Posts_PendingFilter = {
  /** Returns true only if all its inner filters are true, otherwise returns false */
  and?: InputMaybe<Array<Posts_PendingFilter>>
  comments?: InputMaybe<IntFilter>
  content?: InputMaybe<StringFilter>
  created_at?: InputMaybe<DatetimeFilter>
  creator?: InputMaybe<UuidFilter>
  gid?: InputMaybe<StringFilter>
  id?: InputMaybe<BigIntFilter>
  likes?: InputMaybe<IntFilter>
  model_id?: InputMaybe<UuidFilter>
  nodeId?: InputMaybe<IdFilter>
  /** Negates a filter */
  not?: InputMaybe<Posts_PendingFilter>
  /** Returns true if at least one of its inner filters is true, otherwise returns false */
  or?: InputMaybe<Array<Posts_PendingFilter>>
  private?: InputMaybe<BooleanFilter>
  tag_reused?: InputMaybe<IntFilter>
  version?: InputMaybe<Model_VersionFilter>
}

export type Posts_PendingInsertInput = {
  comments?: InputMaybe<Scalars['Int']['input']>
  content?: InputMaybe<Scalars['String']['input']>
  created_at?: InputMaybe<Scalars['Datetime']['input']>
  creator?: InputMaybe<Scalars['UUID']['input']>
  gid?: InputMaybe<Scalars['String']['input']>
  images?: InputMaybe<Scalars['JSON']['input']>
  likes?: InputMaybe<Scalars['Int']['input']>
  model_id?: InputMaybe<Scalars['UUID']['input']>
  private?: InputMaybe<Scalars['Boolean']['input']>
  tag_combos?: InputMaybe<Scalars['JSON']['input']>
  tag_reused?: InputMaybe<Scalars['Int']['input']>
  user_tags?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>
  version?: InputMaybe<Model_Version>
}

export type Posts_PendingInsertResponse = {
  __typename?: 'posts_pendingInsertResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Posts_Pending>
}

export type Posts_PendingOrderBy = {
  comments?: InputMaybe<OrderByDirection>
  content?: InputMaybe<OrderByDirection>
  created_at?: InputMaybe<OrderByDirection>
  creator?: InputMaybe<OrderByDirection>
  gid?: InputMaybe<OrderByDirection>
  id?: InputMaybe<OrderByDirection>
  likes?: InputMaybe<OrderByDirection>
  model_id?: InputMaybe<OrderByDirection>
  private?: InputMaybe<OrderByDirection>
  tag_reused?: InputMaybe<OrderByDirection>
  version?: InputMaybe<OrderByDirection>
}

export type Posts_PendingUpdateInput = {
  comments?: InputMaybe<Scalars['Int']['input']>
  content?: InputMaybe<Scalars['String']['input']>
  created_at?: InputMaybe<Scalars['Datetime']['input']>
  creator?: InputMaybe<Scalars['UUID']['input']>
  gid?: InputMaybe<Scalars['String']['input']>
  images?: InputMaybe<Scalars['JSON']['input']>
  likes?: InputMaybe<Scalars['Int']['input']>
  model_id?: InputMaybe<Scalars['UUID']['input']>
  private?: InputMaybe<Scalars['Boolean']['input']>
  tag_combos?: InputMaybe<Scalars['JSON']['input']>
  tag_reused?: InputMaybe<Scalars['Int']['input']>
  user_tags?: InputMaybe<Array<InputMaybe<Scalars['BigInt']['input']>>>
  version?: InputMaybe<Model_Version>
}

export type Posts_PendingUpdateResponse = {
  __typename?: 'posts_pendingUpdateResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Posts_Pending>
}

export type Prices = Node & {
  __typename?: 'prices'
  active?: Maybe<Scalars['Boolean']['output']>
  created_at?: Maybe<Scalars['Datetime']['output']>
  currency?: Maybe<Scalars['String']['output']>
  id: Scalars['String']['output']
  interval?: Maybe<Pricing_Plan_Interval>
  interval_count?: Maybe<Scalars['Int']['output']>
  invoicesCollection?: Maybe<InvoicesConnection>
  name?: Maybe<Scalars['String']['output']>
  /** Globally Unique Record Identifier */
  nodeId: Scalars['ID']['output']
  product?: Maybe<Products>
  product_id?: Maybe<Scalars['String']['output']>
  subscriptionsCollection?: Maybe<SubscriptionsConnection>
  type?: Maybe<Pricing_Type>
  unit_amount?: Maybe<Scalars['BigInt']['output']>
  updated_at?: Maybe<Scalars['Datetime']['output']>
}

export type PricesInvoicesCollectionArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  before?: InputMaybe<Scalars['Cursor']['input']>
  filter?: InputMaybe<InvoicesFilter>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Array<InvoicesOrderBy>>
}

export type PricesSubscriptionsCollectionArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  before?: InputMaybe<Scalars['Cursor']['input']>
  filter?: InputMaybe<SubscriptionsFilter>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Array<SubscriptionsOrderBy>>
}

export type PricesConnection = {
  __typename?: 'pricesConnection'
  edges: Array<PricesEdge>
  pageInfo: PageInfo
}

export type PricesDeleteResponse = {
  __typename?: 'pricesDeleteResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Prices>
}

export type PricesEdge = {
  __typename?: 'pricesEdge'
  cursor: Scalars['String']['output']
  node: Prices
}

export type PricesFilter = {
  active?: InputMaybe<BooleanFilter>
  /** Returns true only if all its inner filters are true, otherwise returns false */
  and?: InputMaybe<Array<PricesFilter>>
  created_at?: InputMaybe<DatetimeFilter>
  currency?: InputMaybe<StringFilter>
  id?: InputMaybe<StringFilter>
  interval?: InputMaybe<Pricing_Plan_IntervalFilter>
  interval_count?: InputMaybe<IntFilter>
  name?: InputMaybe<StringFilter>
  nodeId?: InputMaybe<IdFilter>
  /** Negates a filter */
  not?: InputMaybe<PricesFilter>
  /** Returns true if at least one of its inner filters is true, otherwise returns false */
  or?: InputMaybe<Array<PricesFilter>>
  product_id?: InputMaybe<StringFilter>
  type?: InputMaybe<Pricing_TypeFilter>
  unit_amount?: InputMaybe<BigIntFilter>
  updated_at?: InputMaybe<DatetimeFilter>
}

export type PricesInsertInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>
  created_at?: InputMaybe<Scalars['Datetime']['input']>
  currency?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['String']['input']>
  interval?: InputMaybe<Pricing_Plan_Interval>
  interval_count?: InputMaybe<Scalars['Int']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  product_id?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<Pricing_Type>
  unit_amount?: InputMaybe<Scalars['BigInt']['input']>
  updated_at?: InputMaybe<Scalars['Datetime']['input']>
}

export type PricesInsertResponse = {
  __typename?: 'pricesInsertResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Prices>
}

export type PricesOrderBy = {
  active?: InputMaybe<OrderByDirection>
  created_at?: InputMaybe<OrderByDirection>
  currency?: InputMaybe<OrderByDirection>
  id?: InputMaybe<OrderByDirection>
  interval?: InputMaybe<OrderByDirection>
  interval_count?: InputMaybe<OrderByDirection>
  name?: InputMaybe<OrderByDirection>
  product_id?: InputMaybe<OrderByDirection>
  type?: InputMaybe<OrderByDirection>
  unit_amount?: InputMaybe<OrderByDirection>
  updated_at?: InputMaybe<OrderByDirection>
}

export type PricesUpdateInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>
  created_at?: InputMaybe<Scalars['Datetime']['input']>
  currency?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['String']['input']>
  interval?: InputMaybe<Pricing_Plan_Interval>
  interval_count?: InputMaybe<Scalars['Int']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  product_id?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<Pricing_Type>
  unit_amount?: InputMaybe<Scalars['BigInt']['input']>
  updated_at?: InputMaybe<Scalars['Datetime']['input']>
}

export type PricesUpdateResponse = {
  __typename?: 'pricesUpdateResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Prices>
}

export enum Pricing_Plan_Interval {
  Day = 'day',
  Month = 'month',
  Week = 'week',
  Year = 'year',
}

/** Boolean expression comparing fields on type "pricing_plan_interval" */
export type Pricing_Plan_IntervalFilter = {
  eq?: InputMaybe<Pricing_Plan_Interval>
  in?: InputMaybe<Array<Pricing_Plan_Interval>>
  is?: InputMaybe<FilterIs>
  neq?: InputMaybe<Pricing_Plan_Interval>
}

export enum Pricing_Type {
  OneTime = 'one_time',
  Recurring = 'recurring',
}

/** Boolean expression comparing fields on type "pricing_type" */
export type Pricing_TypeFilter = {
  eq?: InputMaybe<Pricing_Type>
  in?: InputMaybe<Array<Pricing_Type>>
  is?: InputMaybe<FilterIs>
  neq?: InputMaybe<Pricing_Type>
}

export enum Product_Tier {
  AddonBig = 'addon_big',
  AddonMedium = 'addon_medium',
  AddonSmall = 'addon_small',
  SubscriptionPlus = 'subscription_plus',
  SubscriptionPremium = 'subscription_premium',
  SubscriptionPro = 'subscription_pro',
}

/** Boolean expression comparing fields on type "product_tier" */
export type Product_TierFilter = {
  eq?: InputMaybe<Product_Tier>
  in?: InputMaybe<Array<Product_Tier>>
  is?: InputMaybe<FilterIs>
  neq?: InputMaybe<Product_Tier>
}

export type Products = Node & {
  __typename?: 'products'
  active?: Maybe<Scalars['Boolean']['output']>
  created_at?: Maybe<Scalars['Datetime']['output']>
  credits: Scalars['Int']['output']
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['String']['output']
  invoicesCollection?: Maybe<InvoicesConnection>
  name?: Maybe<Scalars['String']['output']>
  /** Globally Unique Record Identifier */
  nodeId: Scalars['ID']['output']
  pricesCollection?: Maybe<PricesConnection>
  subscriptionsCollection?: Maybe<SubscriptionsConnection>
  tier?: Maybe<Product_Tier>
  updated_at?: Maybe<Scalars['Datetime']['output']>
}

export type ProductsInvoicesCollectionArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  before?: InputMaybe<Scalars['Cursor']['input']>
  filter?: InputMaybe<InvoicesFilter>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Array<InvoicesOrderBy>>
}

export type ProductsPricesCollectionArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  before?: InputMaybe<Scalars['Cursor']['input']>
  filter?: InputMaybe<PricesFilter>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Array<PricesOrderBy>>
}

export type ProductsSubscriptionsCollectionArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  before?: InputMaybe<Scalars['Cursor']['input']>
  filter?: InputMaybe<SubscriptionsFilter>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Array<SubscriptionsOrderBy>>
}

export type ProductsConnection = {
  __typename?: 'productsConnection'
  edges: Array<ProductsEdge>
  pageInfo: PageInfo
}

export type ProductsDeleteResponse = {
  __typename?: 'productsDeleteResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Products>
}

export type ProductsEdge = {
  __typename?: 'productsEdge'
  cursor: Scalars['String']['output']
  node: Products
}

export type ProductsFilter = {
  active?: InputMaybe<BooleanFilter>
  /** Returns true only if all its inner filters are true, otherwise returns false */
  and?: InputMaybe<Array<ProductsFilter>>
  created_at?: InputMaybe<DatetimeFilter>
  credits?: InputMaybe<IntFilter>
  description?: InputMaybe<StringFilter>
  id?: InputMaybe<StringFilter>
  name?: InputMaybe<StringFilter>
  nodeId?: InputMaybe<IdFilter>
  /** Negates a filter */
  not?: InputMaybe<ProductsFilter>
  /** Returns true if at least one of its inner filters is true, otherwise returns false */
  or?: InputMaybe<Array<ProductsFilter>>
  tier?: InputMaybe<Product_TierFilter>
  updated_at?: InputMaybe<DatetimeFilter>
}

export type ProductsInsertInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>
  created_at?: InputMaybe<Scalars['Datetime']['input']>
  credits?: InputMaybe<Scalars['Int']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  tier?: InputMaybe<Product_Tier>
  updated_at?: InputMaybe<Scalars['Datetime']['input']>
}

export type ProductsInsertResponse = {
  __typename?: 'productsInsertResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Products>
}

export type ProductsOrderBy = {
  active?: InputMaybe<OrderByDirection>
  created_at?: InputMaybe<OrderByDirection>
  credits?: InputMaybe<OrderByDirection>
  description?: InputMaybe<OrderByDirection>
  id?: InputMaybe<OrderByDirection>
  name?: InputMaybe<OrderByDirection>
  tier?: InputMaybe<OrderByDirection>
  updated_at?: InputMaybe<OrderByDirection>
}

export type ProductsUpdateInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>
  created_at?: InputMaybe<Scalars['Datetime']['input']>
  credits?: InputMaybe<Scalars['Int']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  tier?: InputMaybe<Product_Tier>
  updated_at?: InputMaybe<Scalars['Datetime']['input']>
}

export type ProductsUpdateResponse = {
  __typename?: 'productsUpdateResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Products>
}

export type Profiles = Node & {
  __typename?: 'profiles'
  about?: Maybe<Scalars['String']['output']>
  avatar_url?: Maybe<Scalars['String']['output']>
  commentsCollection?: Maybe<CommentsConnection>
  created_at?: Maybe<Scalars['Datetime']['output']>
  id: Scalars['UUID']['output']
  name?: Maybe<Scalars['String']['output']>
  /** Globally Unique Record Identifier */
  nodeId: Scalars['ID']['output']
  updated_at?: Maybe<Scalars['Datetime']['output']>
  user: Users
}

export type ProfilesCommentsCollectionArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  before?: InputMaybe<Scalars['Cursor']['input']>
  filter?: InputMaybe<CommentsFilter>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Array<CommentsOrderBy>>
}

export type ProfilesConnection = {
  __typename?: 'profilesConnection'
  edges: Array<ProfilesEdge>
  pageInfo: PageInfo
  /** The total number of records matching the `filter` criteria */
  totalCount: Scalars['Int']['output']
}

export type ProfilesDeleteResponse = {
  __typename?: 'profilesDeleteResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Profiles>
}

export type ProfilesEdge = {
  __typename?: 'profilesEdge'
  cursor: Scalars['String']['output']
  node: Profiles
}

export type ProfilesFilter = {
  about?: InputMaybe<StringFilter>
  /** Returns true only if all its inner filters are true, otherwise returns false */
  and?: InputMaybe<Array<ProfilesFilter>>
  avatar_url?: InputMaybe<StringFilter>
  created_at?: InputMaybe<DatetimeFilter>
  id?: InputMaybe<UuidFilter>
  name?: InputMaybe<StringFilter>
  nodeId?: InputMaybe<IdFilter>
  /** Negates a filter */
  not?: InputMaybe<ProfilesFilter>
  /** Returns true if at least one of its inner filters is true, otherwise returns false */
  or?: InputMaybe<Array<ProfilesFilter>>
  updated_at?: InputMaybe<DatetimeFilter>
}

export type ProfilesInsertInput = {
  about?: InputMaybe<Scalars['String']['input']>
  avatar_url?: InputMaybe<Scalars['String']['input']>
  created_at?: InputMaybe<Scalars['Datetime']['input']>
  id?: InputMaybe<Scalars['UUID']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['Datetime']['input']>
}

export type ProfilesInsertResponse = {
  __typename?: 'profilesInsertResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Profiles>
}

export type ProfilesOrderBy = {
  about?: InputMaybe<OrderByDirection>
  avatar_url?: InputMaybe<OrderByDirection>
  created_at?: InputMaybe<OrderByDirection>
  id?: InputMaybe<OrderByDirection>
  name?: InputMaybe<OrderByDirection>
  updated_at?: InputMaybe<OrderByDirection>
}

export type ProfilesUpdateInput = {
  about?: InputMaybe<Scalars['String']['input']>
  avatar_url?: InputMaybe<Scalars['String']['input']>
  created_at?: InputMaybe<Scalars['Datetime']['input']>
  id?: InputMaybe<Scalars['UUID']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['Datetime']['input']>
}

export type ProfilesUpdateResponse = {
  __typename?: 'profilesUpdateResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Profiles>
}

export enum Scenario_Type {
  Private = 'private',
  Public = 'public',
}

/** Boolean expression comparing fields on type "scenario_type" */
export type Scenario_TypeFilter = {
  eq?: InputMaybe<Scenario_Type>
  in?: InputMaybe<Array<Scenario_Type>>
  is?: InputMaybe<FilterIs>
  neq?: InputMaybe<Scenario_Type>
}

export type Session_Message_Interactions = Node & {
  __typename?: 'session_message_interactions'
  content?: Maybe<Scalars['String']['output']>
  created_at: Scalars['Datetime']['output']
  id: Scalars['BigInt']['output']
  message: Session_Messages
  /** Globally Unique Record Identifier */
  nodeId: Scalars['ID']['output']
  topic: Session_Topics
  topic_id: Scalars['Int']['output']
  type: Message_Interaction_Type
}

export type Session_Message_InteractionsConnection = {
  __typename?: 'session_message_interactionsConnection'
  edges: Array<Session_Message_InteractionsEdge>
  pageInfo: PageInfo
}

export type Session_Message_InteractionsDeleteResponse = {
  __typename?: 'session_message_interactionsDeleteResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Session_Message_Interactions>
}

export type Session_Message_InteractionsEdge = {
  __typename?: 'session_message_interactionsEdge'
  cursor: Scalars['String']['output']
  node: Session_Message_Interactions
}

export type Session_Message_InteractionsFilter = {
  /** Returns true only if all its inner filters are true, otherwise returns false */
  and?: InputMaybe<Array<Session_Message_InteractionsFilter>>
  content?: InputMaybe<StringFilter>
  created_at?: InputMaybe<DatetimeFilter>
  id?: InputMaybe<BigIntFilter>
  nodeId?: InputMaybe<IdFilter>
  /** Negates a filter */
  not?: InputMaybe<Session_Message_InteractionsFilter>
  /** Returns true if at least one of its inner filters is true, otherwise returns false */
  or?: InputMaybe<Array<Session_Message_InteractionsFilter>>
  topic_id?: InputMaybe<IntFilter>
  type?: InputMaybe<Message_Interaction_TypeFilter>
}

export type Session_Message_InteractionsInsertInput = {
  content?: InputMaybe<Scalars['String']['input']>
  created_at?: InputMaybe<Scalars['Datetime']['input']>
  topic_id?: InputMaybe<Scalars['Int']['input']>
  type?: InputMaybe<Message_Interaction_Type>
}

export type Session_Message_InteractionsInsertResponse = {
  __typename?: 'session_message_interactionsInsertResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Session_Message_Interactions>
}

export type Session_Message_InteractionsOrderBy = {
  content?: InputMaybe<OrderByDirection>
  created_at?: InputMaybe<OrderByDirection>
  id?: InputMaybe<OrderByDirection>
  topic_id?: InputMaybe<OrderByDirection>
  type?: InputMaybe<OrderByDirection>
}

export type Session_Message_InteractionsUpdateInput = {
  content?: InputMaybe<Scalars['String']['input']>
  created_at?: InputMaybe<Scalars['Datetime']['input']>
  topic_id?: InputMaybe<Scalars['Int']['input']>
  type?: InputMaybe<Message_Interaction_Type>
}

export type Session_Message_InteractionsUpdateResponse = {
  __typename?: 'session_message_interactionsUpdateResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Session_Message_Interactions>
}

export type Session_Messages = Node & {
  __typename?: 'session_messages'
  content?: Maybe<Scalars['String']['output']>
  created_at: Scalars['Datetime']['output']
  error?: Maybe<Message_Error>
  files?: Maybe<Scalars['JSON']['output']>
  id: Scalars['BigInt']['output']
  /** Globally Unique Record Identifier */
  nodeId: Scalars['ID']['output']
  role: Message_Role
  session_id: Scalars['UUID']['output']
  session_message_interactions: Session_Message_Interactions
  topic_id: Scalars['BigInt']['output']
  type: Message_Type
  updated_at: Scalars['Datetime']['output']
}

export type Session_MessagesConnection = {
  __typename?: 'session_messagesConnection'
  edges: Array<Session_MessagesEdge>
  pageInfo: PageInfo
}

export type Session_MessagesDeleteResponse = {
  __typename?: 'session_messagesDeleteResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Session_Messages>
}

export type Session_MessagesEdge = {
  __typename?: 'session_messagesEdge'
  cursor: Scalars['String']['output']
  node: Session_Messages
}

export type Session_MessagesFilter = {
  /** Returns true only if all its inner filters are true, otherwise returns false */
  and?: InputMaybe<Array<Session_MessagesFilter>>
  content?: InputMaybe<StringFilter>
  created_at?: InputMaybe<DatetimeFilter>
  error?: InputMaybe<Message_ErrorFilter>
  id?: InputMaybe<BigIntFilter>
  nodeId?: InputMaybe<IdFilter>
  /** Negates a filter */
  not?: InputMaybe<Session_MessagesFilter>
  /** Returns true if at least one of its inner filters is true, otherwise returns false */
  or?: InputMaybe<Array<Session_MessagesFilter>>
  role?: InputMaybe<Message_RoleFilter>
  session_id?: InputMaybe<UuidFilter>
  topic_id?: InputMaybe<BigIntFilter>
  type?: InputMaybe<Message_TypeFilter>
  updated_at?: InputMaybe<DatetimeFilter>
}

export type Session_MessagesInsertInput = {
  content?: InputMaybe<Scalars['String']['input']>
  created_at?: InputMaybe<Scalars['Datetime']['input']>
  error?: InputMaybe<Message_Error>
  files?: InputMaybe<Scalars['JSON']['input']>
  role?: InputMaybe<Message_Role>
  session_id?: InputMaybe<Scalars['UUID']['input']>
  topic_id?: InputMaybe<Scalars['BigInt']['input']>
  type?: InputMaybe<Message_Type>
  updated_at?: InputMaybe<Scalars['Datetime']['input']>
}

export type Session_MessagesInsertResponse = {
  __typename?: 'session_messagesInsertResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Session_Messages>
}

export type Session_MessagesOrderBy = {
  content?: InputMaybe<OrderByDirection>
  created_at?: InputMaybe<OrderByDirection>
  error?: InputMaybe<OrderByDirection>
  id?: InputMaybe<OrderByDirection>
  role?: InputMaybe<OrderByDirection>
  session_id?: InputMaybe<OrderByDirection>
  topic_id?: InputMaybe<OrderByDirection>
  type?: InputMaybe<OrderByDirection>
  updated_at?: InputMaybe<OrderByDirection>
}

export type Session_MessagesUpdateInput = {
  content?: InputMaybe<Scalars['String']['input']>
  created_at?: InputMaybe<Scalars['Datetime']['input']>
  error?: InputMaybe<Message_Error>
  files?: InputMaybe<Scalars['JSON']['input']>
  role?: InputMaybe<Message_Role>
  session_id?: InputMaybe<Scalars['UUID']['input']>
  topic_id?: InputMaybe<Scalars['BigInt']['input']>
  type?: InputMaybe<Message_Type>
  updated_at?: InputMaybe<Scalars['Datetime']['input']>
}

export type Session_MessagesUpdateResponse = {
  __typename?: 'session_messagesUpdateResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Session_Messages>
}

export type Session_Scenario_Likes = Node & {
  __typename?: 'session_scenario_likes'
  created_at: Scalars['Datetime']['output']
  id: Scalars['BigInt']['output']
  /** Globally Unique Record Identifier */
  nodeId: Scalars['ID']['output']
  scenario: Session_Scenarios
  scenario_id: Scalars['Int']['output']
  user: Users
  user_id: Scalars['UUID']['output']
}

export type Session_Scenario_LikesConnection = {
  __typename?: 'session_scenario_likesConnection'
  edges: Array<Session_Scenario_LikesEdge>
  pageInfo: PageInfo
  /** The total number of records matching the `filter` criteria */
  totalCount: Scalars['Int']['output']
}

export type Session_Scenario_LikesDeleteResponse = {
  __typename?: 'session_scenario_likesDeleteResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Session_Scenario_Likes>
}

export type Session_Scenario_LikesEdge = {
  __typename?: 'session_scenario_likesEdge'
  cursor: Scalars['String']['output']
  node: Session_Scenario_Likes
}

export type Session_Scenario_LikesFilter = {
  /** Returns true only if all its inner filters are true, otherwise returns false */
  and?: InputMaybe<Array<Session_Scenario_LikesFilter>>
  created_at?: InputMaybe<DatetimeFilter>
  id?: InputMaybe<BigIntFilter>
  nodeId?: InputMaybe<IdFilter>
  /** Negates a filter */
  not?: InputMaybe<Session_Scenario_LikesFilter>
  /** Returns true if at least one of its inner filters is true, otherwise returns false */
  or?: InputMaybe<Array<Session_Scenario_LikesFilter>>
  scenario_id?: InputMaybe<IntFilter>
  user_id?: InputMaybe<UuidFilter>
}

export type Session_Scenario_LikesInsertInput = {
  created_at?: InputMaybe<Scalars['Datetime']['input']>
  scenario_id?: InputMaybe<Scalars['Int']['input']>
  user_id?: InputMaybe<Scalars['UUID']['input']>
}

export type Session_Scenario_LikesInsertResponse = {
  __typename?: 'session_scenario_likesInsertResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Session_Scenario_Likes>
}

export type Session_Scenario_LikesOrderBy = {
  created_at?: InputMaybe<OrderByDirection>
  id?: InputMaybe<OrderByDirection>
  scenario_id?: InputMaybe<OrderByDirection>
  user_id?: InputMaybe<OrderByDirection>
}

export type Session_Scenario_LikesUpdateInput = {
  created_at?: InputMaybe<Scalars['Datetime']['input']>
  scenario_id?: InputMaybe<Scalars['Int']['input']>
  user_id?: InputMaybe<Scalars['UUID']['input']>
}

export type Session_Scenario_LikesUpdateResponse = {
  __typename?: 'session_scenario_likesUpdateResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Session_Scenario_Likes>
}

export type Session_Scenarios = Node & {
  __typename?: 'session_scenarios'
  alternate_greetings?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  avatar?: Maybe<Scalars['String']['output']>
  created_at: Scalars['Datetime']['output']
  creator?: Maybe<Scalars['UUID']['output']>
  example_dialogs?: Maybe<Scalars['String']['output']>
  first_message?: Maybe<Scalars['String']['output']>
  id: Scalars['Int']['output']
  likeCount: Scalars['Int']['output']
  name?: Maybe<Scalars['String']['output']>
  /** Globally Unique Record Identifier */
  nodeId: Scalars['ID']['output']
  notes?: Maybe<Scalars['String']['output']>
  scenario?: Maybe<Scalars['String']['output']>
  session_scenario_likesCollection?: Maybe<Session_Scenario_LikesConnection>
  session_topicsCollection?: Maybe<Session_TopicsConnection>
  type: Scenario_Type
  updated_at?: Maybe<Scalars['Datetime']['output']>
  usedCount?: Maybe<Scalars['Int']['output']>
  user?: Maybe<Users>
}

export type Session_ScenariosSession_Scenario_LikesCollectionArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  before?: InputMaybe<Scalars['Cursor']['input']>
  filter?: InputMaybe<Session_Scenario_LikesFilter>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Array<Session_Scenario_LikesOrderBy>>
}

export type Session_ScenariosSession_TopicsCollectionArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  before?: InputMaybe<Scalars['Cursor']['input']>
  filter?: InputMaybe<Session_TopicsFilter>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Array<Session_TopicsOrderBy>>
}

export type Session_ScenariosConnection = {
  __typename?: 'session_scenariosConnection'
  edges: Array<Session_ScenariosEdge>
  pageInfo: PageInfo
  /** The total number of records matching the `filter` criteria */
  totalCount: Scalars['Int']['output']
}

export type Session_ScenariosDeleteResponse = {
  __typename?: 'session_scenariosDeleteResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Session_Scenarios>
}

export type Session_ScenariosEdge = {
  __typename?: 'session_scenariosEdge'
  cursor: Scalars['String']['output']
  node: Session_Scenarios
}

export type Session_ScenariosFilter = {
  /** Returns true only if all its inner filters are true, otherwise returns false */
  and?: InputMaybe<Array<Session_ScenariosFilter>>
  avatar?: InputMaybe<StringFilter>
  created_at?: InputMaybe<DatetimeFilter>
  creator?: InputMaybe<UuidFilter>
  example_dialogs?: InputMaybe<StringFilter>
  first_message?: InputMaybe<StringFilter>
  id?: InputMaybe<IntFilter>
  likeCount?: InputMaybe<IntFilter>
  name?: InputMaybe<StringFilter>
  nodeId?: InputMaybe<IdFilter>
  /** Negates a filter */
  not?: InputMaybe<Session_ScenariosFilter>
  notes?: InputMaybe<StringFilter>
  /** Returns true if at least one of its inner filters is true, otherwise returns false */
  or?: InputMaybe<Array<Session_ScenariosFilter>>
  scenario?: InputMaybe<StringFilter>
  type?: InputMaybe<Scenario_TypeFilter>
  updated_at?: InputMaybe<DatetimeFilter>
  usedCount?: InputMaybe<IntFilter>
}

export type Session_ScenariosInsertInput = {
  alternate_greetings?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  avatar?: InputMaybe<Scalars['String']['input']>
  created_at?: InputMaybe<Scalars['Datetime']['input']>
  creator?: InputMaybe<Scalars['UUID']['input']>
  example_dialogs?: InputMaybe<Scalars['String']['input']>
  first_message?: InputMaybe<Scalars['String']['input']>
  likeCount?: InputMaybe<Scalars['Int']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  notes?: InputMaybe<Scalars['String']['input']>
  scenario?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<Scenario_Type>
  updated_at?: InputMaybe<Scalars['Datetime']['input']>
  usedCount?: InputMaybe<Scalars['Int']['input']>
}

export type Session_ScenariosInsertResponse = {
  __typename?: 'session_scenariosInsertResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Session_Scenarios>
}

export type Session_ScenariosOrderBy = {
  avatar?: InputMaybe<OrderByDirection>
  created_at?: InputMaybe<OrderByDirection>
  creator?: InputMaybe<OrderByDirection>
  example_dialogs?: InputMaybe<OrderByDirection>
  first_message?: InputMaybe<OrderByDirection>
  id?: InputMaybe<OrderByDirection>
  likeCount?: InputMaybe<OrderByDirection>
  name?: InputMaybe<OrderByDirection>
  notes?: InputMaybe<OrderByDirection>
  scenario?: InputMaybe<OrderByDirection>
  type?: InputMaybe<OrderByDirection>
  updated_at?: InputMaybe<OrderByDirection>
  usedCount?: InputMaybe<OrderByDirection>
}

export type Session_ScenariosUpdateInput = {
  alternate_greetings?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  avatar?: InputMaybe<Scalars['String']['input']>
  created_at?: InputMaybe<Scalars['Datetime']['input']>
  creator?: InputMaybe<Scalars['UUID']['input']>
  example_dialogs?: InputMaybe<Scalars['String']['input']>
  first_message?: InputMaybe<Scalars['String']['input']>
  likeCount?: InputMaybe<Scalars['Int']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  notes?: InputMaybe<Scalars['String']['input']>
  scenario?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<Scenario_Type>
  updated_at?: InputMaybe<Scalars['Datetime']['input']>
  usedCount?: InputMaybe<Scalars['Int']['input']>
}

export type Session_ScenariosUpdateResponse = {
  __typename?: 'session_scenariosUpdateResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Session_Scenarios>
}

export type Session_Topic_Interactions = Node & {
  __typename?: 'session_topic_interactions'
  created_at: Scalars['Datetime']['output']
  dislike_count: Scalars['Int']['output']
  id: Scalars['Int']['output']
  like_count: Scalars['Int']['output']
  /** Globally Unique Record Identifier */
  nodeId: Scalars['ID']['output']
  status: Topic_Interaction_Type
  topic: Session_Topics
  updated_at: Scalars['Datetime']['output']
  user: Users
  user_id: Scalars['UUID']['output']
}

export type Session_Topic_InteractionsConnection = {
  __typename?: 'session_topic_interactionsConnection'
  edges: Array<Session_Topic_InteractionsEdge>
  pageInfo: PageInfo
  /** The total number of records matching the `filter` criteria */
  totalCount: Scalars['Int']['output']
}

export type Session_Topic_InteractionsDeleteResponse = {
  __typename?: 'session_topic_interactionsDeleteResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Session_Topic_Interactions>
}

export type Session_Topic_InteractionsEdge = {
  __typename?: 'session_topic_interactionsEdge'
  cursor: Scalars['String']['output']
  node: Session_Topic_Interactions
}

export type Session_Topic_InteractionsFilter = {
  /** Returns true only if all its inner filters are true, otherwise returns false */
  and?: InputMaybe<Array<Session_Topic_InteractionsFilter>>
  created_at?: InputMaybe<DatetimeFilter>
  dislike_count?: InputMaybe<IntFilter>
  id?: InputMaybe<IntFilter>
  like_count?: InputMaybe<IntFilter>
  nodeId?: InputMaybe<IdFilter>
  /** Negates a filter */
  not?: InputMaybe<Session_Topic_InteractionsFilter>
  /** Returns true if at least one of its inner filters is true, otherwise returns false */
  or?: InputMaybe<Array<Session_Topic_InteractionsFilter>>
  status?: InputMaybe<Topic_Interaction_TypeFilter>
  updated_at?: InputMaybe<DatetimeFilter>
  user_id?: InputMaybe<UuidFilter>
}

export type Session_Topic_InteractionsInsertInput = {
  created_at?: InputMaybe<Scalars['Datetime']['input']>
  dislike_count?: InputMaybe<Scalars['Int']['input']>
  like_count?: InputMaybe<Scalars['Int']['input']>
  status?: InputMaybe<Topic_Interaction_Type>
  updated_at?: InputMaybe<Scalars['Datetime']['input']>
  user_id?: InputMaybe<Scalars['UUID']['input']>
}

export type Session_Topic_InteractionsInsertResponse = {
  __typename?: 'session_topic_interactionsInsertResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Session_Topic_Interactions>
}

export type Session_Topic_InteractionsOrderBy = {
  created_at?: InputMaybe<OrderByDirection>
  dislike_count?: InputMaybe<OrderByDirection>
  id?: InputMaybe<OrderByDirection>
  like_count?: InputMaybe<OrderByDirection>
  status?: InputMaybe<OrderByDirection>
  updated_at?: InputMaybe<OrderByDirection>
  user_id?: InputMaybe<OrderByDirection>
}

export type Session_Topic_InteractionsUpdateInput = {
  created_at?: InputMaybe<Scalars['Datetime']['input']>
  dislike_count?: InputMaybe<Scalars['Int']['input']>
  like_count?: InputMaybe<Scalars['Int']['input']>
  status?: InputMaybe<Topic_Interaction_Type>
  updated_at?: InputMaybe<Scalars['Datetime']['input']>
  user_id?: InputMaybe<Scalars['UUID']['input']>
}

export type Session_Topic_InteractionsUpdateResponse = {
  __typename?: 'session_topic_interactionsUpdateResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Session_Topic_Interactions>
}

export type Session_Topics = Node & {
  __typename?: 'session_topics'
  created_at: Scalars['Datetime']['output']
  id: Scalars['Int']['output']
  language?: Maybe<Scalars['String']['output']>
  mode?: Maybe<Topic_Mode>
  /** Globally Unique Record Identifier */
  nodeId: Scalars['ID']['output']
  scenario: Session_Scenarios
  scenario_id: Scalars['BigInt']['output']
  session: Sessions
  session_id: Scalars['UUID']['output']
  session_message_interactionsCollection?: Maybe<Session_Message_InteractionsConnection>
  session_topic_interactions: Session_Topic_Interactions
  title: Scalars['String']['output']
  updated_at: Scalars['Datetime']['output']
  user_id: Scalars['UUID']['output']
}

export type Session_TopicsSession_Message_InteractionsCollectionArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  before?: InputMaybe<Scalars['Cursor']['input']>
  filter?: InputMaybe<Session_Message_InteractionsFilter>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Array<Session_Message_InteractionsOrderBy>>
}

export type Session_TopicsConnection = {
  __typename?: 'session_topicsConnection'
  edges: Array<Session_TopicsEdge>
  pageInfo: PageInfo
  /** The total number of records matching the `filter` criteria */
  totalCount: Scalars['Int']['output']
}

export type Session_TopicsDeleteResponse = {
  __typename?: 'session_topicsDeleteResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Session_Topics>
}

export type Session_TopicsEdge = {
  __typename?: 'session_topicsEdge'
  cursor: Scalars['String']['output']
  node: Session_Topics
}

export type Session_TopicsFilter = {
  /** Returns true only if all its inner filters are true, otherwise returns false */
  and?: InputMaybe<Array<Session_TopicsFilter>>
  created_at?: InputMaybe<DatetimeFilter>
  id?: InputMaybe<IntFilter>
  language?: InputMaybe<StringFilter>
  mode?: InputMaybe<Topic_ModeFilter>
  nodeId?: InputMaybe<IdFilter>
  /** Negates a filter */
  not?: InputMaybe<Session_TopicsFilter>
  /** Returns true if at least one of its inner filters is true, otherwise returns false */
  or?: InputMaybe<Array<Session_TopicsFilter>>
  scenario_id?: InputMaybe<BigIntFilter>
  session_id?: InputMaybe<UuidFilter>
  title?: InputMaybe<StringFilter>
  updated_at?: InputMaybe<DatetimeFilter>
  user_id?: InputMaybe<UuidFilter>
}

export type Session_TopicsInsertInput = {
  created_at?: InputMaybe<Scalars['Datetime']['input']>
  language?: InputMaybe<Scalars['String']['input']>
  mode?: InputMaybe<Topic_Mode>
  scenario_id?: InputMaybe<Scalars['BigInt']['input']>
  session_id?: InputMaybe<Scalars['UUID']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['Datetime']['input']>
  user_id?: InputMaybe<Scalars['UUID']['input']>
}

export type Session_TopicsInsertResponse = {
  __typename?: 'session_topicsInsertResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Session_Topics>
}

export type Session_TopicsOrderBy = {
  created_at?: InputMaybe<OrderByDirection>
  id?: InputMaybe<OrderByDirection>
  language?: InputMaybe<OrderByDirection>
  mode?: InputMaybe<OrderByDirection>
  scenario_id?: InputMaybe<OrderByDirection>
  session_id?: InputMaybe<OrderByDirection>
  title?: InputMaybe<OrderByDirection>
  updated_at?: InputMaybe<OrderByDirection>
  user_id?: InputMaybe<OrderByDirection>
}

export type Session_TopicsUpdateInput = {
  created_at?: InputMaybe<Scalars['Datetime']['input']>
  language?: InputMaybe<Scalars['String']['input']>
  mode?: InputMaybe<Topic_Mode>
  scenario_id?: InputMaybe<Scalars['BigInt']['input']>
  session_id?: InputMaybe<Scalars['UUID']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['Datetime']['input']>
  user_id?: InputMaybe<Scalars['UUID']['input']>
}

export type Session_TopicsUpdateResponse = {
  __typename?: 'session_topicsUpdateResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Session_Topics>
}

export type Sessions = Node & {
  __typename?: 'sessions'
  created_at: Scalars['Datetime']['output']
  id: Scalars['UUID']['output']
  model: Models
  model_id: Scalars['UUID']['output']
  /** Globally Unique Record Identifier */
  nodeId: Scalars['ID']['output']
  session_topicsCollection?: Maybe<Session_TopicsConnection>
  updated_at: Scalars['Datetime']['output']
  user: Users
  user_id: Scalars['UUID']['output']
}

export type SessionsSession_TopicsCollectionArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  before?: InputMaybe<Scalars['Cursor']['input']>
  filter?: InputMaybe<Session_TopicsFilter>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Array<Session_TopicsOrderBy>>
}

export type SessionsConnection = {
  __typename?: 'sessionsConnection'
  edges: Array<SessionsEdge>
  pageInfo: PageInfo
}

export type SessionsDeleteResponse = {
  __typename?: 'sessionsDeleteResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Sessions>
}

export type SessionsEdge = {
  __typename?: 'sessionsEdge'
  cursor: Scalars['String']['output']
  node: Sessions
}

export type SessionsFilter = {
  /** Returns true only if all its inner filters are true, otherwise returns false */
  and?: InputMaybe<Array<SessionsFilter>>
  created_at?: InputMaybe<DatetimeFilter>
  id?: InputMaybe<UuidFilter>
  model_id?: InputMaybe<UuidFilter>
  nodeId?: InputMaybe<IdFilter>
  /** Negates a filter */
  not?: InputMaybe<SessionsFilter>
  /** Returns true if at least one of its inner filters is true, otherwise returns false */
  or?: InputMaybe<Array<SessionsFilter>>
  updated_at?: InputMaybe<DatetimeFilter>
  user_id?: InputMaybe<UuidFilter>
}

export type SessionsInsertInput = {
  created_at?: InputMaybe<Scalars['Datetime']['input']>
  id?: InputMaybe<Scalars['UUID']['input']>
  model_id?: InputMaybe<Scalars['UUID']['input']>
  updated_at?: InputMaybe<Scalars['Datetime']['input']>
  user_id?: InputMaybe<Scalars['UUID']['input']>
}

export type SessionsInsertResponse = {
  __typename?: 'sessionsInsertResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Sessions>
}

export type SessionsOrderBy = {
  created_at?: InputMaybe<OrderByDirection>
  id?: InputMaybe<OrderByDirection>
  model_id?: InputMaybe<OrderByDirection>
  updated_at?: InputMaybe<OrderByDirection>
  user_id?: InputMaybe<OrderByDirection>
}

export type SessionsUpdateInput = {
  created_at?: InputMaybe<Scalars['Datetime']['input']>
  id?: InputMaybe<Scalars['UUID']['input']>
  model_id?: InputMaybe<Scalars['UUID']['input']>
  updated_at?: InputMaybe<Scalars['Datetime']['input']>
  user_id?: InputMaybe<Scalars['UUID']['input']>
}

export type SessionsUpdateResponse = {
  __typename?: 'sessionsUpdateResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Sessions>
}

export enum Subscription_Status {
  Active = 'active',
  Canceled = 'canceled',
  Incomplete = 'incomplete',
  IncompleteExpired = 'incomplete_expired',
  PastDue = 'past_due',
  Paused = 'paused',
  Trialing = 'trialing',
  Unpaid = 'unpaid',
}

/** Boolean expression comparing fields on type "subscription_status" */
export type Subscription_StatusFilter = {
  eq?: InputMaybe<Subscription_Status>
  in?: InputMaybe<Array<Subscription_Status>>
  is?: InputMaybe<FilterIs>
  neq?: InputMaybe<Subscription_Status>
}

export enum Subscription_Tier {
  Free = 'free',
  Plus = 'plus',
  Premium = 'premium',
  Pro = 'pro',
}

/** Boolean expression comparing fields on type "subscription_tier" */
export type Subscription_TierFilter = {
  eq?: InputMaybe<Subscription_Tier>
  in?: InputMaybe<Array<Subscription_Tier>>
  is?: InputMaybe<FilterIs>
  neq?: InputMaybe<Subscription_Tier>
}

export type Subscriptions = Node & {
  __typename?: 'subscriptions'
  cancel_at?: Maybe<Scalars['Datetime']['output']>
  cancel_at_period_end?: Maybe<Scalars['Boolean']['output']>
  canceled_at?: Maybe<Scalars['Datetime']['output']>
  created?: Maybe<Scalars['Datetime']['output']>
  current_period_end?: Maybe<Scalars['Datetime']['output']>
  current_period_start?: Maybe<Scalars['Datetime']['output']>
  customer_id?: Maybe<Scalars['String']['output']>
  ended_at?: Maybe<Scalars['Datetime']['output']>
  id: Scalars['String']['output']
  /** Globally Unique Record Identifier */
  nodeId: Scalars['ID']['output']
  paddle_customer_id?: Maybe<Scalars['String']['output']>
  price?: Maybe<Prices>
  price_id?: Maybe<Scalars['String']['output']>
  product?: Maybe<Products>
  product_id?: Maybe<Scalars['String']['output']>
  quantity?: Maybe<Scalars['Int']['output']>
  status?: Maybe<Subscription_Status>
  trial_end?: Maybe<Scalars['Datetime']['output']>
  trial_start?: Maybe<Scalars['Datetime']['output']>
  user?: Maybe<Users>
  userId?: Maybe<Scalars['UUID']['output']>
}

export type SubscriptionsConnection = {
  __typename?: 'subscriptionsConnection'
  edges: Array<SubscriptionsEdge>
  pageInfo: PageInfo
}

export type SubscriptionsDeleteResponse = {
  __typename?: 'subscriptionsDeleteResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Subscriptions>
}

export type SubscriptionsEdge = {
  __typename?: 'subscriptionsEdge'
  cursor: Scalars['String']['output']
  node: Subscriptions
}

export type SubscriptionsFilter = {
  /** Returns true only if all its inner filters are true, otherwise returns false */
  and?: InputMaybe<Array<SubscriptionsFilter>>
  cancel_at?: InputMaybe<DatetimeFilter>
  cancel_at_period_end?: InputMaybe<BooleanFilter>
  canceled_at?: InputMaybe<DatetimeFilter>
  created?: InputMaybe<DatetimeFilter>
  current_period_end?: InputMaybe<DatetimeFilter>
  current_period_start?: InputMaybe<DatetimeFilter>
  customer_id?: InputMaybe<StringFilter>
  ended_at?: InputMaybe<DatetimeFilter>
  id?: InputMaybe<StringFilter>
  nodeId?: InputMaybe<IdFilter>
  /** Negates a filter */
  not?: InputMaybe<SubscriptionsFilter>
  /** Returns true if at least one of its inner filters is true, otherwise returns false */
  or?: InputMaybe<Array<SubscriptionsFilter>>
  paddle_customer_id?: InputMaybe<StringFilter>
  price_id?: InputMaybe<StringFilter>
  product_id?: InputMaybe<StringFilter>
  quantity?: InputMaybe<IntFilter>
  status?: InputMaybe<Subscription_StatusFilter>
  trial_end?: InputMaybe<DatetimeFilter>
  trial_start?: InputMaybe<DatetimeFilter>
  userId?: InputMaybe<UuidFilter>
}

export type SubscriptionsInsertInput = {
  cancel_at?: InputMaybe<Scalars['Datetime']['input']>
  cancel_at_period_end?: InputMaybe<Scalars['Boolean']['input']>
  canceled_at?: InputMaybe<Scalars['Datetime']['input']>
  created?: InputMaybe<Scalars['Datetime']['input']>
  current_period_end?: InputMaybe<Scalars['Datetime']['input']>
  current_period_start?: InputMaybe<Scalars['Datetime']['input']>
  customer_id?: InputMaybe<Scalars['String']['input']>
  ended_at?: InputMaybe<Scalars['Datetime']['input']>
  id?: InputMaybe<Scalars['String']['input']>
  paddle_customer_id?: InputMaybe<Scalars['String']['input']>
  price_id?: InputMaybe<Scalars['String']['input']>
  product_id?: InputMaybe<Scalars['String']['input']>
  quantity?: InputMaybe<Scalars['Int']['input']>
  status?: InputMaybe<Subscription_Status>
  trial_end?: InputMaybe<Scalars['Datetime']['input']>
  trial_start?: InputMaybe<Scalars['Datetime']['input']>
  userId?: InputMaybe<Scalars['UUID']['input']>
}

export type SubscriptionsInsertResponse = {
  __typename?: 'subscriptionsInsertResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Subscriptions>
}

export type SubscriptionsOrderBy = {
  cancel_at?: InputMaybe<OrderByDirection>
  cancel_at_period_end?: InputMaybe<OrderByDirection>
  canceled_at?: InputMaybe<OrderByDirection>
  created?: InputMaybe<OrderByDirection>
  current_period_end?: InputMaybe<OrderByDirection>
  current_period_start?: InputMaybe<OrderByDirection>
  customer_id?: InputMaybe<OrderByDirection>
  ended_at?: InputMaybe<OrderByDirection>
  id?: InputMaybe<OrderByDirection>
  paddle_customer_id?: InputMaybe<OrderByDirection>
  price_id?: InputMaybe<OrderByDirection>
  product_id?: InputMaybe<OrderByDirection>
  quantity?: InputMaybe<OrderByDirection>
  status?: InputMaybe<OrderByDirection>
  trial_end?: InputMaybe<OrderByDirection>
  trial_start?: InputMaybe<OrderByDirection>
  userId?: InputMaybe<OrderByDirection>
}

export type SubscriptionsUpdateInput = {
  cancel_at?: InputMaybe<Scalars['Datetime']['input']>
  cancel_at_period_end?: InputMaybe<Scalars['Boolean']['input']>
  canceled_at?: InputMaybe<Scalars['Datetime']['input']>
  created?: InputMaybe<Scalars['Datetime']['input']>
  current_period_end?: InputMaybe<Scalars['Datetime']['input']>
  current_period_start?: InputMaybe<Scalars['Datetime']['input']>
  customer_id?: InputMaybe<Scalars['String']['input']>
  ended_at?: InputMaybe<Scalars['Datetime']['input']>
  id?: InputMaybe<Scalars['String']['input']>
  paddle_customer_id?: InputMaybe<Scalars['String']['input']>
  price_id?: InputMaybe<Scalars['String']['input']>
  product_id?: InputMaybe<Scalars['String']['input']>
  quantity?: InputMaybe<Scalars['Int']['input']>
  status?: InputMaybe<Subscription_Status>
  trial_end?: InputMaybe<Scalars['Datetime']['input']>
  trial_start?: InputMaybe<Scalars['Datetime']['input']>
  userId?: InputMaybe<Scalars['UUID']['input']>
}

export type SubscriptionsUpdateResponse = {
  __typename?: 'subscriptionsUpdateResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Subscriptions>
}

export enum Task_Status {
  Completed = 'completed',
  Failed = 'failed',
  Pending = 'pending',
  Timeout = 'timeout',
}

/** Boolean expression comparing fields on type "task_status" */
export type Task_StatusFilter = {
  eq?: InputMaybe<Task_Status>
  in?: InputMaybe<Array<Task_Status>>
  is?: InputMaybe<FilterIs>
  neq?: InputMaybe<Task_Status>
}

export enum Task_Type {
  ChatImage = 'chat_image',
  ChatText = 'chat_text',
  ExtractTag = 'extract_tag',
  MakeModel = 'make_model',
  NewModel = 'new_model',
  Tag2Img = 'tag2Img',
}

/** Boolean expression comparing fields on type "task_type" */
export type Task_TypeFilter = {
  eq?: InputMaybe<Task_Type>
  in?: InputMaybe<Array<Task_Type>>
  is?: InputMaybe<FilterIs>
  neq?: InputMaybe<Task_Type>
}

export enum Topic_Interaction_Type {
  Fixed = 'fixed',
  Ignore = 'ignore',
  InProgress = 'in_progress',
  Todo = 'todo',
}

/** Boolean expression comparing fields on type "topic_interaction_type" */
export type Topic_Interaction_TypeFilter = {
  eq?: InputMaybe<Topic_Interaction_Type>
  in?: InputMaybe<Array<Topic_Interaction_Type>>
  is?: InputMaybe<FilterIs>
  neq?: InputMaybe<Topic_Interaction_Type>
}

export enum Topic_Mode {
  Dialog = 'dialog',
  LongStory = 'long_story',
  Story = 'story',
}

/** Boolean expression comparing fields on type "topic_mode" */
export type Topic_ModeFilter = {
  eq?: InputMaybe<Topic_Mode>
  in?: InputMaybe<Array<Topic_Mode>>
  is?: InputMaybe<FilterIs>
  neq?: InputMaybe<Topic_Mode>
}

export type User_Extract_Tag = Node & {
  __typename?: 'user_extract_tag'
  created_at: Scalars['Datetime']['output']
  extract_tag_id: Scalars['BigInt']['output']
  id: Scalars['BigInt']['output']
  /** Globally Unique Record Identifier */
  nodeId: Scalars['ID']['output']
  user_id: Scalars['UUID']['output']
}

export type User_Extract_TagConnection = {
  __typename?: 'user_extract_tagConnection'
  edges: Array<User_Extract_TagEdge>
  pageInfo: PageInfo
}

export type User_Extract_TagDeleteResponse = {
  __typename?: 'user_extract_tagDeleteResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<User_Extract_Tag>
}

export type User_Extract_TagEdge = {
  __typename?: 'user_extract_tagEdge'
  cursor: Scalars['String']['output']
  node: User_Extract_Tag
}

export type User_Extract_TagFilter = {
  /** Returns true only if all its inner filters are true, otherwise returns false */
  and?: InputMaybe<Array<User_Extract_TagFilter>>
  created_at?: InputMaybe<DatetimeFilter>
  extract_tag_id?: InputMaybe<BigIntFilter>
  id?: InputMaybe<BigIntFilter>
  nodeId?: InputMaybe<IdFilter>
  /** Negates a filter */
  not?: InputMaybe<User_Extract_TagFilter>
  /** Returns true if at least one of its inner filters is true, otherwise returns false */
  or?: InputMaybe<Array<User_Extract_TagFilter>>
  user_id?: InputMaybe<UuidFilter>
}

export type User_Extract_TagInsertInput = {
  created_at?: InputMaybe<Scalars['Datetime']['input']>
  extract_tag_id?: InputMaybe<Scalars['BigInt']['input']>
  user_id?: InputMaybe<Scalars['UUID']['input']>
}

export type User_Extract_TagInsertResponse = {
  __typename?: 'user_extract_tagInsertResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<User_Extract_Tag>
}

export type User_Extract_TagOrderBy = {
  created_at?: InputMaybe<OrderByDirection>
  extract_tag_id?: InputMaybe<OrderByDirection>
  id?: InputMaybe<OrderByDirection>
  user_id?: InputMaybe<OrderByDirection>
}

export type User_Extract_TagUpdateInput = {
  created_at?: InputMaybe<Scalars['Datetime']['input']>
  extract_tag_id?: InputMaybe<Scalars['BigInt']['input']>
  user_id?: InputMaybe<Scalars['UUID']['input']>
}

export type User_Extract_TagUpdateResponse = {
  __typename?: 'user_extract_tagUpdateResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<User_Extract_Tag>
}

export type User_Referrers = Node & {
  __typename?: 'user_referrers'
  created_at?: Maybe<Scalars['Datetime']['output']>
  inviterId?: Maybe<Scalars['String']['output']>
  /** Globally Unique Record Identifier */
  nodeId: Scalars['ID']['output']
  referredCount: Scalars['Int']['output']
  referrerId: Scalars['String']['output']
  updated_at?: Maybe<Scalars['Datetime']['output']>
  userId: Scalars['UUID']['output']
}

export type User_ReferrersConnection = {
  __typename?: 'user_referrersConnection'
  edges: Array<User_ReferrersEdge>
  pageInfo: PageInfo
}

export type User_ReferrersDeleteResponse = {
  __typename?: 'user_referrersDeleteResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<User_Referrers>
}

export type User_ReferrersEdge = {
  __typename?: 'user_referrersEdge'
  cursor: Scalars['String']['output']
  node: User_Referrers
}

export type User_ReferrersFilter = {
  /** Returns true only if all its inner filters are true, otherwise returns false */
  and?: InputMaybe<Array<User_ReferrersFilter>>
  created_at?: InputMaybe<DatetimeFilter>
  inviterId?: InputMaybe<StringFilter>
  nodeId?: InputMaybe<IdFilter>
  /** Negates a filter */
  not?: InputMaybe<User_ReferrersFilter>
  /** Returns true if at least one of its inner filters is true, otherwise returns false */
  or?: InputMaybe<Array<User_ReferrersFilter>>
  referredCount?: InputMaybe<IntFilter>
  referrerId?: InputMaybe<StringFilter>
  updated_at?: InputMaybe<DatetimeFilter>
  userId?: InputMaybe<UuidFilter>
}

export type User_ReferrersInsertInput = {
  created_at?: InputMaybe<Scalars['Datetime']['input']>
  inviterId?: InputMaybe<Scalars['String']['input']>
  referredCount?: InputMaybe<Scalars['Int']['input']>
  referrerId?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['Datetime']['input']>
  userId?: InputMaybe<Scalars['UUID']['input']>
}

export type User_ReferrersInsertResponse = {
  __typename?: 'user_referrersInsertResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<User_Referrers>
}

export type User_ReferrersOrderBy = {
  created_at?: InputMaybe<OrderByDirection>
  inviterId?: InputMaybe<OrderByDirection>
  referredCount?: InputMaybe<OrderByDirection>
  referrerId?: InputMaybe<OrderByDirection>
  updated_at?: InputMaybe<OrderByDirection>
  userId?: InputMaybe<OrderByDirection>
}

export type User_ReferrersUpdateInput = {
  created_at?: InputMaybe<Scalars['Datetime']['input']>
  inviterId?: InputMaybe<Scalars['String']['input']>
  referredCount?: InputMaybe<Scalars['Int']['input']>
  referrerId?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['Datetime']['input']>
  userId?: InputMaybe<Scalars['UUID']['input']>
}

export type User_ReferrersUpdateResponse = {
  __typename?: 'user_referrersUpdateResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<User_Referrers>
}

export type User_Shares = Node & {
  __typename?: 'user_shares'
  amount: Scalars['Int']['output']
  created_at: Scalars['Datetime']['output']
  /** Globally Unique Record Identifier */
  nodeId: Scalars['ID']['output']
  share_date: Scalars['String']['output']
  updated_at: Scalars['Datetime']['output']
  userId: Scalars['UUID']['output']
}

export type User_SharesConnection = {
  __typename?: 'user_sharesConnection'
  edges: Array<User_SharesEdge>
  pageInfo: PageInfo
}

export type User_SharesDeleteResponse = {
  __typename?: 'user_sharesDeleteResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<User_Shares>
}

export type User_SharesEdge = {
  __typename?: 'user_sharesEdge'
  cursor: Scalars['String']['output']
  node: User_Shares
}

export type User_SharesFilter = {
  amount?: InputMaybe<IntFilter>
  /** Returns true only if all its inner filters are true, otherwise returns false */
  and?: InputMaybe<Array<User_SharesFilter>>
  created_at?: InputMaybe<DatetimeFilter>
  nodeId?: InputMaybe<IdFilter>
  /** Negates a filter */
  not?: InputMaybe<User_SharesFilter>
  /** Returns true if at least one of its inner filters is true, otherwise returns false */
  or?: InputMaybe<Array<User_SharesFilter>>
  share_date?: InputMaybe<StringFilter>
  updated_at?: InputMaybe<DatetimeFilter>
  userId?: InputMaybe<UuidFilter>
}

export type User_SharesInsertInput = {
  amount?: InputMaybe<Scalars['Int']['input']>
  created_at?: InputMaybe<Scalars['Datetime']['input']>
  share_date?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['Datetime']['input']>
  userId?: InputMaybe<Scalars['UUID']['input']>
}

export type User_SharesInsertResponse = {
  __typename?: 'user_sharesInsertResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<User_Shares>
}

export type User_SharesOrderBy = {
  amount?: InputMaybe<OrderByDirection>
  created_at?: InputMaybe<OrderByDirection>
  share_date?: InputMaybe<OrderByDirection>
  updated_at?: InputMaybe<OrderByDirection>
  userId?: InputMaybe<OrderByDirection>
}

export type User_SharesUpdateInput = {
  amount?: InputMaybe<Scalars['Int']['input']>
  created_at?: InputMaybe<Scalars['Datetime']['input']>
  share_date?: InputMaybe<Scalars['String']['input']>
  updated_at?: InputMaybe<Scalars['Datetime']['input']>
  userId?: InputMaybe<Scalars['UUID']['input']>
}

export type User_SharesUpdateResponse = {
  __typename?: 'user_sharesUpdateResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<User_Shares>
}

export type User_Subscribers = Node & {
  __typename?: 'user_subscribers'
  created_at?: Maybe<Scalars['Datetime']['output']>
  id: Scalars['BigInt']['output']
  /** Globally Unique Record Identifier */
  nodeId: Scalars['ID']['output']
  subscriber: Users
  subscriber_id: Scalars['UUID']['output']
  user: Users
  user_id: Scalars['UUID']['output']
}

export type User_SubscribersConnection = {
  __typename?: 'user_subscribersConnection'
  edges: Array<User_SubscribersEdge>
  pageInfo: PageInfo
  /** The total number of records matching the `filter` criteria */
  totalCount: Scalars['Int']['output']
}

export type User_SubscribersDeleteResponse = {
  __typename?: 'user_subscribersDeleteResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<User_Subscribers>
}

export type User_SubscribersEdge = {
  __typename?: 'user_subscribersEdge'
  cursor: Scalars['String']['output']
  node: User_Subscribers
}

export type User_SubscribersFilter = {
  /** Returns true only if all its inner filters are true, otherwise returns false */
  and?: InputMaybe<Array<User_SubscribersFilter>>
  created_at?: InputMaybe<DatetimeFilter>
  id?: InputMaybe<BigIntFilter>
  nodeId?: InputMaybe<IdFilter>
  /** Negates a filter */
  not?: InputMaybe<User_SubscribersFilter>
  /** Returns true if at least one of its inner filters is true, otherwise returns false */
  or?: InputMaybe<Array<User_SubscribersFilter>>
  subscriber_id?: InputMaybe<UuidFilter>
  user_id?: InputMaybe<UuidFilter>
}

export type User_SubscribersInsertInput = {
  created_at?: InputMaybe<Scalars['Datetime']['input']>
  subscriber_id?: InputMaybe<Scalars['UUID']['input']>
  user_id?: InputMaybe<Scalars['UUID']['input']>
}

export type User_SubscribersInsertResponse = {
  __typename?: 'user_subscribersInsertResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<User_Subscribers>
}

export type User_SubscribersOrderBy = {
  created_at?: InputMaybe<OrderByDirection>
  id?: InputMaybe<OrderByDirection>
  subscriber_id?: InputMaybe<OrderByDirection>
  user_id?: InputMaybe<OrderByDirection>
}

export type User_SubscribersUpdateInput = {
  created_at?: InputMaybe<Scalars['Datetime']['input']>
  subscriber_id?: InputMaybe<Scalars['UUID']['input']>
  user_id?: InputMaybe<Scalars['UUID']['input']>
}

export type User_SubscribersUpdateResponse = {
  __typename?: 'user_subscribersUpdateResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<User_Subscribers>
}

export type Users = Node & {
  __typename?: 'users'
  created_at?: Maybe<Scalars['Datetime']['output']>
  credits?: Maybe<Credits>
  credits_historyCollection?: Maybe<Credits_HistoryConnection>
  email?: Maybe<Scalars['String']['output']>
  followers?: Maybe<Scalars['BigInt']['output']>
  id: Scalars['UUID']['output']
  invoicesCollection?: Maybe<InvoicesConnection>
  model_subscribersCollection?: Maybe<Model_SubscribersConnection>
  modelsCollection?: Maybe<ModelsConnection>
  /** Globally Unique Record Identifier */
  nodeId: Scalars['ID']['output']
  postsCollection?: Maybe<PostsConnection>
  posts_pendingCollection?: Maybe<Posts_PendingConnection>
  profiles: Profiles
  session_scenario_likesCollection?: Maybe<Session_Scenario_LikesConnection>
  session_scenariosCollection?: Maybe<Session_ScenariosConnection>
  session_topic_interactionsCollection?: Maybe<Session_Topic_InteractionsConnection>
  sessionsCollection?: Maybe<SessionsConnection>
  stripe_customer_id?: Maybe<Scalars['String']['output']>
  subscription_tier: Subscription_Tier
  subscriptionsCollection?: Maybe<SubscriptionsConnection>
  updated_at?: Maybe<Scalars['Datetime']['output']>
  user_subscribersCollection?: Maybe<User_SubscribersConnection>
}

export type UsersCredits_HistoryCollectionArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  before?: InputMaybe<Scalars['Cursor']['input']>
  filter?: InputMaybe<Credits_HistoryFilter>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Array<Credits_HistoryOrderBy>>
}

export type UsersInvoicesCollectionArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  before?: InputMaybe<Scalars['Cursor']['input']>
  filter?: InputMaybe<InvoicesFilter>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Array<InvoicesOrderBy>>
}

export type UsersModel_SubscribersCollectionArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  before?: InputMaybe<Scalars['Cursor']['input']>
  filter?: InputMaybe<Model_SubscribersFilter>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Array<Model_SubscribersOrderBy>>
}

export type UsersModelsCollectionArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  before?: InputMaybe<Scalars['Cursor']['input']>
  filter?: InputMaybe<ModelsFilter>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Array<ModelsOrderBy>>
}

export type UsersPostsCollectionArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  before?: InputMaybe<Scalars['Cursor']['input']>
  filter?: InputMaybe<PostsFilter>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Array<PostsOrderBy>>
}

export type UsersPosts_PendingCollectionArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  before?: InputMaybe<Scalars['Cursor']['input']>
  filter?: InputMaybe<Posts_PendingFilter>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Array<Posts_PendingOrderBy>>
}

export type UsersSession_Scenario_LikesCollectionArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  before?: InputMaybe<Scalars['Cursor']['input']>
  filter?: InputMaybe<Session_Scenario_LikesFilter>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Array<Session_Scenario_LikesOrderBy>>
}

export type UsersSession_ScenariosCollectionArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  before?: InputMaybe<Scalars['Cursor']['input']>
  filter?: InputMaybe<Session_ScenariosFilter>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Array<Session_ScenariosOrderBy>>
}

export type UsersSession_Topic_InteractionsCollectionArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  before?: InputMaybe<Scalars['Cursor']['input']>
  filter?: InputMaybe<Session_Topic_InteractionsFilter>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Array<Session_Topic_InteractionsOrderBy>>
}

export type UsersSessionsCollectionArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  before?: InputMaybe<Scalars['Cursor']['input']>
  filter?: InputMaybe<SessionsFilter>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Array<SessionsOrderBy>>
}

export type UsersSubscriptionsCollectionArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  before?: InputMaybe<Scalars['Cursor']['input']>
  filter?: InputMaybe<SubscriptionsFilter>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Array<SubscriptionsOrderBy>>
}

export type UsersUser_SubscribersCollectionArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>
  before?: InputMaybe<Scalars['Cursor']['input']>
  filter?: InputMaybe<User_SubscribersFilter>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Array<User_SubscribersOrderBy>>
}

export type UsersConnection = {
  __typename?: 'usersConnection'
  edges: Array<UsersEdge>
  pageInfo: PageInfo
  /** The total number of records matching the `filter` criteria */
  totalCount: Scalars['Int']['output']
}

export type UsersDeleteResponse = {
  __typename?: 'usersDeleteResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Users>
}

export type UsersEdge = {
  __typename?: 'usersEdge'
  cursor: Scalars['String']['output']
  node: Users
}

export type UsersFilter = {
  /** Returns true only if all its inner filters are true, otherwise returns false */
  and?: InputMaybe<Array<UsersFilter>>
  created_at?: InputMaybe<DatetimeFilter>
  email?: InputMaybe<StringFilter>
  followers?: InputMaybe<BigIntFilter>
  id?: InputMaybe<UuidFilter>
  nodeId?: InputMaybe<IdFilter>
  /** Negates a filter */
  not?: InputMaybe<UsersFilter>
  /** Returns true if at least one of its inner filters is true, otherwise returns false */
  or?: InputMaybe<Array<UsersFilter>>
  stripe_customer_id?: InputMaybe<StringFilter>
  subscription_tier?: InputMaybe<Subscription_TierFilter>
  updated_at?: InputMaybe<DatetimeFilter>
}

export type UsersInsertInput = {
  created_at?: InputMaybe<Scalars['Datetime']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  followers?: InputMaybe<Scalars['BigInt']['input']>
  id?: InputMaybe<Scalars['UUID']['input']>
  stripe_customer_id?: InputMaybe<Scalars['String']['input']>
  subscription_tier?: InputMaybe<Subscription_Tier>
  updated_at?: InputMaybe<Scalars['Datetime']['input']>
}

export type UsersInsertResponse = {
  __typename?: 'usersInsertResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Users>
}

export type UsersOrderBy = {
  created_at?: InputMaybe<OrderByDirection>
  email?: InputMaybe<OrderByDirection>
  followers?: InputMaybe<OrderByDirection>
  id?: InputMaybe<OrderByDirection>
  stripe_customer_id?: InputMaybe<OrderByDirection>
  subscription_tier?: InputMaybe<OrderByDirection>
  updated_at?: InputMaybe<OrderByDirection>
}

export type UsersUpdateInput = {
  created_at?: InputMaybe<Scalars['Datetime']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  followers?: InputMaybe<Scalars['BigInt']['input']>
  id?: InputMaybe<Scalars['UUID']['input']>
  stripe_customer_id?: InputMaybe<Scalars['String']['input']>
  subscription_tier?: InputMaybe<Subscription_Tier>
  updated_at?: InputMaybe<Scalars['Datetime']['input']>
}

export type UsersUpdateResponse = {
  __typename?: 'usersUpdateResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Users>
}

export type Users_Deleted = Node & {
  __typename?: 'users_deleted'
  created_at?: Maybe<Scalars['Datetime']['output']>
  deleted_at?: Maybe<Scalars['Datetime']['output']>
  email?: Maybe<Scalars['String']['output']>
  id: Scalars['UUID']['output']
  /** Globally Unique Record Identifier */
  nodeId: Scalars['ID']['output']
  stripe_customer_id?: Maybe<Scalars['String']['output']>
  subscription_tier: Subscription_Tier
  updated_at?: Maybe<Scalars['Datetime']['output']>
}

export type Users_DeletedConnection = {
  __typename?: 'users_deletedConnection'
  edges: Array<Users_DeletedEdge>
  pageInfo: PageInfo
}

export type Users_DeletedDeleteResponse = {
  __typename?: 'users_deletedDeleteResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Users_Deleted>
}

export type Users_DeletedEdge = {
  __typename?: 'users_deletedEdge'
  cursor: Scalars['String']['output']
  node: Users_Deleted
}

export type Users_DeletedFilter = {
  /** Returns true only if all its inner filters are true, otherwise returns false */
  and?: InputMaybe<Array<Users_DeletedFilter>>
  created_at?: InputMaybe<DatetimeFilter>
  deleted_at?: InputMaybe<DatetimeFilter>
  email?: InputMaybe<StringFilter>
  id?: InputMaybe<UuidFilter>
  nodeId?: InputMaybe<IdFilter>
  /** Negates a filter */
  not?: InputMaybe<Users_DeletedFilter>
  /** Returns true if at least one of its inner filters is true, otherwise returns false */
  or?: InputMaybe<Array<Users_DeletedFilter>>
  stripe_customer_id?: InputMaybe<StringFilter>
  subscription_tier?: InputMaybe<Subscription_TierFilter>
  updated_at?: InputMaybe<DatetimeFilter>
}

export type Users_DeletedInsertInput = {
  created_at?: InputMaybe<Scalars['Datetime']['input']>
  deleted_at?: InputMaybe<Scalars['Datetime']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['UUID']['input']>
  stripe_customer_id?: InputMaybe<Scalars['String']['input']>
  subscription_tier?: InputMaybe<Subscription_Tier>
  updated_at?: InputMaybe<Scalars['Datetime']['input']>
}

export type Users_DeletedInsertResponse = {
  __typename?: 'users_deletedInsertResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Users_Deleted>
}

export type Users_DeletedOrderBy = {
  created_at?: InputMaybe<OrderByDirection>
  deleted_at?: InputMaybe<OrderByDirection>
  email?: InputMaybe<OrderByDirection>
  id?: InputMaybe<OrderByDirection>
  stripe_customer_id?: InputMaybe<OrderByDirection>
  subscription_tier?: InputMaybe<OrderByDirection>
  updated_at?: InputMaybe<OrderByDirection>
}

export type Users_DeletedUpdateInput = {
  created_at?: InputMaybe<Scalars['Datetime']['input']>
  deleted_at?: InputMaybe<Scalars['Datetime']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['UUID']['input']>
  stripe_customer_id?: InputMaybe<Scalars['String']['input']>
  subscription_tier?: InputMaybe<Subscription_Tier>
  updated_at?: InputMaybe<Scalars['Datetime']['input']>
}

export type Users_DeletedUpdateResponse = {
  __typename?: 'users_deletedUpdateResponse'
  /** Count of the records impacted by the mutation */
  affectedCount: Scalars['Int']['output']
  /** Array of records impacted by the mutation */
  records: Array<Users_Deleted>
}

export type GetAllFeedbackTopicsInAdminQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  userId?: InputMaybe<Scalars['UUID']['input']>
  status?: InputMaybe<Array<Topic_Interaction_Type> | Topic_Interaction_Type>
}>

export type GetAllFeedbackTopicsInAdminQuery = {
  __typename?: 'Query'
  session_topic_interactionsCollection?: {
    __typename?: 'session_topic_interactionsConnection'
    totalCount: number
    edges: Array<{
      __typename?: 'session_topic_interactionsEdge'
      node: {
        __typename?: 'session_topic_interactions'
        id: number
        status: Topic_Interaction_Type
        like_count: number
        dislike_count: number
        user: {
          __typename?: 'users'
          profiles: {
            __typename?: 'profiles'
            id: string
            avatar_url?: string | null
            name?: string | null
          }
        }
        topic: {
          __typename?: 'session_topics'
          id: number
          title: string
          updated_at: any
          scenario: { __typename?: 'session_scenarios'; id: number }
          session: {
            __typename?: 'sessions'
            model: { __typename?: 'models'; id: string; avatar?: string | null }
          }
        }
      }
    }>
    pageInfo: { __typename?: 'PageInfo'; endCursor?: string | null; hasNextPage: boolean }
  } | null
}

export type GetScenarioContentInAdminQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>
}>

export type GetScenarioContentInAdminQuery = {
  __typename?: 'Query'
  session_scenariosCollection?: {
    __typename?: 'session_scenariosConnection'
    edges: Array<{
      __typename?: 'session_scenariosEdge'
      node: {
        __typename?: 'session_scenarios'
        id: number
        first_message?: string | null
        alternate_greetings?: Array<string | null> | null
        example_dialogs?: string | null
        scenario?: string | null
        notes?: string | null
        created_at: any
        updated_at?: any | null
        usedCount?: number | null
        avatar?: string | null
        name?: string | null
        likeCount: number
        type: Scenario_Type
        creator?: string | null
      }
    }>
  } | null
}

export type GetTopicInAdminQueryVariables = Exact<{
  topicId?: InputMaybe<Scalars['Int']['input']>
}>

export type GetTopicInAdminQuery = {
  __typename?: 'Query'
  session_topicsCollection?: {
    __typename?: 'session_topicsConnection'
    totalCount: number
    edges: Array<{
      __typename?: 'session_topicsEdge'
      node: {
        __typename?: 'session_topics'
        id: number
        title: string
        created_at: any
        updated_at: any
        session: {
          __typename?: 'sessions'
          user: {
            __typename?: 'users'
            id: string
            profiles: { __typename?: 'profiles'; name?: string | null; avatar_url?: string | null }
          }
          model: { __typename?: 'models'; id: string; avatar?: string | null }
        }
        scenario: { __typename?: 'session_scenarios'; id: number }
      }
    }>
    pageInfo: { __typename?: 'PageInfo'; endCursor?: string | null; hasNextPage: boolean }
  } | null
}

export type GetAllTopicsInAdminQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  userId?: InputMaybe<Scalars['UUID']['input']>
}>

export type GetAllTopicsInAdminQuery = {
  __typename?: 'Query'
  session_topicsCollection?: {
    __typename?: 'session_topicsConnection'
    totalCount: number
    edges: Array<{
      __typename?: 'session_topicsEdge'
      node: {
        __typename?: 'session_topics'
        id: number
        title: string
        created_at: any
        updated_at: any
        session: {
          __typename?: 'sessions'
          user: {
            __typename?: 'users'
            id: string
            profiles: { __typename?: 'profiles'; name?: string | null; avatar_url?: string | null }
          }
          model: { __typename?: 'models'; id: string; avatar?: string | null }
        }
        scenario: { __typename?: 'session_scenarios'; id: number }
      }
    }>
    pageInfo: { __typename?: 'PageInfo'; endCursor?: string | null; hasNextPage: boolean }
  } | null
}

export type GetTopicMessagesInAdminQueryVariables = Exact<{
  topicId?: InputMaybe<Scalars['BigInt']['input']>
  cursor?: InputMaybe<Scalars['Cursor']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
}>

export type GetTopicMessagesInAdminQuery = {
  __typename?: 'Query'
  session_messagesCollection?: {
    __typename?: 'session_messagesConnection'
    edges: Array<{
      __typename?: 'session_messagesEdge'
      node: {
        __typename?: 'session_messages'
        id: any
        role: Message_Role
        content?: string | null
        files?: any | null
        error?: Message_Error | null
        session_id: string
        topic_id: any
        created_at: any
        updated_at: any
        session_message_interactions: {
          __typename?: 'session_message_interactions'
          type: Message_Interaction_Type
          content?: string | null
        }
      }
    }>
    pageInfo: { __typename?: 'PageInfo'; endCursor?: string | null; hasNextPage: boolean }
  } | null
}

export type DashboardModelInfoQueryVariables = Exact<{
  id?: InputMaybe<Scalars['UUID']['input']>
}>

export type DashboardModelInfoQuery = {
  __typename?: 'Query'
  modelsCollection?: {
    __typename?: 'modelsConnection'
    edges: Array<{
      __typename?: 'modelsEdge'
      node: {
        __typename?: 'models'
        id: string
        avatar?: string | null
        fullbody?: string | null
        ass?: string | null
        penis?: string | null
        creator?: string | null
        labels?: Array<number | null> | null
        private?: boolean | null
        subscribers?: any | null
        templated?: any | null
        created_at: any
        nickname?: string | null
        version: Model_Version
        user?: {
          __typename?: 'users'
          id: string
          profiles: { __typename?: 'profiles'; name?: string | null; avatar_url?: string | null }
        } | null
        postsCollection?: { __typename?: 'postsConnection'; totalCount: number } | null
      }
    }>
  } | null
}

export type DashboardModelListByKeywordQueryVariables = Exact<{
  id?: InputMaybe<Scalars['UUID']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
}>

export type DashboardModelListByKeywordQuery = {
  __typename?: 'Query'
  modelsCollection?: {
    __typename?: 'modelsConnection'
    totalCount: number
    edges: Array<{
      __typename?: 'modelsEdge'
      node: {
        __typename?: 'models'
        id: string
        avatar?: string | null
        creator?: string | null
        labels?: Array<number | null> | null
        private?: boolean | null
        subscribers?: any | null
        templated?: any | null
        created_at: any
        nickname?: string | null
        version: Model_Version
        user?: {
          __typename?: 'users'
          id: string
          profiles: { __typename?: 'profiles'; name?: string | null }
        } | null
      }
    }>
  } | null
}

export type DashboardModelListQueryVariables = Exact<{
  pageSize?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
}>

export type DashboardModelListQuery = {
  __typename?: 'Query'
  modelsCollection?: {
    __typename?: 'modelsConnection'
    totalCount: number
    edges: Array<{
      __typename?: 'modelsEdge'
      node: {
        __typename?: 'models'
        id: string
        avatar?: string | null
        creator?: string | null
        labels?: Array<number | null> | null
        private?: boolean | null
        subscribers?: any | null
        templated?: any | null
        created_at: any
        nickname?: string | null
        version: Model_Version
        user?: {
          __typename?: 'users'
          id: string
          profiles: { __typename?: 'profiles'; name?: string | null }
        } | null
      }
    }>
  } | null
}

export type DashboardModelPostsQueryVariables = Exact<{
  modelId?: InputMaybe<Scalars['UUID']['input']>
  userId?: InputMaybe<Scalars['UUID']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  cursor?: InputMaybe<Scalars['Cursor']['input']>
  includeUser: Scalars['Boolean']['input']
}>

export type DashboardModelPostsQuery = {
  __typename?: 'Query'
  postsCollection?: {
    __typename?: 'postsConnection'
    edges: Array<{
      __typename?: 'postsEdge'
      node: { __typename?: 'posts' } & {
        ' $fragmentRefs'?: { PostMetaData_FragementFragment: PostMetaData_FragementFragment }
      }
    }>
    pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean; endCursor?: string | null }
  } | null
}

export type OnboardingQueryVariables = Exact<{
  from?: InputMaybe<Scalars['Datetime']['input']>
  to?: InputMaybe<Scalars['Datetime']['input']>
}>

export type OnboardingQuery = {
  __typename?: 'Query'
  usersCollection?: { __typename?: 'usersConnection'; totalCount: number } | null
}

export type DashboardUserCreatedModelsQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['UUID']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
}>

export type DashboardUserCreatedModelsQuery = {
  __typename?: 'Query'
  modelsCollection?: {
    __typename?: 'modelsConnection'
    totalCount: number
    edges: Array<{
      __typename?: 'modelsEdge'
      node: {
        __typename?: 'models'
        id: string
        nickname?: string | null
        fullbody?: string | null
        private?: boolean | null
      }
    }>
    pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean; endCursor?: string | null }
  } | null
}

export type DashboardUserCreditsHistoryQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['UUID']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
}>

export type DashboardUserCreditsHistoryQuery = {
  __typename?: 'Query'
  credits_historyCollection?: {
    __typename?: 'credits_historyConnection'
    totalCount: number
    edges: Array<{
      __typename?: 'credits_historyEdge'
      node: {
        __typename?: 'credits_history'
        id: any
        subscription_credits_added?: string | null
        subscription_credits_spent?: string | null
        addon_credits_added?: string | null
        addon_credits_spent?: string | null
        created_at?: any | null
        source: Credit_History_Source
      }
    }>
    pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean; endCursor?: string | null }
  } | null
}

export type DashboardUserInfoByIdQueryVariables = Exact<{
  id?: InputMaybe<Scalars['UUID']['input']>
}>

export type DashboardUserInfoByIdQuery = {
  __typename?: 'Query'
  usersCollection?: {
    __typename?: 'usersConnection'
    edges: Array<{
      __typename?: 'usersEdge'
      node: {
        __typename?: 'users'
        id: string
        created_at?: any | null
        subscription_tier: Subscription_Tier
        profiles: {
          __typename?: 'profiles'
          name?: string | null
          avatar_url?: string | null
          about?: string | null
        }
        postsCollection?: { __typename?: 'postsConnection'; totalCount: number } | null
        credits?: {
          __typename?: 'credits'
          subscription_credits: string
          subscription_credits_used: string
          addon_credits: string
          addon_credits_used: string
        } | null
        model_subscribersCollection?: {
          __typename?: 'model_subscribersConnection'
          totalCount: number
        } | null
        modelsCollection?: { __typename?: 'modelsConnection'; totalCount: number } | null
      }
    }>
  } | null
}

export type UserListQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
}>

export type UserListQuery = {
  __typename?: 'Query'
  profilesCollection?: {
    __typename?: 'profilesConnection'
    totalCount: number
    edges: Array<{
      __typename?: 'profilesEdge'
      node: {
        __typename?: 'profiles'
        id: string
        avatar_url?: string | null
        name?: string | null
        about?: string | null
        created_at?: any | null
        user: {
          __typename?: 'users'
          subscription_tier: Subscription_Tier
          email?: string | null
          credits?: {
            __typename?: 'credits'
            subscription_credits: string
            subscription_credits_used: string
            addon_credits: string
            addon_credits_used: string
          } | null
        }
      }
    }>
    pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean }
  } | null
}

export type UserListByKeywordQueryVariables = Exact<{
  id?: InputMaybe<Scalars['UUID']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
}>

export type UserListByKeywordQuery = {
  __typename?: 'Query'
  profilesCollection?: {
    __typename?: 'profilesConnection'
    totalCount: number
    edges: Array<{
      __typename?: 'profilesEdge'
      node: {
        __typename?: 'profiles'
        id: string
        avatar_url?: string | null
        name?: string | null
        about?: string | null
        created_at?: any | null
        user: {
          __typename?: 'users'
          subscription_tier: Subscription_Tier
          email?: string | null
          credits?: {
            __typename?: 'credits'
            subscription_credits: string
            subscription_credits_used: string
            addon_credits: string
            addon_credits_used: string
          } | null
        }
      }
    }>
    pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean }
  } | null
}

export type UserListByEmailQueryVariables = Exact<{
  email?: InputMaybe<Scalars['String']['input']>
  tier: Array<Subscription_Tier> | Subscription_Tier
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
}>

export type UserListByEmailQuery = {
  __typename?: 'Query'
  usersCollection?: {
    __typename?: 'usersConnection'
    totalCount: number
    edges: Array<{
      __typename?: 'usersEdge'
      node: {
        __typename?: 'users'
        profiles: {
          __typename?: 'profiles'
          id: string
          avatar_url?: string | null
          name?: string | null
          about?: string | null
          created_at?: any | null
          user: {
            __typename?: 'users'
            subscription_tier: Subscription_Tier
            email?: string | null
            credits?: {
              __typename?: 'credits'
              subscription_credits: string
              subscription_credits_used: string
              addon_credits: string
              addon_credits_used: string
            } | null
          }
        }
      }
    }>
    pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean }
  } | null
}

export type DashboardUserPostsQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['UUID']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  cursor?: InputMaybe<Scalars['Cursor']['input']>
  includeUser: Scalars['Boolean']['input']
}>

export type DashboardUserPostsQuery = {
  __typename?: 'Query'
  postsCollection?: {
    __typename?: 'postsConnection'
    edges: Array<{
      __typename?: 'postsEdge'
      node: { __typename?: 'posts' } & {
        ' $fragmentRefs'?: { PostMetaData_FragementFragment: PostMetaData_FragementFragment }
      }
    }>
    pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean; endCursor?: string | null }
  } | null
}

export type SessionDetail_FragementFragment = {
  __typename?: 'sessions'
  id: string
  user_id: string
  model_id: string
  created_at: any
  updated_at: any
  model: {
    __typename?: 'models'
    id: string
    nickname?: string | null
    avatar?: string | null
    fullbody?: string | null
    penis?: string | null
    ass?: string | null
    about?: string | null
    creator?: string | null
    labels?: Array<number | null> | null
    private?: boolean | null
    subscribers?: any | null
    templated?: any | null
    version: Model_Version
    personalities?: Array<string | null> | null
    hobbies?: Array<string | null> | null
    occupation?: string | null
    age?: number | null
  }
} & { ' $fragmentName'?: 'SessionDetail_FragementFragment' }

export type GetSessionDetailByIdQueryVariables = Exact<{
  id?: InputMaybe<Scalars['UUID']['input']>
}>

export type GetSessionDetailByIdQuery = {
  __typename?: 'Query'
  sessionsCollection?: {
    __typename?: 'sessionsConnection'
    edges: Array<{
      __typename?: 'sessionsEdge'
      node: { __typename?: 'sessions' } & {
        ' $fragmentRefs'?: { SessionDetail_FragementFragment: SessionDetail_FragementFragment }
      }
    }>
  } | null
}

export type GetUserSessionDetailsQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['UUID']['input']>
  after?: InputMaybe<Scalars['Cursor']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
}>

export type GetUserSessionDetailsQuery = {
  __typename?: 'Query'
  sessionsCollection?: {
    __typename?: 'sessionsConnection'
    edges: Array<{
      __typename?: 'sessionsEdge'
      node: { __typename?: 'sessions' } & {
        ' $fragmentRefs'?: { SessionDetail_FragementFragment: SessionDetail_FragementFragment }
      }
    }>
    pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean; endCursor?: string | null }
  } | null
}

export type GetSessionScenariosQueryVariables = Exact<{
  cursor?: InputMaybe<Scalars['Cursor']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<Array<Session_ScenariosOrderBy> | Session_ScenariosOrderBy>
  userId?: InputMaybe<Scalars['UUID']['input']>
  filter?: InputMaybe<Session_ScenariosFilter>
}>

export type GetSessionScenariosQuery = {
  __typename?: 'Query'
  session_scenariosCollection?: {
    __typename?: 'session_scenariosConnection'
    edges: Array<{
      __typename?: 'session_scenariosEdge'
      node: {
        __typename?: 'session_scenarios'
        id: number
        name?: string | null
        first_message?: string | null
        alternate_greetings?: Array<string | null> | null
        example_dialogs?: string | null
        scenario?: string | null
        notes?: string | null
        usedCount?: number | null
        likeCount: number
        avatar?: string | null
        created_at: any
        updated_at?: any | null
        type: Scenario_Type
        creator?: string | null
        session_scenario_likesCollection?: {
          __typename?: 'session_scenario_likesConnection'
          edges: Array<{
            __typename?: 'session_scenario_likesEdge'
            node: { __typename?: 'session_scenario_likes'; id: any }
          }>
        } | null
        user?: {
          __typename?: 'users'
          profiles: { __typename?: 'profiles'; name?: string | null }
        } | null
      }
    }>
    pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean; endCursor?: string | null }
  } | null
}

export type GetUserLikedScenariosQueryVariables = Exact<{
  cursor?: InputMaybe<Scalars['Cursor']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  userId?: InputMaybe<Scalars['UUID']['input']>
}>

export type GetUserLikedScenariosQuery = {
  __typename?: 'Query'
  session_scenario_likesCollection?: {
    __typename?: 'session_scenario_likesConnection'
    edges: Array<{
      __typename?: 'session_scenario_likesEdge'
      node: {
        __typename?: 'session_scenario_likes'
        scenario: {
          __typename?: 'session_scenarios'
          id: number
          name?: string | null
          first_message?: string | null
          alternate_greetings?: Array<string | null> | null
          example_dialogs?: string | null
          scenario?: string | null
          notes?: string | null
          usedCount?: number | null
          likeCount: number
          avatar?: string | null
          created_at: any
          updated_at?: any | null
          type: Scenario_Type
          creator?: string | null
          session_scenario_likesCollection?: {
            __typename?: 'session_scenario_likesConnection'
            edges: Array<{
              __typename?: 'session_scenario_likesEdge'
              node: { __typename?: 'session_scenario_likes'; id: any }
            }>
          } | null
          user?: {
            __typename?: 'users'
            profiles: { __typename?: 'profiles'; name?: string | null }
          } | null
        }
      }
    }>
    pageInfo: { __typename?: 'PageInfo'; endCursor?: string | null; hasNextPage: boolean }
  } | null
}

export type GetSessionTopicsQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['UUID']['input']>
  sessionId?: InputMaybe<Scalars['UUID']['input']>
  cursor?: InputMaybe<Scalars['Cursor']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
}>

export type GetSessionTopicsQuery = {
  __typename?: 'Query'
  session_topicsCollection?: {
    __typename?: 'session_topicsConnection'
    edges: Array<{
      __typename?: 'session_topicsEdge'
      node: {
        __typename?: 'session_topics'
        id: number
        session_id: string
        title: string
        created_at: any
        updated_at: any
        scenario_id: any
        user_id: string
        mode?: Topic_Mode | null
        language?: string | null
        scenario: { __typename?: 'session_scenarios'; scenario?: string | null }
      }
    }>
    pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean; endCursor?: string | null }
  } | null
}

export type GetTopicMessagesQueryVariables = Exact<{
  topicId?: InputMaybe<Scalars['BigInt']['input']>
  cursor?: InputMaybe<Scalars['Cursor']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
}>

export type GetTopicMessagesQuery = {
  __typename?: 'Query'
  session_messagesCollection?: {
    __typename?: 'session_messagesConnection'
    edges: Array<{
      __typename?: 'session_messagesEdge'
      node: {
        __typename?: 'session_messages'
        id: any
        role: Message_Role
        content?: string | null
        files?: any | null
        error?: Message_Error | null
        session_id: string
        topic_id: any
        type: Message_Type
        created_at: any
        updated_at: any
        session_message_interactions: {
          __typename?: 'session_message_interactions'
          type: Message_Interaction_Type
        }
      }
    }>
    pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean; endCursor?: string | null }
  } | null
}

export type GetSessionTopicDetailByIdQueryVariables = Exact<{
  topicId?: InputMaybe<Scalars['Int']['input']>
  userId?: InputMaybe<Scalars['UUID']['input']>
}>

export type GetSessionTopicDetailByIdQuery = {
  __typename?: 'Query'
  session_topicsCollection?: {
    __typename?: 'session_topicsConnection'
    edges: Array<{
      __typename?: 'session_topicsEdge'
      node: {
        __typename?: 'session_topics'
        id: number
        session_id: string
        title: string
        mode?: Topic_Mode | null
        created_at: any
        updated_at: any
        scenario: {
          __typename?: 'session_scenarios'
          id: number
          first_message?: string | null
          alternate_greetings?: Array<string | null> | null
          example_dialogs?: string | null
          scenario?: string | null
          notes?: string | null
          created_at: any
          updated_at?: any | null
          usedCount?: number | null
          avatar?: string | null
          name?: string | null
          type: Scenario_Type
          creator?: string | null
        }
      }
    }>
  } | null
}

export type CommentMetaDataFragment = {
  __typename?: 'comments'
  id: string
  topic: string
  comment?: string | null
  user_id: string
  parent_id?: string | null
  created_at?: any | null
  likes?: number | null
  replies?: number | null
  comment_likesCollection?: {
    __typename?: 'comment_likesConnection'
    edges: Array<{
      __typename?: 'comment_likesEdge'
      node: { __typename?: 'comment_likes'; id: any; comment_id: string; user_id: string }
    }>
  } | null
  user: { __typename?: 'profiles'; avatar_url?: string | null; name?: string | null }
} & { ' $fragmentName'?: 'CommentMetaDataFragment' }

export type CommentListQueryVariables = Exact<{
  topic?: InputMaybe<Scalars['String']['input']>
  userId?: InputMaybe<Scalars['UUID']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  cursor?: InputMaybe<Scalars['Cursor']['input']>
}>

export type CommentListQuery = {
  __typename?: 'Query'
  commentsCollection?: {
    __typename?: 'commentsConnection'
    edges: Array<{
      __typename?: 'commentsEdge'
      node: { __typename?: 'comments' } & {
        ' $fragmentRefs'?: { CommentMetaDataFragment: CommentMetaDataFragment }
      }
    }>
    pageInfo: { __typename?: 'PageInfo'; endCursor?: string | null; hasNextPage: boolean }
  } | null
}

export type ReplyListQueryVariables = Exact<{
  topic?: InputMaybe<Scalars['String']['input']>
  parentId?: InputMaybe<Scalars['UUID']['input']>
  userId?: InputMaybe<Scalars['UUID']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  cursor?: InputMaybe<Scalars['Cursor']['input']>
}>

export type ReplyListQuery = {
  __typename?: 'Query'
  commentsCollection?: {
    __typename?: 'commentsConnection'
    edges: Array<{
      __typename?: 'commentsEdge'
      node: { __typename?: 'comments' } & {
        ' $fragmentRefs'?: { CommentMetaDataFragment: CommentMetaDataFragment }
      }
    }>
    pageInfo: { __typename?: 'PageInfo'; endCursor?: string | null; hasNextPage: boolean }
  } | null
}

export type GetSubscriptionQueryVariables = Exact<{ [key: string]: never }>

export type GetSubscriptionQuery = {
  __typename?: 'Query'
  productsCollection?: {
    __typename?: 'productsConnection'
    edges: Array<{
      __typename?: 'productsEdge'
      node: {
        __typename?: 'products'
        id: string
        credits: number
        tier?: Product_Tier | null
        pricesCollection?: {
          __typename?: 'pricesConnection'
          edges: Array<{
            __typename?: 'pricesEdge'
            node: {
              __typename?: 'prices'
              id: string
              unit_amount?: any | null
              interval?: Pricing_Plan_Interval | null
            }
          }>
        } | null
      }
    }>
  } | null
}

export type GetPackagesQueryVariables = Exact<{ [key: string]: never }>

export type GetPackagesQuery = {
  __typename?: 'Query'
  productsCollection?: {
    __typename?: 'productsConnection'
    edges: Array<{
      __typename?: 'productsEdge'
      node: {
        __typename?: 'products'
        id: string
        credits: number
        tier?: Product_Tier | null
        pricesCollection?: {
          __typename?: 'pricesConnection'
          edges: Array<{
            __typename?: 'pricesEdge'
            node: { __typename?: 'prices'; id: string; unit_amount?: any | null }
          }>
        } | null
      }
    }>
  } | null
}

export type GetModelProfileByIdQueryVariables = Exact<{
  modelId?: InputMaybe<Scalars['UUID']['input']>
  userId?: InputMaybe<Scalars['UUID']['input']>
}>

export type GetModelProfileByIdQuery = {
  __typename?: 'Query'
  modelsCollection?: {
    __typename?: 'modelsConnection'
    edges: Array<{
      __typename?: 'modelsEdge'
      node: {
        __typename?: 'models'
        id: string
        nickname?: string | null
        avatar?: string | null
        fullbody?: string | null
        penis?: string | null
        ass?: string | null
        about?: string | null
        creator?: string | null
        labels?: Array<number | null> | null
        private?: boolean | null
        subscribers?: any | null
        templated?: any | null
        version: Model_Version
        personalities?: Array<string | null> | null
        hobbies?: Array<string | null> | null
        occupation?: string | null
        age?: number | null
        has_penis_feature: boolean
        user?: {
          __typename?: 'users'
          profiles: {
            __typename?: 'profiles'
            id: string
            avatar_url?: string | null
            name?: string | null
          }
        } | null
      }
    }>
  } | null
  model_subscribersCollection?: {
    __typename?: 'model_subscribersConnection'
    edges: Array<{
      __typename?: 'model_subscribersEdge'
      node: { __typename?: 'model_subscribers'; model_id: string; subscriber_id: string }
    }>
  } | null
}

export type GetPostsByModelIdQueryVariables = Exact<{
  modelId?: InputMaybe<Scalars['UUID']['input']>
  private?: InputMaybe<Scalars['Boolean']['input']>
  userId?: InputMaybe<Scalars['UUID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  after?: InputMaybe<Scalars['Cursor']['input']>
  creator?: InputMaybe<Scalars['UUID']['input']>
  includeUser: Scalars['Boolean']['input']
}>

export type GetPostsByModelIdQuery = {
  __typename?: 'Query'
  postsCollection?: {
    __typename?: 'postsConnection'
    edges: Array<{
      __typename?: 'postsEdge'
      node: { __typename?: 'posts' } & {
        ' $fragmentRefs'?: { PostMetaData_FragementFragment: PostMetaData_FragementFragment }
      }
    }>
    pageInfo: {
      __typename?: 'PageInfo'
      startCursor?: string | null
      endCursor?: string | null
      hasPreviousPage: boolean
      hasNextPage: boolean
    }
  } | null
}

export type DeleteModelByIdMutationVariables = Exact<{
  modelId?: InputMaybe<Scalars['UUID']['input']>
  userId?: InputMaybe<Scalars['UUID']['input']>
}>

export type DeleteModelByIdMutation = {
  __typename?: 'Mutation'
  deleteFrommodelsCollection: { __typename?: 'modelsDeleteResponse'; affectedCount: number }
}

export type GetLatestSubsribedQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['UUID']['input']>
}>

export type GetLatestSubsribedQuery = {
  __typename?: 'Query'
  model_subscribersCollection?: {
    __typename?: 'model_subscribersConnection'
    edges: Array<{
      __typename?: 'model_subscribersEdge'
      node: {
        __typename?: 'model_subscribers'
        id: any
        model_id: string
        subscriber_id: string
        model: {
          __typename?: 'models'
          id: string
          nickname?: string | null
          avatar?: string | null
        }
      }
    }>
  } | null
}

export type GetPostByIdQueryVariables = Exact<{
  postId?: InputMaybe<Scalars['BigInt']['input']>
  userId?: InputMaybe<Scalars['UUID']['input']>
  includeUser: Scalars['Boolean']['input']
}>

export type GetPostByIdQuery = {
  __typename?: 'Query'
  postsCollection?: {
    __typename?: 'postsConnection'
    edges: Array<{
      __typename?: 'postsEdge'
      node: { __typename?: 'posts' } & {
        ' $fragmentRefs'?: { PostMetaData_FragementFragment: PostMetaData_FragementFragment }
      }
    }>
  } | null
}

export type UpdatePostByIdMutationVariables = Exact<{
  postId?: InputMaybe<Scalars['BigInt']['input']>
  userId?: InputMaybe<Scalars['UUID']['input']>
  content?: InputMaybe<Scalars['String']['input']>
  private?: InputMaybe<Scalars['Boolean']['input']>
  images?: InputMaybe<Scalars['JSON']['input']>
}>

export type UpdatePostByIdMutation = {
  __typename?: 'Mutation'
  updatepostsCollection: {
    __typename?: 'postsUpdateResponse'
    affectedCount: number
    records: Array<{ __typename?: 'posts'; id: any }>
  }
}

export type PostByIdsQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['UUID']['input']>
  Ids?: InputMaybe<Array<Scalars['BigInt']['input']> | Scalars['BigInt']['input']>
  includeUser: Scalars['Boolean']['input']
}>

export type PostByIdsQuery = {
  __typename?: 'Query'
  postsCollection?: {
    __typename?: 'postsConnection'
    edges: Array<{
      __typename?: 'postsEdge'
      node: { __typename?: 'posts' } & {
        ' $fragmentRefs'?: { PostMetaData_FragementFragment: PostMetaData_FragementFragment }
      }
    }>
  } | null
}

export type GetFeedPostsQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['UUID']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  after?: InputMaybe<Scalars['Cursor']['input']>
  orderBy?: InputMaybe<Array<PostsOrderBy> | PostsOrderBy>
  filter?: InputMaybe<PostsFilter>
  includeUser: Scalars['Boolean']['input']
}>

export type GetFeedPostsQuery = {
  __typename?: 'Query'
  postsCollection?: {
    __typename?: 'postsConnection'
    edges: Array<{
      __typename?: 'postsEdge'
      node: { __typename?: 'posts' } & {
        ' $fragmentRefs'?: { PostMetaData_FragementFragment: PostMetaData_FragementFragment }
      }
    }>
    pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean; endCursor?: string | null }
  } | null
}

export type PostMetaData_FragementFragment = {
  __typename?: 'posts'
  id: any
  model_id: string
  creator?: string | null
  private?: boolean | null
  images?: any | null
  content?: string | null
  likes?: number | null
  tag_reused?: number | null
  created_at?: any | null
  comments?: number | null
  tag_combos?: any | null
  version?: Model_Version | null
  user_tags?: Array<any | null> | null
  model: {
    __typename?: 'models'
    id: string
    nickname?: string | null
    avatar?: string | null
    version: Model_Version
    has_penis_feature: boolean
    private?: boolean | null
    model_subscribersCollection?: {
      __typename?: 'model_subscribersConnection'
      edges: Array<{
        __typename?: 'model_subscribersEdge'
        node: { __typename?: 'model_subscribers'; id: any; model_id: string; subscriber_id: string }
      }>
    } | null
  }
  post_likesCollection?: {
    __typename?: 'post_likesConnection'
    edges: Array<{ __typename?: 'post_likesEdge'; node: { __typename?: 'post_likes'; id: any } }>
  } | null
  user?: {
    __typename?: 'users'
    profiles: {
      __typename?: 'profiles'
      id: string
      avatar_url?: string | null
      name?: string | null
    }
  } | null
} & { ' $fragmentName'?: 'PostMetaData_FragementFragment' }

export type UserPostByLikeQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['UUID']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  cursor?: InputMaybe<Scalars['Cursor']['input']>
  includeUser: Scalars['Boolean']['input']
  filter?: InputMaybe<Post_LikesFilter>
}>

export type UserPostByLikeQuery = {
  __typename?: 'Query'
  post_likesCollection?: {
    __typename?: 'post_likesConnection'
    edges: Array<{
      __typename?: 'post_likesEdge'
      node: {
        __typename?: 'post_likes'
        id: any
        post_id: any
        user_id: string
        post: { __typename?: 'posts' } & {
          ' $fragmentRefs'?: { PostMetaData_FragementFragment: PostMetaData_FragementFragment }
        }
      }
    }>
    pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean; endCursor?: string | null }
  } | null
}

export type UserPostByAllQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['UUID']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  cursor?: InputMaybe<Scalars['Cursor']['input']>
  includeUser: Scalars['Boolean']['input']
  filter?: InputMaybe<PostsFilter>
}>

export type UserPostByAllQuery = {
  __typename?: 'Query'
  postsCollection?: {
    __typename?: 'postsConnection'
    edges: Array<{
      __typename?: 'postsEdge'
      node: { __typename?: 'posts' } & {
        ' $fragmentRefs'?: { PostMetaData_FragementFragment: PostMetaData_FragementFragment }
      }
    }>
    pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean; endCursor?: string | null }
  } | null
}

export type GetFollowingModelQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['UUID']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  cursor?: InputMaybe<Scalars['Cursor']['input']>
}>

export type GetFollowingModelQuery = {
  __typename?: 'Query'
  model_subscribersCollection?: {
    __typename?: 'model_subscribersConnection'
    edges: Array<{
      __typename?: 'model_subscribersEdge'
      node: {
        __typename?: 'model_subscribers'
        id: any
        model: {
          __typename?: 'models'
          id: string
          nickname?: string | null
          avatar?: string | null
          about?: string | null
          fullbody?: string | null
          penis?: string | null
          ass?: string | null
          version: Model_Version
          has_penis_feature: boolean
        }
      }
    }>
    pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean; endCursor?: string | null }
  } | null
}

export type GetCreatedModelQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['UUID']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  cursor?: InputMaybe<Scalars['Cursor']['input']>
}>

export type GetCreatedModelQuery = {
  __typename?: 'Query'
  modelsCollection?: {
    __typename?: 'modelsConnection'
    edges: Array<{
      __typename?: 'modelsEdge'
      node: {
        __typename?: 'models'
        id: string
        nickname?: string | null
        about?: string | null
        avatar?: string | null
        fullbody?: string | null
        penis?: string | null
        ass?: string | null
        version: Model_Version
        has_penis_feature: boolean
      }
    }>
    pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean; endCursor?: string | null }
  } | null
}

export type GetUserProfileQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['UUID']['input']>
}>

export type GetUserProfileQuery = {
  __typename?: 'Query'
  usersCollection?: {
    __typename?: 'usersConnection'
    edges: Array<{
      __typename?: 'usersEdge'
      node: {
        __typename?: 'users'
        stripe_customer_id?: string | null
        subscription_tier: Subscription_Tier
        modelsCollection?: { __typename?: 'modelsConnection'; totalCount: number } | null
        model_subscribersCollection?: {
          __typename?: 'model_subscribersConnection'
          totalCount: number
        } | null
        profiles: {
          __typename?: 'profiles'
          name?: string | null
          about?: string | null
          avatar_url?: string | null
        }
        credits?: {
          __typename?: 'credits'
          subscription_credits: string
          subscription_credits_used: string
          addon_credits: string
          addon_credits_used: string
        } | null
        postsCollection?: { __typename?: 'postsConnection'; totalCount: number } | null
      }
    }>
  } | null
  followings?: { __typename?: 'user_subscribersConnection'; totalCount: number } | null
}

export type UpdateUserProfileMutationVariables = Exact<{
  userId?: InputMaybe<Scalars['UUID']['input']>
  avatar?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  about?: InputMaybe<Scalars['String']['input']>
}>

export type UpdateUserProfileMutation = {
  __typename?: 'Mutation'
  updateprofilesCollection: { __typename?: 'profilesUpdateResponse'; affectedCount: number }
}

export type GetUserInfoQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['UUID']['input']>
  followerId?: InputMaybe<Scalars['UUID']['input']>
}>

export type GetUserInfoQuery = {
  __typename?: 'Query'
  usersCollection?: {
    __typename?: 'usersConnection'
    edges: Array<{
      __typename?: 'usersEdge'
      node: {
        __typename?: 'users'
        followers?: any | null
        subscription_tier: Subscription_Tier
        profiles: {
          __typename?: 'profiles'
          id: string
          avatar_url?: string | null
          name?: string | null
          about?: string | null
        }
        modelsCollection?: { __typename?: 'modelsConnection'; totalCount: number } | null
        postsCollection?: { __typename?: 'postsConnection'; totalCount: number } | null
      }
    }>
  } | null
  followers?: { __typename?: 'user_subscribersConnection'; totalCount: number } | null
  userFollowings?: { __typename?: 'user_subscribersConnection'; totalCount: number } | null
  modelFollowings?: { __typename?: 'model_subscribersConnection'; totalCount: number } | null
  following?: {
    __typename?: 'user_subscribersConnection'
    edges: Array<{
      __typename?: 'user_subscribersEdge'
      node: { __typename?: 'user_subscribers'; id: any }
    }>
  } | null
}

export type GetFollowingUserQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['UUID']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  cursor?: InputMaybe<Scalars['Cursor']['input']>
}>

export type GetFollowingUserQuery = {
  __typename?: 'Query'
  user_subscribersCollection?: {
    __typename?: 'user_subscribersConnection'
    edges: Array<{
      __typename?: 'user_subscribersEdge'
      node: {
        __typename?: 'user_subscribers'
        id: any
        user: {
          __typename?: 'users'
          id: string
          profiles: {
            __typename?: 'profiles'
            name?: string | null
            about?: string | null
            avatar_url?: string | null
          }
        }
      }
    }>
    pageInfo: { __typename?: 'PageInfo'; endCursor?: string | null; hasNextPage: boolean }
  } | null
}

export type GetUserAndCreditsInfoQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['UUID']['input']>
}>

export type GetUserAndCreditsInfoQuery = {
  __typename?: 'Query'
  usersCollection?: {
    __typename?: 'usersConnection'
    edges: Array<{
      __typename?: 'usersEdge'
      node: {
        __typename?: 'users'
        subscription_tier: Subscription_Tier
        credits?: {
          __typename?: 'credits'
          subscription_credits: string
          subscription_credits_used: string
          addon_credits: string
          addon_credits_used: string
        } | null
      }
    }>
  } | null
}

export const SessionDetail_FragementFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SessionDetail_Fragement' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'sessions' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'model_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'model' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nickname' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fullbody' } },
                { kind: 'Field', name: { kind: 'Name', value: 'penis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ass' } },
                { kind: 'Field', name: { kind: 'Name', value: 'about' } },
                { kind: 'Field', name: { kind: 'Name', value: 'creator' } },
                { kind: 'Field', name: { kind: 'Name', value: 'labels' } },
                { kind: 'Field', name: { kind: 'Name', value: 'private' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subscribers' } },
                { kind: 'Field', name: { kind: 'Name', value: 'templated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                { kind: 'Field', name: { kind: 'Name', value: 'personalities' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hobbies' } },
                { kind: 'Field', name: { kind: 'Name', value: 'occupation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'age' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SessionDetail_FragementFragment, unknown>
export const CommentMetaDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'commentMetaData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'comments' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'topic' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
          { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parent_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'likes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'replies' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'comment_likesCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'comment_id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'avatar_url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CommentMetaDataFragment, unknown>
export const PostMetaData_FragementFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PostMetaData_Fragement' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'posts' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'model_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'creator' } },
          { kind: 'Field', name: { kind: 'Name', value: 'private' } },
          { kind: 'Field', name: { kind: 'Name', value: 'images' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'likes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tag_reused' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comments' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tag_combos' } },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          { kind: 'Field', name: { kind: 'Name', value: 'user_tags' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'model' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nickname' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                { kind: 'Field', name: { kind: 'Name', value: 'has_penis_feature' } },
                { kind: 'Field', name: { kind: 'Name', value: 'private' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'model_subscribersCollection' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filter' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'subscriber_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'userId' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  directives: [
                    {
                      kind: 'Directive',
                      name: { kind: 'Name', value: 'include' },
                      arguments: [
                        {
                          kind: 'Argument',
                          name: { kind: 'Name', value: 'if' },
                          value: { kind: 'Variable', name: { kind: 'Name', value: 'includeUser' } },
                        },
                      ],
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'model_id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'subscriber_id' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'post_likesCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: { kind: 'Variable', name: { kind: 'Name', value: 'includeUser' } },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profiles' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'avatar_url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PostMetaData_FragementFragment, unknown>
export const GetAllFeedbackTopicsInAdminDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAllFeedbackTopicsInAdmin' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'topic_interaction_type' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'session_topic_interactionsCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'in' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'updated_at' },
                      value: { kind: 'EnumValue', value: 'DescNullsLast' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'like_count' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'dislike_count' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'profiles' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'avatar_url' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'topic' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'scenario' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'session' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'model' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'avatar' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAllFeedbackTopicsInAdminQuery,
  GetAllFeedbackTopicsInAdminQueryVariables
>
export const GetScenarioContentInAdminDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getScenarioContentInAdmin' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'session_scenariosCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'first_message' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'alternate_greetings' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'example_dialogs' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'scenario' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'usedCount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'likeCount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'creator' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetScenarioContentInAdminQuery,
  GetScenarioContentInAdminQueryVariables
>
export const GetTopicInAdminDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getTopicInAdmin' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'topicId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'session_topicsCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'topicId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'session' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'user' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'profiles' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'avatar_url' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'model' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'scenario' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetTopicInAdminQuery, GetTopicInAdminQueryVariables>
export const GetAllTopicsInAdminDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAllTopicsInAdmin' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'session_topicsCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'updated_at' },
                      value: { kind: 'EnumValue', value: 'DescNullsLast' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'session' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'user' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'profiles' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'avatar_url' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'model' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'scenario' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAllTopicsInAdminQuery, GetAllTopicsInAdminQueryVariables>
export const GetTopicMessagesInAdminDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getTopicMessagesInAdmin' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'topicId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'BigInt' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cursor' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Cursor' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'session_messagesCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'topic_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'topicId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cursor' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'files' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'session_id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'topic_id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'session_message_interactions' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetTopicMessagesInAdminQuery, GetTopicMessagesInAdminQueryVariables>
export const DashboardModelInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'dashboardModelInfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'modelsCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fullbody' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'ass' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'penis' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'creator' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'labels' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'private' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'subscribers' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'templated' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'nickname' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'profiles' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'avatar_url' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'postsCollection' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DashboardModelInfoQuery, DashboardModelInfoQueryVariables>
export const DashboardModelListByKeywordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'dashboardModelListByKeyword' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'modelsCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'or' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'eq' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: 'id' },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'nickname' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'like' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: 'name' },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_at' },
                      value: { kind: 'EnumValue', value: 'DescNullsLast' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'creator' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'labels' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'private' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'subscribers' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'templated' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'nickname' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'profiles' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DashboardModelListByKeywordQuery,
  DashboardModelListByKeywordQueryVariables
>
export const DashboardModelListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'dashboardModelList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'modelsCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_at' },
                      value: { kind: 'EnumValue', value: 'DescNullsLast' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'creator' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'labels' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'private' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'subscribers' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'templated' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'nickname' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'profiles' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DashboardModelListQuery, DashboardModelListQueryVariables>
export const DashboardModelPostsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'dashboardModelPosts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'modelId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cursor' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Cursor' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeUser' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'postsCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'model_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'modelId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cursor' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_at' },
                      value: { kind: 'EnumValue', value: 'DescNullsLast' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'PostMetaData_Fragement' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PostMetaData_Fragement' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'posts' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'model_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'creator' } },
          { kind: 'Field', name: { kind: 'Name', value: 'private' } },
          { kind: 'Field', name: { kind: 'Name', value: 'images' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'likes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tag_reused' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comments' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tag_combos' } },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          { kind: 'Field', name: { kind: 'Name', value: 'user_tags' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'model' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nickname' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                { kind: 'Field', name: { kind: 'Name', value: 'has_penis_feature' } },
                { kind: 'Field', name: { kind: 'Name', value: 'private' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'model_subscribersCollection' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filter' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'subscriber_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'userId' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  directives: [
                    {
                      kind: 'Directive',
                      name: { kind: 'Name', value: 'include' },
                      arguments: [
                        {
                          kind: 'Argument',
                          name: { kind: 'Name', value: 'if' },
                          value: { kind: 'Variable', name: { kind: 'Name', value: 'includeUser' } },
                        },
                      ],
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'model_id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'subscriber_id' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'post_likesCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: { kind: 'Variable', name: { kind: 'Name', value: 'includeUser' } },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profiles' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'avatar_url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DashboardModelPostsQuery, DashboardModelPostsQueryVariables>
export const OnboardingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'onboarding' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'from' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Datetime' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'to' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Datetime' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'usersCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_at' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'gte' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'from' } },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'lte' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'to' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalCount' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OnboardingQuery, OnboardingQueryVariables>
export const DashboardUserCreatedModelsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'dashboardUserCreatedModels' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'modelsCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'creator' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'nickname' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fullbody' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'private' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DashboardUserCreatedModelsQuery,
  DashboardUserCreatedModelsQueryVariables
>
export const DashboardUserCreditsHistoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'dashboardUserCreditsHistory' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'credits_historyCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_at' },
                      value: { kind: 'EnumValue', value: 'DescNullsLast' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'subscription_credits_added' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'subscription_credits_spent' },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'addon_credits_added' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'addon_credits_spent' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DashboardUserCreditsHistoryQuery,
  DashboardUserCreditsHistoryQueryVariables
>
export const DashboardUserInfoByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'dashboardUserInfoById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'usersCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'subscription_tier' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'profiles' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'avatar_url' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'about' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'postsCollection' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'credits' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'subscription_credits' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'subscription_credits_used' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'addon_credits' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'addon_credits_used' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'model_subscribersCollection' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'modelsCollection' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DashboardUserInfoByIdQuery, DashboardUserInfoByIdQueryVariables>
export const UserListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'userList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profilesCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_at' },
                      value: { kind: 'EnumValue', value: 'DescNullsLast' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'avatar_url' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'about' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'subscription_tier' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'credits' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'subscription_credits' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'subscription_credits_used',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'addon_credits' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'addon_credits_used' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserListQuery, UserListQueryVariables>
export const UserListByKeywordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'userListByKeyword' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profilesCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'or' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'eq' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: 'id' },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'name' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'like' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: 'name' },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_at' },
                      value: { kind: 'EnumValue', value: 'DescNullsLast' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'avatar_url' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'about' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'subscription_tier' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'credits' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'subscription_credits' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'subscription_credits_used',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'addon_credits' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'addon_credits_used' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserListByKeywordQuery, UserListByKeywordQueryVariables>
export const UserListByEmailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'userListByEmail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'tier' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'subscription_tier' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'usersCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'or' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'email' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'eq' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: 'email' },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'subscription_tier' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'in' },
                                      value: {
                                        kind: 'Variable',
                                        name: { kind: 'Name', value: 'tier' },
                                      },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_at' },
                      value: { kind: 'EnumValue', value: 'DescNullsLast' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'profiles' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'avatar_url' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'about' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'user' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'subscription_tier' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'credits' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'subscription_credits',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'subscription_credits_used',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'addon_credits' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'addon_credits_used' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserListByEmailQuery, UserListByEmailQueryVariables>
export const DashboardUserPostsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'dashboardUserPosts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cursor' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Cursor' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeUser' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'postsCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'creator' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cursor' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_at' },
                      value: { kind: 'EnumValue', value: 'DescNullsLast' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'PostMetaData_Fragement' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PostMetaData_Fragement' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'posts' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'model_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'creator' } },
          { kind: 'Field', name: { kind: 'Name', value: 'private' } },
          { kind: 'Field', name: { kind: 'Name', value: 'images' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'likes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tag_reused' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comments' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tag_combos' } },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          { kind: 'Field', name: { kind: 'Name', value: 'user_tags' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'model' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nickname' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                { kind: 'Field', name: { kind: 'Name', value: 'has_penis_feature' } },
                { kind: 'Field', name: { kind: 'Name', value: 'private' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'model_subscribersCollection' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filter' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'subscriber_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'userId' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  directives: [
                    {
                      kind: 'Directive',
                      name: { kind: 'Name', value: 'include' },
                      arguments: [
                        {
                          kind: 'Argument',
                          name: { kind: 'Name', value: 'if' },
                          value: { kind: 'Variable', name: { kind: 'Name', value: 'includeUser' } },
                        },
                      ],
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'model_id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'subscriber_id' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'post_likesCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: { kind: 'Variable', name: { kind: 'Name', value: 'includeUser' } },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profiles' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'avatar_url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DashboardUserPostsQuery, DashboardUserPostsQueryVariables>
export const GetSessionDetailByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getSessionDetailById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sessionsCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'SessionDetail_Fragement' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SessionDetail_Fragement' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'sessions' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'model_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'model' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nickname' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fullbody' } },
                { kind: 'Field', name: { kind: 'Name', value: 'penis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ass' } },
                { kind: 'Field', name: { kind: 'Name', value: 'about' } },
                { kind: 'Field', name: { kind: 'Name', value: 'creator' } },
                { kind: 'Field', name: { kind: 'Name', value: 'labels' } },
                { kind: 'Field', name: { kind: 'Name', value: 'private' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subscribers' } },
                { kind: 'Field', name: { kind: 'Name', value: 'templated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                { kind: 'Field', name: { kind: 'Name', value: 'personalities' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hobbies' } },
                { kind: 'Field', name: { kind: 'Name', value: 'occupation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'age' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSessionDetailByIdQuery, GetSessionDetailByIdQueryVariables>
export const GetUserSessionDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUserSessionDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Cursor' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sessionsCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'SessionDetail_Fragement' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SessionDetail_Fragement' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'sessions' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'model_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'model' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nickname' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fullbody' } },
                { kind: 'Field', name: { kind: 'Name', value: 'penis' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ass' } },
                { kind: 'Field', name: { kind: 'Name', value: 'about' } },
                { kind: 'Field', name: { kind: 'Name', value: 'creator' } },
                { kind: 'Field', name: { kind: 'Name', value: 'labels' } },
                { kind: 'Field', name: { kind: 'Name', value: 'private' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subscribers' } },
                { kind: 'Field', name: { kind: 'Name', value: 'templated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                { kind: 'Field', name: { kind: 'Name', value: 'personalities' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hobbies' } },
                { kind: 'Field', name: { kind: 'Name', value: 'occupation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'age' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserSessionDetailsQuery, GetUserSessionDetailsQueryVariables>
export const GetSessionScenariosDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getSessionScenarios' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cursor' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Cursor' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'session_scenariosOrderBy' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'session_scenariosFilter' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'session_scenariosCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cursor' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'first_message' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'alternate_greetings' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'example_dialogs' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'scenario' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'usedCount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'likeCount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'creator' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'session_scenario_likesCollection' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'filter' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: 'user_id' },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: { kind: 'Name', value: 'eq' },
                                              value: {
                                                kind: 'Variable',
                                                name: { kind: 'Name', value: 'userId' },
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'edges' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'node' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'profiles' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSessionScenariosQuery, GetSessionScenariosQueryVariables>
export const GetUserLikedScenariosDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUserLikedScenarios' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cursor' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Cursor' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'session_scenario_likesCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cursor' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_at' },
                      value: { kind: 'EnumValue', value: 'DescNullsLast' },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'scenario' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'first_message' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'alternate_greetings' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'example_dialogs' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'scenario' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'usedCount' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'likeCount' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'creator' } },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'session_scenario_likesCollection',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'edges' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'node' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'id' },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'user' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'profiles' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserLikedScenariosQuery, GetUserLikedScenariosQueryVariables>
export const GetSessionTopicsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getSessionTopics' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sessionId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cursor' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Cursor' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'session_topicsCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'session_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'sessionId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cursor' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'updated_at' },
                      value: { kind: 'EnumValue', value: 'DescNullsLast' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'session_id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'scenario_id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'language' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'scenario' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'scenario' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSessionTopicsQuery, GetSessionTopicsQueryVariables>
export const GetTopicMessagesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getTopicMessages' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'topicId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'BigInt' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cursor' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Cursor' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'session_messagesCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'topic_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'topicId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cursor' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_at' },
                      value: { kind: 'EnumValue', value: 'DescNullsLast' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'files' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'session_id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'topic_id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'session_message_interactions' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetTopicMessagesQuery, GetTopicMessagesQueryVariables>
export const GetSessionTopicDetailByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getSessionTopicDetailById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'topicId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'session_topicsCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'topicId' } },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'session_id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'scenario' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'first_message' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'alternate_greetings' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'example_dialogs' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'scenario' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'updated_at' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'usedCount' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'creator' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetSessionTopicDetailByIdQuery,
  GetSessionTopicDetailByIdQueryVariables
>
export const CommentListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'commentList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'topic' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cursor' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Cursor' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commentsCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'topic' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'topic' } },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'parent_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'is' },
                            value: { kind: 'EnumValue', value: 'NULL' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cursor' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_at' },
                      value: { kind: 'EnumValue', value: 'AscNullsLast' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'commentMetaData' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'commentMetaData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'comments' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'topic' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
          { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parent_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'likes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'replies' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'comment_likesCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'comment_id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'avatar_url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CommentListQuery, CommentListQueryVariables>
export const ReplyListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'replyList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'topic' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'parentId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cursor' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Cursor' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'commentsCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'topic' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'topic' } },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'parent_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'parentId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cursor' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_at' },
                      value: { kind: 'EnumValue', value: 'AscNullsLast' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'commentMetaData' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'commentMetaData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'comments' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'topic' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
          { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parent_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'likes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'replies' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'comment_likesCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'comment_id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'avatar_url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReplyListQuery, ReplyListQueryVariables>
export const GetSubscriptionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getSubscription' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productsCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'active' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'tier' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'in' },
                            value: {
                              kind: 'ListValue',
                              values: [
                                { kind: 'EnumValue', value: 'subscription_plus' },
                                { kind: 'EnumValue', value: 'subscription_premium' },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'credits' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'tier' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pricesCollection' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'filter' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: 'active' },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: { kind: 'Name', value: 'eq' },
                                              value: { kind: 'BooleanValue', value: true },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'edges' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'node' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'unit_amount' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'interval' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSubscriptionQuery, GetSubscriptionQueryVariables>
export const GetPackagesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPackages' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productsCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'active' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'tier' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'in' },
                            value: {
                              kind: 'ListValue',
                              values: [
                                { kind: 'EnumValue', value: 'addon_small' },
                                { kind: 'EnumValue', value: 'addon_medium' },
                                { kind: 'EnumValue', value: 'addon_big' },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'credits' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'tier' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pricesCollection' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'filter' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: 'active' },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: { kind: 'Name', value: 'eq' },
                                              value: { kind: 'BooleanValue', value: true },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'edges' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'node' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'unit_amount' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPackagesQuery, GetPackagesQueryVariables>
export const GetModelProfileByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getModelProfileById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'modelId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'modelsCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'modelId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'nickname' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fullbody' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'penis' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'ass' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'about' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'creator' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'labels' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'private' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'subscribers' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'templated' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'personalities' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'hobbies' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'occupation' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'age' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'has_penis_feature' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'profiles' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'avatar_url' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'model_subscribersCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'model_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'modelId' } },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'subscriber_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'model_id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'subscriber_id' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetModelProfileByIdQuery, GetModelProfileByIdQueryVariables>
export const GetPostsByModelIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPostsByModelId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'modelId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'private' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Cursor' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'creator' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeUser' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'postsCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'model_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'modelId' } },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'private' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'private' } },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'creator' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'creator' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_at' },
                      value: { kind: 'EnumValue', value: 'DescNullsLast' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'PostMetaData_Fragement' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'startCursor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasPreviousPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PostMetaData_Fragement' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'posts' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'model_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'creator' } },
          { kind: 'Field', name: { kind: 'Name', value: 'private' } },
          { kind: 'Field', name: { kind: 'Name', value: 'images' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'likes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tag_reused' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comments' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tag_combos' } },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          { kind: 'Field', name: { kind: 'Name', value: 'user_tags' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'model' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nickname' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                { kind: 'Field', name: { kind: 'Name', value: 'has_penis_feature' } },
                { kind: 'Field', name: { kind: 'Name', value: 'private' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'model_subscribersCollection' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filter' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'subscriber_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'userId' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  directives: [
                    {
                      kind: 'Directive',
                      name: { kind: 'Name', value: 'include' },
                      arguments: [
                        {
                          kind: 'Argument',
                          name: { kind: 'Name', value: 'if' },
                          value: { kind: 'Variable', name: { kind: 'Name', value: 'includeUser' } },
                        },
                      ],
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'model_id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'subscriber_id' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'post_likesCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: { kind: 'Variable', name: { kind: 'Name', value: 'includeUser' } },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profiles' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'avatar_url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPostsByModelIdQuery, GetPostsByModelIdQueryVariables>
export const DeleteModelByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteModelById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'modelId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteFrommodelsCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'modelId' } },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'creator' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'atMost' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'affectedCount' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteModelByIdMutation, DeleteModelByIdMutationVariables>
export const GetLatestSubsribedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getLatestSubsribed' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'model_subscribersCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'subscriber_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_at' },
                      value: { kind: 'EnumValue', value: 'DescNullsLast' },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'IntValue', value: '20' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'model_id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'subscriber_id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'model' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'nickname' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetLatestSubsribedQuery, GetLatestSubsribedQueryVariables>
export const GetPostByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPostById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'postId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'BigInt' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeUser' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'postsCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'postId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'PostMetaData_Fragement' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PostMetaData_Fragement' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'posts' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'model_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'creator' } },
          { kind: 'Field', name: { kind: 'Name', value: 'private' } },
          { kind: 'Field', name: { kind: 'Name', value: 'images' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'likes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tag_reused' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comments' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tag_combos' } },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          { kind: 'Field', name: { kind: 'Name', value: 'user_tags' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'model' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nickname' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                { kind: 'Field', name: { kind: 'Name', value: 'has_penis_feature' } },
                { kind: 'Field', name: { kind: 'Name', value: 'private' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'model_subscribersCollection' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filter' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'subscriber_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'userId' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  directives: [
                    {
                      kind: 'Directive',
                      name: { kind: 'Name', value: 'include' },
                      arguments: [
                        {
                          kind: 'Argument',
                          name: { kind: 'Name', value: 'if' },
                          value: { kind: 'Variable', name: { kind: 'Name', value: 'includeUser' } },
                        },
                      ],
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'model_id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'subscriber_id' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'post_likesCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: { kind: 'Variable', name: { kind: 'Name', value: 'includeUser' } },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profiles' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'avatar_url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPostByIdQuery, GetPostByIdQueryVariables>
export const UpdatePostByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updatePostById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'postId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'BigInt' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'content' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'private' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'images' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'JSON' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatepostsCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'postId' } },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'creator' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'content' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'content' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'private' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'private' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'images' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'images' } },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'atMost' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'affectedCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'records' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdatePostByIdMutation, UpdatePostByIdMutationVariables>
export const PostByIdsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'postByIds' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'Ids' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'BigInt' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeUser' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'postsCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'in' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'Ids' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'PostMetaData_Fragement' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PostMetaData_Fragement' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'posts' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'model_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'creator' } },
          { kind: 'Field', name: { kind: 'Name', value: 'private' } },
          { kind: 'Field', name: { kind: 'Name', value: 'images' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'likes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tag_reused' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comments' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tag_combos' } },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          { kind: 'Field', name: { kind: 'Name', value: 'user_tags' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'model' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nickname' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                { kind: 'Field', name: { kind: 'Name', value: 'has_penis_feature' } },
                { kind: 'Field', name: { kind: 'Name', value: 'private' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'model_subscribersCollection' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filter' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'subscriber_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'userId' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  directives: [
                    {
                      kind: 'Directive',
                      name: { kind: 'Name', value: 'include' },
                      arguments: [
                        {
                          kind: 'Argument',
                          name: { kind: 'Name', value: 'if' },
                          value: { kind: 'Variable', name: { kind: 'Name', value: 'includeUser' } },
                        },
                      ],
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'model_id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'subscriber_id' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'post_likesCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: { kind: 'Variable', name: { kind: 'Name', value: 'includeUser' } },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profiles' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'avatar_url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PostByIdsQuery, PostByIdsQueryVariables>
export const GetFeedPostsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getFeedPosts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Cursor' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'postsOrderBy' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'postsFilter' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeUser' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'postsCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'PostMetaData_Fragement' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PostMetaData_Fragement' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'posts' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'model_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'creator' } },
          { kind: 'Field', name: { kind: 'Name', value: 'private' } },
          { kind: 'Field', name: { kind: 'Name', value: 'images' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'likes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tag_reused' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comments' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tag_combos' } },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          { kind: 'Field', name: { kind: 'Name', value: 'user_tags' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'model' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nickname' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                { kind: 'Field', name: { kind: 'Name', value: 'has_penis_feature' } },
                { kind: 'Field', name: { kind: 'Name', value: 'private' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'model_subscribersCollection' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filter' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'subscriber_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'userId' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  directives: [
                    {
                      kind: 'Directive',
                      name: { kind: 'Name', value: 'include' },
                      arguments: [
                        {
                          kind: 'Argument',
                          name: { kind: 'Name', value: 'if' },
                          value: { kind: 'Variable', name: { kind: 'Name', value: 'includeUser' } },
                        },
                      ],
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'model_id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'subscriber_id' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'post_likesCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: { kind: 'Variable', name: { kind: 'Name', value: 'includeUser' } },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profiles' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'avatar_url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetFeedPostsQuery, GetFeedPostsQueryVariables>
export const UserPostByLikeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'userPostByLike' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cursor' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Cursor' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeUser' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'post_likesFilter' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'post_likesCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cursor' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_at' },
                      value: { kind: 'EnumValue', value: 'DescNullsLast' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'post_id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'user_id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'post' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'PostMetaData_Fragement' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PostMetaData_Fragement' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'posts' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'model_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'creator' } },
          { kind: 'Field', name: { kind: 'Name', value: 'private' } },
          { kind: 'Field', name: { kind: 'Name', value: 'images' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'likes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tag_reused' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comments' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tag_combos' } },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          { kind: 'Field', name: { kind: 'Name', value: 'user_tags' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'model' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nickname' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                { kind: 'Field', name: { kind: 'Name', value: 'has_penis_feature' } },
                { kind: 'Field', name: { kind: 'Name', value: 'private' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'model_subscribersCollection' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filter' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'subscriber_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'userId' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  directives: [
                    {
                      kind: 'Directive',
                      name: { kind: 'Name', value: 'include' },
                      arguments: [
                        {
                          kind: 'Argument',
                          name: { kind: 'Name', value: 'if' },
                          value: { kind: 'Variable', name: { kind: 'Name', value: 'includeUser' } },
                        },
                      ],
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'model_id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'subscriber_id' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'post_likesCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: { kind: 'Variable', name: { kind: 'Name', value: 'includeUser' } },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profiles' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'avatar_url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserPostByLikeQuery, UserPostByLikeQueryVariables>
export const UserPostByAllDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'userPostByAll' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cursor' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Cursor' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeUser' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'postsFilter' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'postsCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cursor' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_at' },
                      value: { kind: 'EnumValue', value: 'DescNullsLast' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'PostMetaData_Fragement' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PostMetaData_Fragement' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'posts' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'model_id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'creator' } },
          { kind: 'Field', name: { kind: 'Name', value: 'private' } },
          { kind: 'Field', name: { kind: 'Name', value: 'images' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'likes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tag_reused' } },
          { kind: 'Field', name: { kind: 'Name', value: 'created_at' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comments' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tag_combos' } },
          { kind: 'Field', name: { kind: 'Name', value: 'version' } },
          { kind: 'Field', name: { kind: 'Name', value: 'user_tags' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'model' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nickname' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                { kind: 'Field', name: { kind: 'Name', value: 'has_penis_feature' } },
                { kind: 'Field', name: { kind: 'Name', value: 'private' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'model_subscribersCollection' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filter' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'subscriber_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'userId' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  directives: [
                    {
                      kind: 'Directive',
                      name: { kind: 'Name', value: 'include' },
                      arguments: [
                        {
                          kind: 'Argument',
                          name: { kind: 'Name', value: 'if' },
                          value: { kind: 'Variable', name: { kind: 'Name', value: 'includeUser' } },
                        },
                      ],
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'model_id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'subscriber_id' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'post_likesCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: { kind: 'Variable', name: { kind: 'Name', value: 'includeUser' } },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profiles' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'avatar_url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserPostByAllQuery, UserPostByAllQueryVariables>
export const GetFollowingModelDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getFollowingModel' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cursor' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Cursor' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'model_subscribersCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'subscriber_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cursor' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_at' },
                      value: { kind: 'EnumValue', value: 'DescNullsLast' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'model' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'nickname' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'about' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'fullbody' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'penis' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'ass' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'has_penis_feature' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetFollowingModelQuery, GetFollowingModelQueryVariables>
export const GetCreatedModelDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getCreatedModel' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cursor' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Cursor' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'modelsCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'creator' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cursor' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_at' },
                      value: { kind: 'EnumValue', value: 'DescNullsLast' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'nickname' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'about' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'fullbody' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'penis' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'ass' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'has_penis_feature' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCreatedModelQuery, GetCreatedModelQueryVariables>
export const GetUserProfileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUserProfile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'usersCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'stripe_customer_id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'subscription_tier' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'modelsCollection' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'model_subscribersCollection' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'profiles' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'about' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'avatar_url' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'credits' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'subscription_credits' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'subscription_credits_used' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'addon_credits' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'addon_credits_used' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'postsCollection' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'followings' },
            name: { kind: 'Name', value: 'user_subscribersCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'subscriber_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalCount' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserProfileQuery, GetUserProfileQueryVariables>
export const UpdateUserProfileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateUserProfile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'avatar' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'about' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateprofilesCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'set' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'avatar_url' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'avatar' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'about' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'about' } },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'atMost' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'affectedCount' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>
export const GetUserInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUserInfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'followerId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'usersCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'followers' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'subscription_tier' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'profiles' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'avatar_url' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'about' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'modelsCollection' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'postsCollection' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'followers' },
            name: { kind: 'Name', value: 'user_subscribersCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalCount' } }],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'userFollowings' },
            name: { kind: 'Name', value: 'user_subscribersCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'subscriber_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalCount' } }],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'modelFollowings' },
            name: { kind: 'Name', value: 'model_subscribersCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'subscriber_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalCount' } }],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'following' },
            name: { kind: 'Name', value: 'user_subscribersCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'subscriber_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'followerId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserInfoQuery, GetUserInfoQueryVariables>
export const GetFollowingUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getFollowingUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cursor' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Cursor' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user_subscribersCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'subscriber_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cursor' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_at' },
                      value: { kind: 'EnumValue', value: 'DescNullsLast' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'profiles' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'about' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'avatar_url' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetFollowingUserQuery, GetFollowingUserQueryVariables>
export const GetUserAndCreditsInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUserAndCreditsInfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'usersCollection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'subscription_tier' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'credits' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'subscription_credits' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'subscription_credits_used' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'addon_credits' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'addon_credits_used' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserAndCreditsInfoQuery, GetUserAndCreditsInfoQueryVariables>
