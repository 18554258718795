import { observer } from '@legendapp/state/react'
import { Subscription_Tier } from '@my/supabase/graphql/gql/graphql'
import { useBillingScreenTranslation } from 'app/features/ecommerce/hooks/useBillingScreenTranslation'

import { CardItem } from './CardItem'
import { SubscriptionPrices } from '../../../constant'
import { useSubscriptionSection } from '../providers/SubscriptionSectionProvider'
export const Cards = observer(() => {
  const { t } = useBillingScreenTranslation('subscriptionSection.cards')
  const { selectedPeriod$, selectedTier$ } = useSubscriptionSection()
  return (
    <div className="no-scrollbar w-full overflow-x-auto">
      <div className="mt-5 flex w-full items-stretch gap-4 md:mt-10 md:items-center lg:justify-center">
        <CardItem
          onClick={() => selectedTier$.set(Subscription_Tier.Plus)}
          icon={
            <img
              src="https://api.mydreamboy.com/storage/v1/object/public/static/plus_tier.png"
              className="h-9 w-9"
            />
          }
          tier={t('plus')}
          credits={300}
          billPrice={`${SubscriptionPrices[selectedPeriod$.get()][Subscription_Tier.Plus]}`}
          benefits={[3, 4, 5]}
          className="order-2 md:order-1"
        />
        <CardItem
          onClick={() => selectedTier$.set(Subscription_Tier.Premium)}
          icon={
            <img
              src="https://api.mydreamboy.com/storage/v1/object/public/static/premium_tier.png"
              className="h-9 w-9"
            />
          }
          tier={t('premium')}
          credits={1500}
          billPrice={`${SubscriptionPrices[selectedPeriod$.get()][Subscription_Tier.Premium]}`}
          benefits={[0, 1, 2, 3, 4, 5, 6]}
          highlight
          decoration={
            <div className="absolute left-1/2 top-0 w-[190px] -translate-x-1/2 -translate-y-1/2 transform rounded bg-white px-[30px] py-[10px] text-center">
              <span className="bg-gradient-to-r from-[#7756E0] to-[#0D009B] bg-clip-text font-bold text-transparent">
                MOST POPULAR
              </span>
            </div>
          }
          className="order-1 md:order-2 md:-mt-4"
        />
        <CardItem
          onClick={() => selectedTier$.set(Subscription_Tier.Pro)}
          icon={
            <img
              src="https://api.mydreamboy.com/storage/v1/object/public/static/pro_tier.png"
              className="h-9 w-9"
            />
          }
          tier={t('pro')}
          credits={8000}
          billPrice={`${SubscriptionPrices[selectedPeriod$.get()][Subscription_Tier.Pro]}`}
          benefits={[0, 1, 2, 3, 4, 5, 6]}
          decoration={
            <div className="absolute left-1/2 top-0 w-[190px] -translate-x-1/2 -translate-y-1/2 transform rounded bg-gradient-to-r from-[#7756E0] to-[#0D009B] px-[30px] py-[10px] text-center font-bold">
              <span className="font-bold text-white">BEST VALUE</span>
            </div>
          }
          className="order-3 md:order-3"
        />
      </div>
    </div>
  )
})
