import { Particles, SphereMask, cn } from '@my/magic-ui'
import {
  RedeemCouponModal,
  toggleRedeemCouponModal,
} from 'app/features/ecommerce/components/RedeemCouponModal'
import { SubscriptionSection } from 'app/features/ecommerce/components/SubscriptionSection'
import { i18nInstance } from 'app/utils/i18n'
import { Inter as FontSans } from 'next/font/google'

import enMessages from '../locales/en/landingScreen.json'
import zhMessages from '../locales/zh/landingScreen.json'
import { FAQScreen } from './components/FAQScreen/FAQScreen'
import { FeatureSection } from './components/FeatureSection/FeatureSection'
import { FooterSection } from './components/FooterSection'
import { HeadBar } from './components/HeadBar/HeadBar'
import HeroSection from './components/HeroSection'
import { PrivateScreen } from './components/PrivateScreen/PrivateScreen'

import './css/landing-screen.css'

const fontSans = FontSans({
  subsets: ['latin'],
  variable: '--font-sans',
})

i18nInstance.addResourceBundle('en', 'landingScreen', enMessages)
i18nInstance.addResourceBundle('zh', 'landingScreen', zhMessages)

export const LandingScreen = () => {
  return (
    <div
      className={cn(
        'min-h-screen w-screen overflow-x-hidden bg-black font-sans antialiased',
        fontSans.variable
      )}
    >
      <HeadBar />
      <Particles
        className="absolute inset-0"
        quantity={150}
        ease={70}
        size={0.05}
        staticity={40}
        color="#ffffff"
      />
      <HeroSection />
      <PrivateScreen />
      <FeatureSection />
      <SphereMask />
      <div className="px-2" id="pricing">
        <SubscriptionSection onRedeem={toggleRedeemCouponModal} />
        <RedeemCouponModal />
      </div>
      <FAQScreen />
      <FooterSection />
    </div>
  )
}
