import { Accordion } from 'app/components/Accordion'
import { useTranslation } from 'react-i18next'

export const FAQScreen = () => {
  const [t] = useTranslation('landingScreen', { keyPrefix: 'faqScreen' })

  return (
    <div className="mx-auto mt-16 max-w-[80rem] sm:mt-32">
      <div className="relative" id="faq"></div>
      <div className="mt-12 md:mt-20">
        <h4 className="text-center text-3xl font-medium text-white md:text-5xl">{t('title')}</h4>
        <div className="mt-8 w-full px-4 md:mx-auto md:mt-12 md:w-[80%]">
          <Accordion
            items={(t('items', { returnObjects: true }) as { label: string; desc: string }[]).map(
              ({ label, desc }) => ({
                title: label,
                content: desc,
              })
            )}
          />
        </div>
      </div>
    </div>
  )
}
