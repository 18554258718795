import { Subscription_Tier } from '@my/supabase/graphql/gql/graphql'

export const PrivateModelCreditAmount = 500

export const PERIODS = [1, 3, 6, 12]

export enum PaymentType {
  Recurring = 'recurring',
  Onetime = 'onetime',
}

export enum PackageTier {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  XLarge = 'xlarge',
}

export const PackageTierToCredits = {
  [PackageTier.Small]: 150,
  [PackageTier.Medium]: 350,
  [PackageTier.Large]: 750,
  [PackageTier.XLarge]: 1500,
}

export const PackageTierToPrice = {
  [PackageTier.Small]: 4.99,
  [PackageTier.Medium]: 9.99,
  [PackageTier.Large]: 19.99,
  [PackageTier.XLarge]: 29.99,
}

export const PackageG2AShopUrl = {
  [PackageTier.Small]:
    'https://www.g2a.com/mydreamboy-gift-card-150-credits-mydreamboyai-key-global-i10000508561002?uuid=8153ea79-70e3-474a-9272-621d0d3eb815',
  [PackageTier.Medium]:
    'https://www.g2a.com/mydreamboy-gift-card-350-credits-mydreamboyai-key-global-i10000508561003?uuid=2d5209c2-4a68-4c9f-8435-6cf0ee18e6cb',
  [PackageTier.Large]:
    'https://www.g2a.com/mydreamboy-gift-card-750-credits-mydreamboyai-key-global-i10000508561004?uuid=065101a8-ec2e-4130-80d4-358e17f7e180',
  [PackageTier.XLarge]:
    'https://www.g2a.com/mydreamboy-gift-card-1500-credits-mydreamboyai-key-global-i10000508561001?uuid=2c74399a-fb49-4b48-927b-fccbed60b611',
}

export const PatreonOption = { id: 'patreon', label: 'Patreon' }
export const CryptoOption = { id: 'crypto', label: 'Crypto' }
export const OthersOption = { id: 'others', label: 'Others' }
export const G2AOption = { id: 'g2a', label: 'G2A Shop(Bank Card)' }
export const GumroadOption = { id: 'gumroad', label: 'Gumroad(Card/Google Pay)' }
export const BankCardOption = { id: 'bankcard', label: 'Credit/Debit Card' }
export const WechatOption = { id: 'wechat', label: 'Wechat Pay' }
export const AlipayOption = { id: 'alipay', label: 'Alipay' }
export const HiicashOption = { id: 'hiicash', label: 'Hiicash(Wechat/Telegram Pay)' }
export const OnrampPaypalOption = { id: 'onramp-paypal', label: 'Paypal(USA Only)' }

export const WechatLimit = {
  min: 0.2,
  max: 60,
}

export const AlipayLimit = {
  min: 0.2,
  max: 600,
}

export const HiicashLimit = {
  min: 5,
  max: 265,
}

export const OnrampPaypalLimit = {
  min: 10,
  max: 1000,
}

export const SubscriptionTierToCredits = {
  [Subscription_Tier.Free]: 25,
  [Subscription_Tier.Plus]: 300,
  [Subscription_Tier.Premium]: 1500,
  [Subscription_Tier.Pro]: 8000,
}

export const SubscriptionPrices = {
  1: {
    [Subscription_Tier.Free]: '0',
    [Subscription_Tier.Plus]: '9.99',
    [Subscription_Tier.Premium]: '29.99',
    [Subscription_Tier.Pro]: '99.99',
  },
  3: {
    [Subscription_Tier.Free]: '0',
    [Subscription_Tier.Plus]: '23.99',
    [Subscription_Tier.Premium]: '71.99',
    [Subscription_Tier.Pro]: '239.99',
  },
  6: {
    [Subscription_Tier.Free]: '0',
    [Subscription_Tier.Plus]: '35.99',
    [Subscription_Tier.Premium]: '107.99',
    [Subscription_Tier.Pro]: '347.99',
  },
  12: {
    [Subscription_Tier.Free]: '0',
    [Subscription_Tier.Plus]: '59.99',
    [Subscription_Tier.Premium]: '179.99',
    [Subscription_Tier.Pro]: '599.99',
  },
}

export const OnetimeSubscriptionPrices = {
  1: {
    [Subscription_Tier.Free]: '0',
    [Subscription_Tier.Plus]: '11.99',
    [Subscription_Tier.Premium]: '35.99',
    [Subscription_Tier.Pro]: '119.99',
  },
  3: {
    [Subscription_Tier.Free]: '0',
    [Subscription_Tier.Plus]: '29.99',
    [Subscription_Tier.Premium]: '89.99',
    [Subscription_Tier.Pro]: '299.99',
  },
  6: {
    [Subscription_Tier.Free]: '0',
    [Subscription_Tier.Plus]: '47.99',
    [Subscription_Tier.Premium]: '149.99',
    [Subscription_Tier.Pro]: '479.99',
  },
  12: {
    [Subscription_Tier.Free]: '0',
    [Subscription_Tier.Plus]: '71.99',
    [Subscription_Tier.Premium]: '215.99',
    [Subscription_Tier.Pro]: '719.99',
  },
}

export const OnetimeSubscriptionG2AShopUrl = {
  1: {
    [Subscription_Tier.Plus]:
      'https://www.g2a.com/mydreamboy-plus-membership-1-month-mydreamboyai-key-global-i10000508563001?uuid=b4c29146-b177-445d-b88a-d147dccec382',
    [Subscription_Tier.Premium]:
      'https://www.g2a.com/mydreamboy-premium-membership-1-month-mydreamboyai-key-global-i10000508563002?uuid=a2321b72-cafe-4afd-9718-91665059e8fa',
    [Subscription_Tier.Pro]:
      'https://www.g2a.com/mydreamboy-pro-membership-1-month-mydreamboyai-key-global-i10000508563003?uuid=41f44b39-0ab2-450c-a5d2-bdf3742b2584',
  },
  3: {
    [Subscription_Tier.Plus]:
      'https://www.g2a.com/mydreamboy-plus-membership-3-months-mydreamboyai-key-global-i10000508563004?uuid=49ead4c9-74a7-4ea7-ba77-9fc37b098974',
    [Subscription_Tier.Premium]:
      'https://www.g2a.com/mydreamboy-premium-membership-3-months-mydreamboyai-key-global-i10000508563005?uuid=ee85b21a-aa80-4624-9faa-7f04ccf26fbf',
    [Subscription_Tier.Pro]:
      'https://www.g2a.com/mydreamboy-pro-membership-3-months-mydreamboyai-key-global-i10000508563006?uuid=f0d05da8-0d79-4de3-9bb9-58a16e3697e7',
  },
  6: {
    [Subscription_Tier.Plus]:
      'https://www.g2a.com/mydreamboy-plus-membership-6-months-mydreamboyai-key-global-i10000508563007?uuid=0e5078a4-5da0-44d7-8c08-198330262f58',
    [Subscription_Tier.Premium]:
      'https://www.g2a.com/mydreamboy-premium-membership-6-months-mydreamboyai-key-global-i10000508563008?uuid=ccd3fa55-2a85-4f78-894f-f5fd449e46a2',
    [Subscription_Tier.Pro]:
      'https://www.g2a.com/mydreamboy-pro-membership-6-months-mydreamboyai-key-global-i10000508563009?uuid=050d3211-bc79-4dbf-8f3d-35e68e7babc0',
  },
  12: {
    [Subscription_Tier.Plus]:
      'https://www.g2a.com/mydreamboy-plus-membership-12-months-mydreamboyai-key-global-i10000508563010?uuid=fd330702-dd08-461b-bc92-6508153a957a',
    [Subscription_Tier.Premium]:
      'https://www.g2a.com/mydreamboy-premium-membership-12-months-mydreamboyai-key-global-i10000508563011?uuid=1a521c84-0d09-4e75-b5fa-f7e2887febe0',
    [Subscription_Tier.Pro]:
      'https://www.g2a.com/mydreamboy-pro-membership-12-months-mydreamboyai-key-global-i10000508563012?uuid=a70a50cc-89cc-4379-9549-1e9feb362b3b',
  },
}
